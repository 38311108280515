import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ProspectoService } from 'src/app/services/prospecto/prospecto.service';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import { 
  faTimes as faTimes,
  faTrash as fasTrash,
  faAddressBook as faAddressBook,
  faTruck as faTruck
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-prospecto-notif',
  templateUrl: './prospecto-notif.component.html',
  styleUrls: ['./prospecto-notif.component.css']
})
export class ProspectoNotifComponent implements OnInit {
  faTimes = faTimes;
  fasTrash = fasTrash;
  faAddressBook = faAddressBook;
  faTruck = faTruck;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions:any = {
    columnDefs:[
      {
          targets: [7],
          orderable: false // set orderable false for selected columns
      }],
    dom: 'Bflrtip',
    buttons: [{
      extend:'excel',
      exportOptions: {
          columns: [0,1,2,3,4,5,6]
      }
    }
    ],
    language: {
        "decimal": "",
        "emptyTable": "No hay información",
        "info": "Mostrando _START_ a _END_ de _TOTAL_ Resultados",
        "infoEmpty": "Mostrando 0 to 0 of 0 Resultados",
        "infoFiltered": "(Filtrado de _MAX_ total resultados)",
        "infoPostFix": "",
        "thousands": ",",
        "lengthMenu": "Mostrar _MENU_ Resultados",
        "loadingRecords": "Cargando...",
        "processing": "Procesando...",
        "search": "Buscador:",
        "zeroRecords": "Sin resultados encontrados",
        "paginate": {
            "first": "Primero",
            "last": "Ultimo",
            "next": "Siguiente",
            "previous": "Anterior"
        }
    }
  };
  dtTrigger = new Subject();
  public datos:any[];

  identity = JSON.parse(localStorage.getItem('identity_user'));
  loading = new LoadingEfectComponent();
  tipo = "";

  constructor(
    private _service:ProspectoService,
    private _route: ActivatedRoute,
    private _serviceCliente:ClientesService,
    private _serviceUsuario:UsuariosService,
    private _router: Router
    ) { }

  ngOnInit(): void {
    this.verifyClaims();
    this._route.params.subscribe(
        params => {
            this.tipo = params['tipo'];
            this.getRegistros();
        }
    );
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  verifyClaims(){
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Notificacion Prospectos');
    if(claim == undefined && claim == null){
      this._router.navigate(['/sa-rm/admin']);
    }
  }
  
  validateClaim(claim: string) {
    return this.identity.permisoLists.find(x => x.nombrePermiso === claim);
  }

  getRegistros(){
    this.dtOptions.destroy;
    this.loading.onLoadingRequest();
    this._service.getProspectoAlert(this.tipo)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          this.datos = response.content;
          // Destroy the table first
          dtInstance.destroy();
          this.dtTrigger.next();
          this.loading.onDoneRequest();
        });
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  deleteProspecto(id: number){
    Swal.fire({
      title: 'Atención',
      text: "¿Estas seguro que deseas eliminar el registro?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading.onLoadingRequest();
        this._service.deleteProspecto(id)
        .then(response=>{
          this.loading.onDoneRequest();
          if(response.status == 200){
            Swal.fire('Éxito', response.message, 'success');
            this.getRegistros();
          }else{
            Swal.fire('Error', response.message, 'error');
          }
        }).catch(error=>{  
          this.loading.onDoneRequest();    
          Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
        })
      }
    })
  }

  rechazarProspecto(id: number){
    Swal.fire({
      title: 'Atención',
      text: "¿Estas seguro que deseas rechazar el prospecto?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading.onLoadingRequest();
        this._service.rechazarProspecto(id)
        .then(response=>{
          this.loading.onDoneRequest();
          if(response.status == 200){
            Swal.fire('Éxito', response.message, 'success');
            this.getRegistros();
          }else{
            Swal.fire('Error', response.message, 'error');
          }
        }).catch(error=>{  
          this.loading.onDoneRequest();    
          Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
        })
      }
    })
  }
 //#region Funcionalidad Pase a Candidato
  paseCandidato(prospect: any){
    let texto = prospect.Status == 1 ? "El prospecto no cumple con los mínimos mandatorios para trabajar con DHL o bien terceriza unidades, ¿Estás seguro que deseas pasar de prospecto a candidato?" : "¿Estás seguro que deseas pasar de prospecto a candidato?";
    let titulo = prospect.Status == 1 ? "Necesita Autorización" : "Pase a Candidato";
    Swal.fire({
      title: titulo,
      text: texto,
      icon: prospect.Status == 1 ? 'warning' : 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.createCandidato(prospect);
      }
    })
  }

  public createCandidato(prospect: any){
    let Emp:any = {
      EmpresaID: 0,
      users: [prospect.Correo],
      NumProveedor: "",
      Nombre: prospect.RazonSocial,
      NombreComercial: prospect.NombreComercial,
      TipoPersona: prospect.TipoPersona,
      TipoServicio: prospect.TipoServicio
    };
    let obj:any = {
      idUser: 0,
      Nombre: prospect.NombreContacto,
      Correo: prospect.Correo,
      Telefono: prospect.Telefono,
      RolID: 1,
      EmpresaID: 0
    };
    
    this.loading.onLoadingRequest();
    this._serviceCliente.updateEmpresa(Emp)
        .then(response=>{
          if(response.status == 200){
            obj.EmpresaID = response.EmpresaID;
            //#region Creacion de usuario
            this._serviceUsuario.update(obj)
            .then(responseUsr=>{
              if(responseUsr.status == 200){
                this.paseCandidato_ReactivosVinculados(prospect.ProspectoID, obj.EmpresaID);
              }else if (responseUsr.status == 500){      
                this.loading.onDoneRequest();
                Swal.fire('Error', responseUsr.message, 'error');
              }else {
                this.loading.onDoneRequest();
                Swal.fire("Atención",responseUsr.message,"info");
              }
            }).catch(error=>{ 
              this.loading.onDoneRequest();     
              Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
            })
            //#endregion
          }else{
            this.loading.onDoneRequest();
            Swal.fire('Error', response.message, 'error');
          }        
        }).catch(error=>{   
          this.loading.onDoneRequest();   
          Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
        })
  }

  public paseCandidato_ReactivosVinculados(id:number, empresaID:number){
    this._service.paseCandidato(id, empresaID)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        Swal.fire("Éxito",response.message,"success")
        .then(rs=>{
          this._router.navigate(['/sa-rm/admin-client/' + empresaID + '/ft/' + empresaID]);
        });
      }else{
        Swal.fire('Error', response.message, 'error');
      }
    }).catch(error=>{  
      this.loading.onDoneRequest();    
      Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
    })
  }
//#endregion

  public ExtModal = false;
  dispDate;
  public dispID = 0;
  updateDisponibilidadModal(id: number){
    this.ExtModal = !this.ExtModal;
    if(this.ExtModal){
      this.dispDate = "";
      this.dispID = id
    }
  }

  updateProspectoDisponibilidad(){
    if(this.dispDate == "" ){
      Swal.fire("Atención","La fecha de disponibilidad no puede estar vacia.","warning")
    }else{
      this.loading.onLoadingRequest();
      this._service.updateProspectoDisponibilidad(this.dispID, this.dispDate)
      .then(response=>{
        this.loading.onDoneRequest();
        if(response.status == 200){
          Swal.fire('Éxito', response.message, 'success');
          this.getRegistros();
        }else{
          Swal.fire('Error', response.message, 'error');
        }
      }).catch(error=>{  
        this.loading.onDoneRequest();    
        Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
      })
    }
  }
}
