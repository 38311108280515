<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row mapa-sitio">
          <div class="col-sm-12 col-md-9">
              <div>
                  <a class="lbl-noActive" href="/#/sa-rm/admin/prospect">Prospectos ></a>
                  <label class="lbl-active">&nbsp;Nuevo Prospecto</label>
              </div>
              <h2>Nuevo Prospecto</h2>
          </div>
          <div class="col-sm-12">
              <a href="/#/sa-rm/admin/prospect" class="float-left link-regresar" style="cursor: pointer;"><img src="../../../assets/IMG/arrow_back.png" /></a>
          </div>
        </div>
        <form #createForm="ngForm" (ngSubmit)="update()" autocomplete="off" appIdentityRevealed>
            <div class="row">
                <div class="col-md-12 col-lg-6">
                    <div class="graph-title">DATOS EMPRESA</div>
                    <div class="row graphs-client ml-0 mr-0 pt-2 pb-2">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="RazonSocial">RAZÓN SOCIAL</label>
                                <input type="text" class="form-control" id="RazonSocial" #RazonSocial="ngModel" name="RazonSocial" [(ngModel)]="Emp.RazonSocial" required oninput="this.value = this.value.toUpperCase().replace(/[^a-zA-Z0-9 ]/g, '')">
                                <div *ngIf="RazonSocial.invalid" class="lbl-error">
                                    <span *ngIf="RazonSocial.errors.required">
                                        El campo es requerido
                                    </span>            
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="NombreComercial">NOMBRE COMERCIAL</label>
                                <input type="text" class="form-control" id="NombreComercial" #NombreComercial="ngModel" name="NombreComercial" [(ngModel)]="Emp.NombreComercial" required oninput="this.value = this.value.toUpperCase().replace(/[^a-zA-Z0-9 ]/g, '')">
                                <div *ngIf="NombreComercial.invalid" class="lbl-error">
                                    <span *ngIf="NombreComercial.errors.required">
                                        El campo es requerido
                                    </span>            
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="TipoPersona">TIPO PERSONA</label>
                                <select name="TipoPersona" id="TipoPersona" #TipoPersona="ngModel" name="TipoPersona" [(ngModel)]="Emp.TipoPersona" class="form-control" required pattern="[^0].*">
                                    <option value="0" disabled>Seleccionar...</option>
                                    <option value="1">Moral</option>
                                    <option value="2">Física</option>
                                </select>
                                <div *ngIf="TipoPersona.invalid" class="lbl-error">
                                    <span *ngIf="TipoPersona.errors.required || TipoPersona.errors.pattern">
                                        El tipo de persona es requerido
                                    </span>            
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="TipoLineaArray">TIPO DE LINEA</label>
                                <ng-select id="TipoLineaArray" #TipoLineaArray="ngModel" name="TipoLineaArray" placeholder="Seleccionar..."                                
                                        multiple="true" [clearable]="false" appendTo="body" [(ngModel)]="Emp.TipoLineaArray" required>
                                    <ng-option [value]="tl.ID" *ngFor="let tl of catalogTL">{{tl.Nombre}}</ng-option>
                                </ng-select>
                                <div *ngIf="TipoLineaArray.invalid" class="lbl-error">
                                    <span *ngIf="TipoLineaArray.errors.required">
                                        El tipo de linea es requerido
                                    </span>            
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="TipoServicio">TIPO DE SERVICIO</label>
                                <select name="TipoServicio" id="TipoServicio" #TipoServicio="ngModel" name="TipoServicio" [(ngModel)]="Emp.TipoServicio" class="form-control" required pattern="[^9].*">
                                    <option value="9" disabled>Seleccionar...</option>
                                    <option value="0">NACIONAL</option>
                                    <option value="1">EXTRANJERA</option>
                                    <option value="2">AEROLINEA</option>
                                </select>
                                <div *ngIf="TipoServicio.invalid"
                                    class="lbl-error">
                                    <span *ngIf="TipoServicio.errors.required || TipoServicio.errors.pattern">
                                        El campo es requerido
                                    </span>            
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-6">
                    <div class="graph-title">CONTACTO</div>
                    <div class="row graphs-client ml-0 mr-0 pt-2 pb-2">
                        <div class="col-12">
                            <div class="form-group">
                              <label for="NombreContacto">NOMBRE COMPLETO</label>
                              <input type="text" class="form-control" #NombreContacto="ngModel" name="NombreContacto" [(ngModel)]="Emp.NombreContacto" placeholder="Ingresa texto"  required/>
                              <div *ngIf="NombreContacto.invalid" class="lbl-error">
                                  <span *ngIf="NombreContacto.errors.required">
                                      El campo es requerido
                                  </span> 
                              </div>
                            </div>
                            <div class="form-group">
                                <label for="Correo">CORREO</label>
                                <input type="text" class="form-control" id="Correo" #Correo="ngModel" name="Correo" [(ngModel)]="Emp.Correo" required email>
                                <div *ngIf="Correo.invalid" class="lbl-error">
                                    <span *ngIf="Correo.errors.required">
                                        El campo es requerido
                                    </span>        
                                    <span *ngIf="Correo.errors.email">
                                        El correo no es válido
                                    </span>     
                                </div>
                            </div>
                            <div class="form-group">
                              <label for="Telefono">TELÉFONO</label>
                              <input type="tel" class="form-control" #Telefono="ngModel" name="Telefono" [(ngModel)]="Emp.Telefono" placeholder="Ingresa texto"  required mask='0000000000'/>
                              <div *ngIf="Telefono.invalid" class="lbl-error">
                                  <span *ngIf="Telefono.errors.required">
                                      El telefono es requerido
                                  </span>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 pt-3">
                    <button class='btn-layout btn-med float-right btnTableUp' [disabled]="createForm.invalid">
                        GUARDAR
                    </button>
                </div>
            </div>
        </form>  
        <app-loading-efect></app-loading-efect>     
      </div>
</div>