<div id="site_wrapper">
    <div class="contenedor-principal">    
        <div class="row pt-3">
            <div class="col-sm-12">
              <a (click)="regresar()" style="cursor: pointer;" class="float-left link-regresar"><img src="../../../assets/IMG/arrow_back.png" /></a>
            </div>
        </div>
        <div class="back-cuestionario">
            <h2>{{objPreview.Nombre}}</h2>
            <p>{{objPreview.Descripcion}}</p>
        </div>
        <div id="SeccionPreguntas">
            <div class="back-cuestionario">
                <div *ngFor="let group of preguntas" class="mt-3 mb-3">
                    <div class="card-header">
                        <p class="mt-3">
                            <label class="react-text" placement="right" [ngbTooltip]="group.Descripcion" [disableTooltip]="group.Descripcion == ' '">{{group.Nombre}}</label> 
                            <a *ngIf="group.Mandatorio != 'Deseable';" style="color: red;">* Mandatorio {{group.Mandatorio}}</a>
                        </p>
                        <div *ngIf="group.Opcional;" class="switch-form-base">
                            <div *ngIf="(group.ItemID != 21 && group.ItemID != 11) || group.ReactivoID == 134; else elseStatement">
                                <label class="switch">
                                    <input [disabled]="group.accessC.length == 0" [(ngModel)]="group.NoAplica" type="checkbox" id="swtNA-{{group.ReactivoItemEmpresaID}}" name="swtNA-{{group.ReactivoItemEmpresaID}}">
                                    <span class="slider round"></span>
                                </label>
                                &nbsp;
                                <label class="lblSwitch">No Aplica</label>
                            </div>
                            <ng-template #elseStatement>
                                <input [disabled]="group.accessC.length == 0 && group.Aplica != 'false'" (click)="aplica(group.ReactivoItemEmpresaID, false)" type="radio" name="swtNA-{{group.ReactivoItemEmpresaID}}" id="pregunta-si-swtNA-{{group.ReactivoID}}" value="false" [(ngModel)]="group.Aplica" style="cursor: pointer;"><label for="pregunta-si-swtNA-{{group.ReactivoID}}" style="cursor: pointer;">Si &nbsp; &nbsp;</label>
                                <input [disabled]="group.accessC.length == 0 && group.Aplica != 'true'"  (click)="aplica(group.ReactivoItemEmpresaID, true)" type="radio" name="swtNA-{{group.ReactivoItemEmpresaID}}" id="pregunta-no-swtNA-{{group.ReactivoID}}" value="true" [(ngModel)]="group.Aplica" style="cursor: pointer;"><label for="pregunta-no-swtNA-{{group.ReactivoID}}" style="cursor: pointer;">No</label>
                            </ng-template>
                        </div>
                    </div>
                    <div class="card-body">
                        <div *ngIf="group.Status == 3;">
                            <label class="" style="color: red;" *ngIf="group.MotivoRechazo == null || group.MotivoRechazo == ''; else elseRechazo">RECHAZADO</label>
                            <ng-template #elseRechazo>
                                <label class="" style="color: red;" *ngIf="group.MotivoRechazo == 'Otro';"
                                    placement="right" [ngbTooltip]="group.ComentarioRechazo">RECHAZADO</label>
                                    <label class="" style="color: red;" *ngIf="group.MotivoRechazo != 'Otro';"
                                        placement="right" [ngbTooltip]="group.MotivoRechazo">RECHAZADO</label>
                            </ng-template>
                        </div>
                        <div *ngIf="group.Vencimiento && !group.NoAplica;">
                            <label>Fecha de Vencimiento</label>
                            <input [disabled]="group.accessC.length == 0" type="date" class="form-control mb-3" name="pregunta-{{group.ReactivoItemEmpresaID}}" [(ngModel)]="group.VigenciaFIN">
                        </div>
                        <div *ngIf="group.TipoFK == 1 && !group.NoAplica;">
                            <input [disabled]="group.accessC.length == 0" type="text" class="form-control" name="pregunta-{{group.ReactivoItemEmpresaID}}" [(ngModel)]="group.Respuesta">
                        </div>
                        <div *ngIf="group.TipoFK == 2 && !group.NoAplica;">
                            <input [disabled]="group.accessC.length == 0" type="date" class="form-control" name="pregunta-{{group.ReactivoItemEmpresaID}}" [(ngModel)]="group.Respuesta">
                        </div>
                        <div *ngIf="group.TipoFK == 3 && !group.NoAplica;">
                            <button [hidden]="!group.Respuesta || group.Respuesta == '' || !group.Respuesta.includes('back-eutdhl')" class="btn btn-secondary mb-3" (click)="descargar(group.ReactivoItemEmpresaID)">DESCARGAR DOCUMENTO</button>
                            <button [hidden]="!group.Respuesta || group.Respuesta == '' || group.Respuesta.includes('back-eutdhl')" class="btn btn-secondary mb-3" (click)="verDoc(group.Respuesta)">DESCARGAR DOCUMENTO</button><br>
                            <input [disabled]="group.accessC.length == 0" type="file" name="pregunta-{{group.ReactivoItemEmpresaID}}" accept=".pdf" (change)="readUrl($event, group.ReactivoItemEmpresaID)">
                        </div>
                        <div *ngIf="group.TipoFK == 4 && !group.NoAplica;">
                            <label>Estado</label>
                            <input [disabled]="group.accessC.length == 0" type="text" class="form-control" [(ngModel)]="group.Direccion.Estado">
                            <label>Ciudad/Municipio</label>
                            <input [disabled]="group.accessC.length == 0" type="text" class="form-control" [(ngModel)]="group.Direccion.Municipio">
                            <label>Código Postal</label>
                            <input [disabled]="group.accessC.length == 0" type="number" class="form-control" [(ngModel)]="group.Direccion.CP">
                            <label>Colonia</label>
                            <input [disabled]="group.accessC.length == 0" type="text" class="form-control" [(ngModel)]="group.Direccion.Colonia">
                            <label>Calle</label>
                            <input [disabled]="group.accessC.length == 0" type="text" class="form-control" [(ngModel)]="group.Direccion.Calle">
                            <label>Num.</label>
                            <input [disabled]="group.accessC.length == 0" type="text" class="form-control" [(ngModel)]="group.Direccion.Num">
                        </div>
                        <div *ngIf="group.TipoFK == 5 && !group.NoAplica;">
                            <input [disabled]="group.accessC.length == 0 && group.Aplica != 'si'" type="radio" name="pregunta-{{group.ReactivoItemEmpresaID}}" id="pregunta-si-{{group.ReactivoID}}" value="si" [(ngModel)]="group.Respuesta" style="cursor: pointer;"><label for="pregunta-si-{{group.ReactivoID}}" style="cursor: pointer;">Si &nbsp; &nbsp;</label>
                            <input [disabled]="group.accessC.length == 0 && group.Aplica != 'no'" type="radio" name="pregunta-{{group.ReactivoItemEmpresaID}}" id="pregunta-no-{{group.ReactivoID}}" value="no" [(ngModel)]="group.Respuesta" style="cursor: pointer;"><label for="pregunta-no-{{group.ReactivoID}}" style="cursor: pointer;">No</label>
                        </div>
                        <div *ngIf="group.TipoFK == 6 && !group.NoAplica;">
                            <input [disabled]="group.accessC.length == 0" type="email" class="form-control" name="pregunta-{{group.ReactivoItemEmpresaID}}" [(ngModel)]="group.Respuesta">
                        </div>
                        <div *ngIf="group.TipoFK == 8 && !group.NoAplica;">
                            <select [disabled]="group.accessC.length == 0" name="pregunta-{{group.ReactivoItemEmpresaID}}" [(ngModel)]="group.Respuesta" class="form-control">
                                <option *ngFor="let item of group.Catalogos" [value]="item.ID">{{item.Nombre}}</option>
                            </select>
                        </div>
                        <div *ngIf="group.MultipleAplica && !group.NoAplica && !group.Disable;">
                            <button [hidden]="(group.accessC.length == 0)" class="btn-Anadir btn-fluid mb-3 mt-3" (click)="anadirReact(group.ReactivoItemEmpresaID)">AGREGAR <a style="color: red;">(Presionar solo en caso necesario)</a></button>
                        </div>
                        <a [hidden]="group.TipoFK == 7 || group.ReactivoItemEmpresaID < 0" class="lbl-noActive btn" (click)="ticket(true,group.ReactivoItemEmpresaID)">+Ticket Ayuda</a>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-left: 15px;">
                <button class="btn-layout btn-med btn-guardar mb-4" (click)="guardar(0)">GUARDAR</button>
            </div>
        </div>
        
    </div>
</div>

<div class="modal fade" id="mdlTicket" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': mdlTicket ? 'block' : 'none', 'opacity': 1}"> 
    <div class="modal-dialog modal-xl modal-dialog-centered" role="dialog" >
      <div class="modal-content">
        <div class="modal-header mdl-hdr text-center">
          <h5 class="modal-title w-100">LEVANTAR TICKET</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="ticket(false,0)">
            <span aria-hidden="true" class="mdl-close" >&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row principal">
                <div class="col-12">
                    <label>Mensaje</label>
                    <textarea [(ngModel)]="asistenciaREQ.Comentario" class="form-control"></textarea>
                </div>
            </div>
            <div class="div-btns">
                <button class="btn-layout btn-med btnTableUp mt-4 float-right ml-5" (click)="crearTicket()">ENVIAR</button>    
            </div>    
        </div>       
      </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>