import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';

@Component({
  selector: 'app-tipo-contrato',
  templateUrl: './tipo-contrato.component.html',
  styleUrls: ['./tipo-contrato.component.css']
})

export class TipoContratoComponent implements OnInit {
  loading = new LoadingEfectComponent();
  searchText;
  public obj:any = {};
  public RolAutList:any[];
  public catalog:any[];
  public Roles:any[];
  public mdl : boolean = false;
  identity = JSON.parse(localStorage.getItem('identity_user'));

  constructor(
    private _service:ClientesService,
    private user_service:UsuariosService,
    private router:Router
  ) { }
    
ngOnInit() {
  this.verifyClaims();
  this.getTipoContrato();
  this.getRoles();
  this.loading.onDoneRequest();
}

verifyClaims(){
  var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Configuraciones');
  console.log(claim);
  if(claim == undefined && claim == null){
    this.router.navigate(['/sa-rm/admin']);
  }
  this.identity.permisoLists
}

public getTipoContrato(){    
  this.loading.onLoadingRequest();
  this._service.getTipoContrato()
  .then(response=>{
    this.loading.onDoneRequest();
    if(response.status == 200){
      if(response.content != null && response.content.length > 0){
        this.catalog = response.content;
      }
    }else if(response.status == 500){
      Swal.fire("Error",response.message,"error");
    }
  })
  .catch(error=>{
    this.loading.onDoneRequest();
    console.log(error.toString());
  });
}

public getRoles(){    
  this.user_service.getRoles()
  .then(response=>{
    if(response.response.status == 200){
      if(response.content != null && response.content.length > 0){
        this.Roles = response.content;
      }
    }else if(response.response.status == 500){
      Swal.fire("Error",response.message,"error");
    }
  })
  .catch(error=>{
    console.log(error.toString());
  });
}

public open(open : boolean, id: number) : void {
  this.obj.Nombre = "";
  this.obj.RolAutList = "";
  this.RolAutList = [];
  this.obj.id = id;
  if(open){     
    $(".container").css('opacity',0.5);
    $("#sidebar-wrapper").css('opacity',0.5);
    $(".modal-title").html("CREAR TIPO CONTRATO");
    if(id != 0){
      $(".modal-title").html("EDITAR TIPO CONTRATO");
      let catAct = this.catalog.find(cat => cat.id === id);
      console.log(catAct)
      this.obj.Nombre = catAct.Nombre;
      this.obj.RolAutList = catAct.Nombre;
      this.RolAutList = catAct.RolAutList == '' ? [] : catAct.RolAutList.split(",").map( Number );
    }
  }else{      
    $(".container").css('opacity',1); 
    $("#sidebar-wrapper").css('opacity',1);      
  }
  this.mdl = open;
}

update(){
  this.obj.RolAutList = this.RolAutList.toString();
  this.loading.onLoadingRequest();
  this._service.updateTipoContrato(this.obj)
  .then(response=>{
    this.loading.onDoneRequest();
    if(response.status == 200){
      Swal.fire('Éxito', response.message, 'success');
      this.open(false, 0);
      this.getTipoContrato();
    }else if (response.status == 500){      
      Swal.fire('Error', response.message, 'error');
    }else {
      Swal.fire("Atención",response.message,"info");
    }
  }).catch(error=>{ 
    this.loading.onDoneRequest();     
    Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
  })
}

delete(id: number){
  Swal.fire({
    title: 'Atención',
    text: "¿Estas seguro que deseas eliminar el registro?",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Aceptar',
    cancelButtonText: 'Cancelar'
  }).then((result) => {
    if (result.isConfirmed) {
      this.loading.onLoadingRequest();
      this._service.deleteTipoContrato(id)
      .then(response=>{
        this.loading.onDoneRequest();
        if(response.status == 200){
          Swal.fire('Éxito', response.message, 'success');
          this.open(false, 0);        
        
          this.getTipoContrato();
        }else{
          Swal.fire('Error', response.message, 'error');
        }
      }).catch(error=>{  
        this.loading.onDoneRequest();    
        Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
      })
    }
  })
}

}
