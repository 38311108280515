import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-tarifas-transportista',
  templateUrl: './tarifas-transportista.component.html',
  styleUrls: ['./tarifas-transportista.component.css']
})
export class TarifasTransportistaComponent implements OnInit {
  loading = new LoadingEfectComponent();
  public id = +this._route.snapshot.paramMap.get('id');
  public cuestionario: any = [];
  public item: any = {};
  public datos:any[];
  public filter:any = {
    desde: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
    hasta: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
    status: "TODOS"
  };
  searchText;

  //Datos cliente
  public client: any = {};
  public spn:any[];
  //Carga excel
  public modalCargar:boolean = false;
  public modalPreview:boolean = false;
  public modalErrors:boolean = false;
  public formdata = new FormData();
  public previewData: any = {};
  uploadedFiles: Array<File>;
  //Tarifas a enviar
  public tarifaSelect:any[];
  
  identity = JSON.parse(localStorage.getItem('identity_user'));

  constructor(
    private _service:ClientesService,
    private _route: ActivatedRoute,
    private _router: Router,
    private datePipe: DatePipe
    ) 
  { 
    this.spn = !this.identity.empresa.NumProveedor || this.identity.empresa.NumProveedor == '' ? [] : this.identity.empresa.NumProveedor.split(",").map( Number );
    this.filter.spn = this.spn[0];
  }

  ngOnInit(): void {
    this.getRegistros();
  }

  public getRegistros(){
    this.loading.onLoadingRequest();
    this._service.getTarifas(this.filter.spn, this.identity.empresa.EmpresaID, this.filter.desde, this.filter.hasta, 0)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        this.datos = response.content;
        this.tarifaSelect = response.content.filter(x=> x.Status == 0).map((x) => { return x.EmpresaTarifaID });
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  regresar(){
    this._router.navigate(["/sa-rm/admin-client/" + this.id + "/ft/" + this.id]);
  }

  cambioStatus(id:number,aceptar:boolean){ 
    this.tarifaSelect = [id];
    let text = aceptar ? "aceptar" : "rechazar";
    let accept = aceptar ? 2 : 3;
    Swal.fire({
      title: 'Atención',
      text: "¿Estas seguro que deseas " + text + " la tarifa?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading.onLoadingRequest();
        this._service.updateStatusTarifas(this.tarifaSelect,accept)
        .then(response=>{
          this.loading.onDoneRequest();
          if(response.status == 200){
            Swal.fire('Éxito', response.message, 'success');
            this.getRegistros();
          }else{
            Swal.fire('Error', response.message, 'error');
          }
          
        }).catch(error=>{   
          this.loading.onDoneRequest();   
          Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
        })
      }
    })
  }

  public exportexcel(){
    /* table id is passed over here */   
    let element = document.getElementById('tableEmpresas'); 
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Tarifas.xlsx');
  }
}