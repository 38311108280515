import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemService } from 'src/app/services/items/item.service';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';

@Component({
  selector: 'app-item-sublist-cocl',
  templateUrl: './item-sublist-cocl.component.html',
  styleUrls: ['./item-sublist-cocl.component.css']
})
export class ItemSublistCoclComponent implements OnInit {
  loading = new LoadingEfectComponent();
  public id = +this._route.snapshot.paramMap.get('id').split(",")[0];
  public ItemId = +this._route.snapshot.paramMap.get('id').split(",")[1];
  public cuestionario: any = [];
  public item: any = {};
  searchText;
  
  identity = JSON.parse(localStorage.getItem('identity_user'));

  //Variables Motivo de Rechazo
  public mdlMotivo:boolean = false;
  public idReact:number = 0;
  public idMotivo:string = "0";
  public commentMotivo:string = "";
  public catalogTL:any[];
  
  constructor(
    private _service:ClientesService,
    private _serviceItem:ItemService,
    private _route: ActivatedRoute,
    private _serviceUsuario:UsuariosService,
    private _router: Router
    ) { }

  ngOnInit(): void {
    this.verifyClaims();
    this.getItem();
    this.getItems();
  }

  verifyClaims(){
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Empresas');
    if(claim == undefined && claim == null){
      this._router.navigate(['/sa-rm/admin']);
    }
  }

  validateClaim(claim: string) {
    return this.identity.permisoLists.find(x => x.nombrePermiso === claim);
  }
  
  public getItem(){
    this.loading.onLoadingRequest();
    this._serviceItem.getItem(+this.ItemId)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.item = response.content[0];
          console.log(this.item);
          if(this.item.Nombre == "OPERADORES"){
            this.getCatalog();
          }
        }
        else{
          Swal.fire("Atención","No hay items que mostrar.","info");
        }
      }else if(response.response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public getItems(){    
    this._service.getItemEmpresaSubList(this.id, this.ItemId).then(response=>{     
      this.cuestionario = response.content.map((x) =>
        {
          const factor = 10 ** 2;
          x.Resultado = Math.round(x.Resultado * factor)/ factor;
          x.Disponibilidad = x.Disponibilidad.slice(0, -9);
          return x
        });
    })
    .catch(error => {      
      console.log(error.toString());
    });
  }

  public editarDisponibilidad(id:number){
    this.loading.onLoadingRequest();
    let cuestAct = this.cuestionario.find(cue => cue.ItemEmpresaID === id);
    this._service.updateDisponibilidadItem(id, cuestAct.Disponibilidad)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status != 200){
        Swal.fire('Error', response.message, 'error');
      }        
    }).catch(error=>{ 
      this.loading.onDoneRequest();     
      Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
    })
  }

  public openPreviewItem(id:number){
    this._router.navigate(['/sa-rm/itemreview/' + id]);
  }

  regresar(){
    this._router.navigate(["/sa-rm/admin-client/" + this.id + "/ft/" + this.id]);
  }

  delete(id: number){ 
    if(this.item.Nombre == "OPERADORES"){
      this.modalMotivo(true, id);
    }else{
      Swal.fire({
        title: 'Atención',
        text: "¿Estas seguro que deseas eliminar el item?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading.onLoadingRequest();
          this._service.deleteItemEmpresa(id)
          .then(response=>{
            this.loading.onDoneRequest();
            if(response.status == 200){
              Swal.fire('Éxito', response.message, 'success');
            
              this.getItems();
            }else{
              Swal.fire('Error', response.message, 'error');
            }
            
          }).catch(error=>{   
            this.loading.onDoneRequest();   
            Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
          })
        }
      })
    }
  }
  
  //Motivo de Rechazo
  public getCatalog(){    
    this.loading.onLoadingRequest();
    this._serviceUsuario.getCatalog(19)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.catalogTL = response.content;
        }
      }else if(response.response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public modalMotivo(flag:boolean, id:number){
    this.idMotivo = "0";
    this.commentMotivo = "";
    this.idReact = id;
    this.mdlMotivo = flag;
  }

  public updateMotivo(){
    if(this.idMotivo == "0"){
      Swal.fire("Atención","Debes seleccionar un motivo de rechazo.","info");
    }else{
      let obj = {
        id: this.idReact,
        MotivoBaja: this.idMotivo,
        ComentarioBaja: this.commentMotivo
      }
      this.loading.onLoadingRequest();
      this._service.deleteItemEmpresaMotivoBaja(obj)
      .then(response=>{
        this.loading.onDoneRequest();
        if(response.status == 200){
          Swal.fire('Éxito', response.message, 'success');
        
          this.getItems();
        }else{
          Swal.fire('Error', response.message, 'error');
        }
        
      }).catch(error=>{   
        this.loading.onDoneRequest();   
        Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
      })
    }
    this.modalMotivo(false,0);
  }
}