import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-resumen',
  templateUrl: './resumen.component.html',
  styleUrls: ['./resumen.component.css']
})
export class ResumenComponent implements OnInit {
  loading = new LoadingEfectComponent();
  public rolID = +this._route.snapshot.paramMap.get('id').split(",")[0];
  public selectTP = +this._route.snapshot.paramMap.get('id').split(",")[1];
  public selectTM = this._route.snapshot.paramMap.get('id').split(",")[2];
  public selectTL = +this._route.snapshot.paramMap.get('id').split(",")[3];
  public flagEXT = this._route.snapshot.paramMap.get('id').split(",")[4];
  public obj:any = {};
  public role:string = "";
  fileName= 'ExcelSheet.xlsx';  

  identity = JSON.parse(localStorage.getItem('identity_user'));

  constructor(
    private _service:DashboardService,
    private _serviceUsuario:UsuariosService,
    private _route: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit() {
    this.verifyClaims();
    this.getRole();
    this.getRegistros();
    this.loading.onDoneRequest();
  }

  public registrarLog(){
    let clase = 'Dashboard Resumen ' + this.role;
    let obj = {
      //Controller: obj.Controller,
      Class: clase,
      //Table: obj.Table,
      //ID: obj.ID,
      Message: 'ACCESO'
    }
    this._serviceUsuario.registrarLog(obj);
  }

  public getRole(){
    this.loading.onLoadingRequest();
    this._service.getRole(this.rolID)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        this.role = response.message;
        this.registrarLog();
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }
  
  public getRegistros(){
    this.loading.onLoadingRequest();
    this._service.getDashboardResumen(this.rolID, this.selectTP, this.selectTM, this.selectTL, this.flagEXT )
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        this.obj = response;
        console.log(this.obj);
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }
  
  verifyClaims(){
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Dashboard');
    if(claim == undefined && claim == null){
      this._router.navigate(['/sa-rm/admin']);
    }
  }

  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('tableResumen'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
  }
}
