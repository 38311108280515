<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row mapa-sitio">
            <div class="col-12">
                <h2>Alertas Tarifas</h2> 
            </div>
        </div>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" (click)="getRegistros(true)" role="tab" aria-controls="home" aria-selected="true">Pendientes</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" (click)="getRegistros(false)" role="tab" aria-controls="profile" aria-selected="false">Todas</a>
            </li>
        </ul>
        <div class="row principal">
            <div class="col-12">
                <input type="text" [(ngModel)]="searchText" class="buscador form-control" placeholder="Buscador">
            </div>
        </div>
        <div class="row tabla-catalogo">  
            <div class="col-12">
                <table class="table table-responsive"> 
                    <thead>
                        <tr>
                            <th width="50%">EMPRESA</th>
                            <th width="15%">SPN</th>
                            <th width="10%" style="color: red;">PENDIENTES POR ENVIAR</th>
                            <th width="10%">ENVIADAS</th>
                            <th width="5%">ACEPTADAS</th>
                            <th width="5%"  style="color: red;">RECHAZADAS</th>
                            <th width="5%">TOTAL</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of catalog | tarifasAlertas: searchText; " (click)="goTarifas(group.EmpresaID)" style="cursor: pointer;">
                            <td>{{group.Nombre}}</td>
                            <td>{{group.SPN}}</td>
                            <td>{{group.PENDIENTES}}</td>
                            <td>{{group.ENVIADAS}}</td>
                            <td>{{group.RECHAZADAS}}</td>
                            <td>{{group.ACEPTADAS}}</td>
                            <td>{{group.TOTAL}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>             
        </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>