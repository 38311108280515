<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row mapa-sitio">
            <div class="col-sm-12 col-md-9">
                <div>
                    <a class="lbl-noActive" href="/#/sa-rm/admin/dashboard/{{flagEXT}}">Dashboard General > </a>
                    <a class="lbl-noActive" href="/#/sa-rm/admin/dashboard-empresa/{{rolID}},{{flagEXT}}">Detalle {{role}} > </a>
                    <label class="lbl-active">&nbsp;Detalle {{obj.NombreEmpresa}}</label>
                </div>
                <h3>Detalle {{role}}</h3> 
            </div>
            <div class="col-sm-12">
                <a href="/#/sa-rm/admin/dashboard-empresa/{{rolID}},{{flagEXT}}" class="float-left link-regresar" ><img src="../../../assets/IMG/arrow_back_red.png" /></a>
            </div>
        </div>
        <div class="row">
            <div class="col-12" style="height: fit-content;">
                <label class="dash-title">{{obj.NombreEmpresa}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-3" style="height: fit-content;">
                <label class="lbl-filtros dash-number">{{obj.DG.PERTOTAL}}%</label><br>
            </div>
            <div class="col-9">
                <div id="graph" class="" style="height: 400px;"></div>
            </div>
        </div>
        <div class="row tabla-catalogo">  
            <div class="col-12">
                <table class="table table-responsive" style="height:400px;">
                    <thead>
                        <tr>
                            <th width="80%">ITEM</th>
                            <th width="10%">STATUS CARGA</th>
                            <th width="20%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of obj.DDE">
                            <td>{{group.Nombre}}</td>
                            <td>{{group.NumRespuestas}}</td>
                            <td><a href="/#/sa-rm/admin-client/{{group.ItemID}}/itemsublist/{{empresaID}},{{group.ItemID}}" style="color: red !important;">Ver detalle</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>