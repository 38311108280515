<div id="site_wrapper">
    <div class="contenedor-principal" style="height: 599px;">
        <div class="row mapa-sitio">
            <div class="col-12">
                <h2>Log de Usuarios</h2> 
            </div>
        </div>
        <div class="row principal">
            <div class="col-sm-7 col-md-8 col-lg-9">
                <input type="text" [(ngModel)]="searchText" class="buscador form-control"  placeholder="Buscador">
            </div>
        </div>
        <div class="row filtros">
            <div class="col-sm-12 col-md-6 col-lg-3">
                <label>DESDE</label>
                <input type="date" class="form-control" name="desde" [(ngModel)]="filter.desde">
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <label>HASTA</label>
                <input type="date" class="form-control" name="hasta" [(ngModel)]="filter.hasta">
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <label>STATUS</label>
                <ng-select multiple="true" [clearable]="false" [(ngModel)]="filter.status" placeholder="Seleccionar...">
                    <ng-option value="ACCESO">ACCESO</ng-option>
                    <ng-option value="CIERRE SESIÓN">CIERRE SESIÓN</ng-option>
                    <!--
                    <ng-option value="CREAR">CREAR</ng-option>
                    <ng-option value="EDITAR">EDITAR</ng-option>
                    <ng-option value="ELIMINAR">ELIMINAR</ng-option>
                    <ng-option value="Reactivo Información Eliminada">Reactivo Información Eliminada</ng-option>
                    -->
                    <ng-option value="INGRESO">INGRESO</ng-option>
                    <ng-option value="Reactivo Aprobado">Reactivo Aprobado</ng-option>
                    <ng-option value="Reactivo Cargado">Reactivo Cargado</ng-option>
                    <ng-option value="Reactivo Rechazado">Reactivo Rechazado</ng-option>
                </ng-select>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <label>ACTIVOS/INACTIVOS</label>
                <select [(ngModel)]="filter.eliminado" class="form-control">
                    <option value="2">TODOS</option>
                    <option value="0">ACTIVOS</option>
                    <option value="1">INACTIVOS</option>
                </select>
            </div>
            <div class="col-sm-12 col-md-6">
                <label>USUARIOS</label>
                <ng-select
                    multiple="true" [clearable]="false"
                    [searchable]="true"
                    [(ngModel)]="filter.usuariosID"
                    placeholder="Seleccionar...">
                    <ng-option [value]="group.UsuarioID" *ngFor="let group of users;">
                        {{group.Nombre}}
                    </ng-option>
                </ng-select>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <button class='btn btn-danger btn-fluid' type='button' style='margin-top: 31px;' (click)="getRegistros()">
                    DESCARGAR
                </button>
            </div>
        </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>