import { Injectable } from '@angular/core';
import { GLOBAL } from './../global';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ClientesService {
  private url:string;
  userToken = localStorage.getItem('token').slice(1).slice(0,-1);

  constructor(private _http:HttpClient) {
    this.url = GLOBAL.url + 'Empresas';
  }

//#region Clientes
  getCliente(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getEmpresa?id=' + id, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getClienteEliminado(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getEmpresaEliminado?id=' + id, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }
  
  updateEmpresa(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.post(this.url + '/updateEmpresa', obj, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }
  
  deleteEmpresa(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.delete(this.url + '/deleteEmpresa?id=' + id, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getItemEmpresaList(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getItemEmpresaList?id=' + id, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getItemEmpresaSubList(id:number, itemId:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getItemEmpresaSubList?id=' + id + '&itemId=' + itemId, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  updateDisponibilidadItem(id:number, disp:Date){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/updateDisponibilidadItem?id=' + id + '&disp=' + disp, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }
  
  updateDisponibilidadReactivo(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.post(this.url + '/updateDisponibilidadReactivo', obj, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  deleteItemEmpresa(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.delete(this.url + '/deleteItemEmpresa?id=' + id, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  deleteItemEmpresaMotivoBaja(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.post(this.url + '/deleteItemEmpresaMotivoBaja', obj, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  updateItemEmpresa(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.post(this.url + '/updateItemEmpresa', obj, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  updateItemEmpresaMassiveAsign(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.post(this.url + '/updateItemEmpresaMassiveAsign', obj, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getItemByEmpresa(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getItemByEmpresa?id=' + id, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getReactivosbyItemEmpresa(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getReactivosbyItemEmpresa?idItem=' + id, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  updateReactivosEmpresa(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.post(this.url + '/updateReactivosEmpresa', obj, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  agregarReactivo(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.post(this.url + '/agregarReactivo', obj, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  deleteReactivoEmpresa(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.delete(this.url + '/deleteReactivoEmpresa?id=' + id, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  uploadFile(formdata){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.post(GLOBAL.url + 'Values', formdata, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }
//#endregion

//#region Alertas----------------------------------------------------------------------------------------------
  getAlertas(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getAlertas?idEmpresa=' + id, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getEmpresaAlerta(tipo:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getEmpresaAlerta?tipo=' + tipo, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getItemAlerta(id:number, tipoAlerta:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getItemAlerta?id=' + id + '&tipoAlerta=' + tipoAlerta, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getReactivosbyItemAlerta(id:number, tipoAlerta:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getReactivosbyItemAlerta?idItem=' + id + '&tipoAlerta=' + tipoAlerta, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }
//#endregion

//#region  Dashboard--------------------------------------------------------------------------------------------
  getDashboard(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(GLOBAL.url + 'Usuarios/getCatalog?idCatalog=1', {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getYears(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(GLOBAL.url + 'Usuarios/getCatalog?idCatalog=1', {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getTotalPreguntas(id:number, obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(GLOBAL.url + 'Usuarios/getCatalog?idCatalog=1', {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getTotalCuestionarios(id:number, obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(GLOBAL.url + 'Usuarios/getCatalog?idCatalog=1', {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }
//#endregion

//#region  TipoContrato--------------------------------------------------------------------------------------------
  getTipoContrato(){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getTipoContrato', {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  updateTipoContrato(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.post(this.url + '/updateTipoContrato/', obj, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }

  deleteTipoContrato(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.delete(this.url + '/deleteTipoContrato?id=' + id, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }
//#endregion
  
//#region Alertas Contrato--------------------------------------------------------------------------------------------
  getAlertaContrato(){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getAlertaContrato', {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  updateStatus1(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.post(this.url + '/updateStatus1/', obj, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }

  updateAutorizarContrato(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.post(this.url + '/updateAutorizarContrato/', obj, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }
  
  getDatosVisita(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getDatosVisita?id=' + id, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  updateStatusEXT(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.post(this.url + '/updateStatusEXT/', obj, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }

  updateAutorizarDocumentoEXT(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.post(this.url + '/updateAutorizarDocumentoEXT/', obj, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }

  updateAutorizarContratoEXT(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.post(this.url + '/updateAutorizarContratoEXT/', obj, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }

  getContratoProceso(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getContratoProceso?id=' + id, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getContratoStatus(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getContratoStatus?id=' + id, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getResetContrato(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getResetContrato?id=' + id, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getNewContrato(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getNewContrato?id=' + id, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getNewCertificacion(id:number, nivelSeguridad:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getNewCertificacion?id=' + id + '&nivelSeguridad=' + nivelSeguridad, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }
//#endregion

//#region Tarifas-------------------------------------------------
  getTarifas(spn:Text, empresaID:number, desde:Date, hasta:Date, historial : number){
    let obj = {
      spn: spn,
      empresaID: empresaID,
      desde: desde,
      hasta: hasta,
      historial: historial
    };
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.post(this.url + '/obtenerTarifas?spn=' + spn + '&empresaID=' + empresaID + '&desde=' + desde + '&hasta=' + hasta + '&historial=' + historial, obj, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  PostTarifasCM(formdata){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.post(this.url + '/PostTarifasCM', formdata, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  updateTarifas(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.post(this.url + '/updateTarifas', obj, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  updateStatusTarifas(tarifasID:any, status:number){
    let obj = {
      tarifasID: tarifasID,
      status: status
    };
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.post(this.url + '/updateStatusTarifas', obj, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getTarifasAlertas(pendientes:Boolean){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getTarifasAlertas?pendientes=' + pendientes, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }
//#endregion
}