import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class GuardService implements CanActivate {

  constructor(
    private _auth: AuthService,
    private _route: Router
    ) { }

  canActivate(){    
    if(this._auth.getIdentity()){      
      return true;
    }else{
      this._route.navigate(['/login-consultor']);
      return false;
    }
  }
}
