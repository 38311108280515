import { Component, OnInit } from '@angular/core';
import { ProspectoService } from 'src/app/services/prospecto/prospecto.service';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { GLOBAL } from './../../../../services/global';
import { faTrash as fasTrash} from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-prospecto-ft',
  templateUrl: './prospecto-ft.component.html',
  styleUrls: ['./prospecto-ft.component.css']
})
export class ProspectoFtComponent implements OnInit {
  fasTrash = fasTrash;
  loading = new LoadingEfectComponent();
  identity = JSON.parse(localStorage.getItem('identity_user'));
  id = +this._route.snapshot.paramMap.get('id');

  public catalogTL = [];
  public catalog:any = {};
  public Pros:any = { };
  public preguntas:any = [];

  constructor(
    private _service:ProspectoService,
    private _serviceUsuario:UsuariosService,
    private _route: ActivatedRoute,
    private _router:Router
  ) { }

  ngOnInit(): void {
    this.verifyClaims();
    this.getCatalog(17); //Tipo de linea
    this.getRutasPrincipalesByProspecto();
    this.getTipoUnidadesByProspecto();
    this.getItemByProspecto();
  }

  verifyClaims(){
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Prospectos');
    if(claim == undefined && claim == null){
      this._router.navigate(['/sa-rm/admin']);
    }
  }

  validateClaim(claim: string) {
    return this.identity.permisoLists.find(x => x.nombrePermiso === claim);
  }

  public getCatalog(type:number){    
    this._serviceUsuario.getCatalog(type)
    .then(response=>{
      if(response.response.status == 200){
        if(response.content != null && response.content.length > 0){
          if(type == 17){
            this.catalogTL = response.content;
            this.getProspecto();
          }
          else if(type == 22){
            this.catalog.Origen = response.content;
          }
          else if(type == 23){
            this.catalog.Destino = response.content;
          }
          else if(type == 27){
            this.catalog.Origen = response.content;
            this.catalog.Destino = response.content;
          }
          else if(type == 24 || type == 28){
            this.catalog.Unidad = response.content;
          }
          else if(type == 25){
            this.catalog.Capacidad = response.content;
          }
        }
      }else if(response.response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      console.log(error.toString());
    });
  }

  getProspecto(){
    this.loading.onLoadingRequest();
    this._service.getProspecto(this.id, 0)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        this.Pros = response.content[0];
        console.log(this.Pros);
        this.Pros.TipoLineaArray = !this.Pros.TipoLinea || this.Pros.TipoLinea == '' ? [] : this.Pros.TipoLinea.split(",").map( Number );
        this.loading.onDoneRequest();
        if(this.Pros.TipoServicio == 2){
          this.getCatalog(27); //Origen/Destino
          this.getCatalog(28); //Unidad
        }else{
          this.getCatalog(22); //Origen
          this.getCatalog(23); //Destino
          this.getCatalog(24); //Unidad
        }
        this.getCatalog(25); //Capacidad
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public editarData: boolean = false;
  public editarFT() : void {    
    this.editarData = true;
  }

  public update(){
    this.loading.onLoadingRequest();
    this.Pros.TipoLinea = this.Pros.TipoLineaArray.toString();
    this.Pros.RazonSocial = this.Pros.RazonSocial.trim();
    this.Pros.NombreComercial = this.Pros.NombreComercial.trim();
    console.log(this.Pros);
    this._service.updateProspecto(this.Pros)
        .then(response=>{
          this.loading.onDoneRequest();   
          if(response.status == 200){
            Swal.fire("Registro exitoso",response.message,"success")
            .then(rs=>{
              this.editarData = false;
            });
          }else{
            Swal.fire('Error', response.message, 'error');
          }        
        }).catch(error=>{   
          this.loading.onDoneRequest();   
          Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
        })
  }

  //#region Rutas Principales
  public editarDataRP: boolean = false;
  public editarRP() : void {    
    this.editarDataRP = !this.editarDataRP;
    if(this.editarDataRP){
      let newRP:any = {
        ProspectoID: this.id,
        ProspectoRutasPrincipalesID: 0,
        Origen: null,
        Destino: null
      };

      this.RutasPrincipales.push(newRP);
    }else{
      this.RutasPrincipales.pop()
    }
  }

  public RutasPrincipales = [];
  getRutasPrincipalesByProspecto(){
    this._service.getRutasPrincipalesByProspecto(this.id)
    .then(response=>{
      if(response.status == 200){
        this.RutasPrincipales = response.content;
        console.log(this.RutasPrincipales);
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      console.log(error.toString());
    });
  }

  public updateRutasPrincipales(){
    let ruta = this.RutasPrincipales[this.RutasPrincipales.length - 1];
    if(!ruta.Origen || ruta.Origen.trim() == "" ){
      Swal.fire("Atención","El origen no puede estar vacio.","warning")
    }
    else if(!ruta.Destino || ruta.Destino.trim() == "" ){
      Swal.fire("Atención","El destino no puede estar vacio.","warning")
    }else{
      this.loading.onLoadingRequest();
      this._service.updateRutasPrincipales(ruta)
          .then(response=>{
            this.loading.onDoneRequest();   
            if(response.status == 200){
              Swal.fire("Registro exitoso",response.message,"success")
              .then(rs=>{
                ruta.ProspectoRutasPrincipalesID = response.EmpresaID
                this.editarDataRP = false;
              });
            }else{
              Swal.fire('Error', response.message, 'error');
            }        
          }).catch(error=>{   
            this.loading.onDoneRequest();   
            Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
          })
    }
  }
  
  deleteRutasPrincipales(id: number){
    Swal.fire({
      title: 'Atención',
      text: "¿Estás seguro que deseas eliminar el registro?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading.onLoadingRequest();
        this._service.deleteRutasPrincipales(id)
        .then(response=>{
          this.loading.onDoneRequest();
          if(response.status == 200){
            Swal.fire('Éxito', response.message, 'success');
            this.getRutasPrincipalesByProspecto();
          }else{
            Swal.fire('Error', response.message, 'error');
          }
        }).catch(error=>{  
          this.loading.onDoneRequest();    
          Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
        })
      }
    })
  }
  //#endregion

  //#region Tipo Unidades
  public editarDataTU: boolean = false;
  public editarTU() : void {    
    this.editarDataTU = !this.editarDataTU;
    if(this.editarDataTU){
      let newTU:any = {
        ProspectoID: this.id,
        ProspectoTipoUnidadesID: 0,
        Tipo: null,
        Capacidad: null,
        Cantidad: 0
      };

      this.TipoUnidades.push(newTU);
    }else{
      this.TipoUnidades.pop()
    }
  }

  public TipoUnidades = [];
  getTipoUnidadesByProspecto(){
    this._service.getTipoUnidadesByProspecto(this.id)
    .then(response=>{
      if(response.status == 200){
        this.TipoUnidades = response.content;
        console.log(this.TipoUnidades);
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      console.log(error.toString());
    });
  }

  public updateTipoUnidades(){
    let TipoUnidad = this.TipoUnidades[this.TipoUnidades.length - 1];
    if(TipoUnidad.Tipo.trim() == "" ){
      Swal.fire("Atención","El tipo no puede estar vacio.","warning")
    }
    else if(TipoUnidad.Capacidad.trim() == "" ){
      Swal.fire("Atención","La capacidad no puede estar vacia.","warning")
    }
    else if(TipoUnidad.Cantidad == null || TipoUnidad.Cantidad == 0){
      Swal.fire("Atención","La cantidad no puede estar vacia y debe ser un número mayor a cero.","warning")
    }else{
      this.loading.onLoadingRequest();
      this._service.updateTipoUnidades(TipoUnidad)
          .then(response=>{
            this.loading.onDoneRequest();   
            if(response.status == 200){
              Swal.fire("Registro exitoso",response.message,"success")
              .then(rs=>{
                TipoUnidad.ProspectoTipoUnidadesID = response.EmpresaID
                this.editarDataTU = false;
              });
            }else{
              Swal.fire('Error', response.message, 'error');
            }        
          }).catch(error=>{   
            this.loading.onDoneRequest();   
            Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
          })
    }
  }

  deleteTipoUnidades(id: number){
    Swal.fire({
      title: 'Atención',
      text: "¿Estás seguro que deseas eliminar el registro?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading.onLoadingRequest();
        this._service.deleteTipoUnidades(id)
        .then(response=>{
          this.loading.onDoneRequest();
          if(response.status == 200){
            Swal.fire('Éxito', response.message, 'success');
            this.getTipoUnidadesByProspecto();
          }else{
            Swal.fire('Error', response.message, 'error');
          }
        }).catch(error=>{  
          this.loading.onDoneRequest();    
          Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
        })
      }
    })
  }
  //#endregion

  //#region Item Reactivo
  getItemByProspecto(){
    this.loading.onLoadingRequest();
    this._service.getItemByProspecto(this.id, 0)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        this.preguntas = response.content.map((x) =>
        {
          x.reactivos.map((y)=>{
            if(y.NombreTipo == "Direccion" && y.Respuesta){
              y.Direccion = JSON.parse(y.Respuesta);
            }else{
              let Res = '{"Estado": "", "Municipio": "", "CP": 0, "Colonia": "", "Calle": "", "Num": ""}';
              x.Direccion = JSON.parse(Res);
            }
            return y
          });
          return x
        });
        console.log(this.preguntas);
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public save(){
    Swal.fire({
      title: 'Atención',
      text: "¿Estás seguro que deseas guardar? Por favor verifica la información.",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.guardarReactivos();
      }
    })
  }

  guardarReactivos(){
    this.loading.onLoadingRequest();
    this.preguntas.map((x) =>
    {
      x.reactivos.map((y)=>{
        if(y.NombreTipo == "Direccion"){
          y.Respuesta = JSON.stringify(y.Direccion);
        }
        return y
      });
      return x
    });
    this._service.updateReactivosProspecto(this.preguntas)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status != 200){
        Swal.fire('Error', response.message, 'error');
      }else{
        Swal.fire('Éxito', response.message, 'success')
        .then(rs=>{
          this.getProspecto();
        });
      }    
    }).catch(error=>{   
      this.loading.onDoneRequest();   
      Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
    });
  }

  uploadedFiles: Array<File>;
  readUrl(event:any, group:any) { 
    if (event.target.files && event.target.files[0]) { 
      this.loading.onLoadingRequest();
      this.uploadedFiles = event.target.files;
      let formdata = new FormData();
      for (let i = 0; i< this.uploadedFiles.length; i++){
        formdata.append(group.ReactivoItemProspectoID.toString(), this.uploadedFiles[i], this.uploadedFiles[i].name);
      }
      this._service.uploadFile(formdata).then((res)=>{
        this.loading.onDoneRequest();
        let selectedReact = this.preguntas.find(x=>x.ItemProspectoID == group.ItemProspectoID).reactivos.find(x=> x.ReactivoItemProspectoID == group.ReactivoItemProspectoID);
        selectedReact.Respuesta = res[0];
      });
    }
  }

  descargar(id:number){
    window.open(GLOBAL.urlIMG + '/General/DownloadProspectoPdf?documentoId=' + id);
  }

  verDoc(id:string){
    window.open(id);
  }

  noAplica(group:any){
    if(group.TipoFK == 7){
      let Group = !group.agrupados || group.agrupados == '' ? [] : group.agrupados.split(",").map( Number );
      if(Group.length > 0){
        Group.forEach((element) => {
          let reactsGV = this.preguntas.find(x=>x.ItemProspectoID == group.ItemProspectoID).reactivos.find(x=> x.ReactivoID == element);
          reactsGV.NoAplica = !group.NoAplica;
        });
      }
    }
  }
  //#endregion

  //#region Carga Masiva Rutas Principales y Tipo Unidad
  public tipoCM = 0;
  public modalCargar:boolean = false;
  public modalPreview:boolean = false;
  public modalErrors:boolean = false;
  public formdata = new FormData();
  public previewDataRP: any = {};
  public previewDataTU: any = {};
  uploadedFilesCM: Array<File>;
  public openCargaExcel(open : boolean, tipo:number = 1){
    if(open){     
      this.tipoCM = tipo;
      $(".container").css('opacity',0.5);
      $("#sidebar-wrapper").css('opacity',0.5);
      if(tipo == 1){
        $(".modal-title").html("Cargar archivo de Rutas Principales");
      }else{
        $(".modal-title").html("Cargar archivo de Tipos de Unidades");
      }
    }else{      
      $(".container").css('opacity',1); 
      $("#sidebar-wrapper").css('opacity',1);      
    }
    this.modalCargar = open;
  }

  obtenerExcel(event:any) { 
    if (event.target.files && event.target.files[0]) { 
      this.loading.onLoadingRequest();
      this.uploadedFilesCM = event.target.files;
      this.formdata = new FormData();
      for (let i = 0; i< this.uploadedFilesCM.length; i++){
        this.formdata.append("uploads[]", this.uploadedFilesCM[i], this.uploadedFilesCM[i].name);
      }
      this.loading.onDoneRequest();
    }
  }

  cargaExcel() { 
    if (!this.uploadedFilesCM) { 
      Swal.fire("Atención","Debes seleccionar un archivo para poder realizar la carga.","warning");
    }else{
      this.loading.onLoadingRequest();
      this.formdata.append("prospectoID", this.id.toString());
      if(this.tipoCM == 1){
        this.rutasPrincipalesCM();
      }else{
        this.tipoUnidadesCM();
      }
    }
  }

  public rutasPrincipalesCM(){
    this._service.PostRutasPrincipalesCM(this.formdata).then((res)=>{
      console.log(res);
      this.loading.onDoneRequest();
      if(res.status == 200){
        this.previewDataRP = res;
        $(".container").css('opacity',0.5);
        $("#sidebar-wrapper").css('opacity',0.5);
        if(res.errors.length > 0){
          $(".modal-title").html("Lista de Errores");
          this.modalErrors = true;
        }else{
          $(".modal-title").html("Vista Previa");
          this.modalPreview = true;
        }
      }else {
        Swal.fire("Error",res.message,"error");
      }
    });
  }

  public tipoUnidadesCM(){
    this._service.PostTipoUnidadesCM(this.formdata).then((res)=>{
      console.log(res);
      this.loading.onDoneRequest();
      if(res.status == 200){
        this.previewDataTU = res;
        $(".container").css('opacity',0.5);
        $("#sidebar-wrapper").css('opacity',0.5);
        if(res.errors.length > 0){
          $(".modal-title").html("Lista de Errores");
          this.modalErrors = true;
        }else{
          $(".modal-title").html("Vista Previa");
          this.modalPreview = true;
        }
      }else {
        Swal.fire("Error",res.message,"error");
      }
    });
  }

  public openModalPreview(open : boolean){
    if(open){     
      $(".container").css('opacity',0.5);
      $("#sidebar-wrapper").css('opacity',0.5);
      $(".modal-title").html("Vista Previa");
    }else{      
      $(".container").css('opacity',1); 
      $("#sidebar-wrapper").css('opacity',1);      
    }
    this.modalPreview = open;
  }

  public openModalErrors(open : boolean){
    if(open){     
      $(".container").css('opacity',0.5);
      $("#sidebar-wrapper").css('opacity',0.5);
      $(".modal-title").html("Lista de Errores");
    }else{      
      $(".container").css('opacity',1); 
      $("#sidebar-wrapper").css('opacity',1);      
    }
    this.modalErrors = open;
  }

  public guardarRutasPrincipales(){
    this.loading.onLoadingRequest();
    this._service.updateRutasPrincipalesCM(this.previewDataRP.content)
    .then(response=>{
      this.loading.onDoneRequest();
      this.openCargaExcel(false);
      this.openModalPreview(false);
      if(response.status == 200){
        Swal.fire('Éxito', "La carga de rutas principales se completo de forma correcta", 'success')
        this.getRutasPrincipalesByProspecto();
      }else if(response.status == 500){
        Swal.fire("Error", response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public guardarTipoUnidades(){
    this.loading.onLoadingRequest();
    this._service.updateTipoUnidadesCM(this.previewDataTU.content)
    .then(response=>{
      this.loading.onDoneRequest();
      this.openCargaExcel(false);
      this.openModalPreview(false);
      if(response.status == 200){
        Swal.fire('Éxito', "La carga de tipos de unidades se completo de forma correcta", 'success')
        this.getTipoUnidadesByProspecto();
      }else if(response.status == 500){
        Swal.fire("Error", response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public exportexcel(tipo:number){
    /* table id is passed over here */   
    let nombreArchivo = "";
    let element;
      if(tipo == 1){
        nombreArchivo = 'RutasPrincipales.xlsx';
        element = document.getElementById('tableRutasPrincipales'); 
      }else{
        nombreArchivo = 'TipoUnidades.xlsx';
        element = document.getElementById('tableTipoUnidades'); 
      }
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, nombreArchivo);
  }
  //#endregion
}
