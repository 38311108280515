import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-reporte-ingreso',
  templateUrl: './reporte-ingreso.component.html',
  styleUrls: ['./reporte-ingreso.component.css']
})
export class ReporteIngresoComponent implements OnInit {
  loading = new LoadingEfectComponent();
  searchText;
  public datos:any[];
  public filter:any = {
    desde: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
    hasta: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
    status: "TODOS"
  };
  fileName= 'ExcelSheet.xlsx';  

  identity = JSON.parse(localStorage.getItem('identity_user'));

  constructor(
    private _service:DashboardService,
    private _router: Router,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.verifyClaims();
    this.loading.onDoneRequest();
  }

  public getRegistros(){
    this.loading.onLoadingRequest();
    this._service.getReporteIngreso(this.filter.desde, this.filter.hasta, this.filter.status)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        this.datos = response.DR;
        console.log(this.datos);
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  verifyClaims(){
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Configuraciones');
    if(claim == undefined && claim == null){
      this._router.navigate(['/sa-rm/admin']);
    }
  }

  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('tableResumen'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, {dateNF:'mm/dd/yyyy;@',cellDates:true, raw: true});

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
  }
}