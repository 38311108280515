import { Component, OnInit  } from '@angular/core';
import * as $ from 'jquery';
import { faAngleRight as fasAngleRight} from '@fortawesome/free-solid-svg-icons';
import { faAngleLeft as fasAngleLeft} from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  fasAngleRight = fasAngleRight;
  fasAngleLeft = fasAngleLeft;
  constructor() {

  }
  
  ngOnInit() {
    
  }
  
  ngAfterViewInit(){  
    
  }

  ngAfterContentInit(){   
    
  }
  
  ngAfterContentChecked(){    
  }
  
}
