import { Injectable } from '@angular/core';
import { GLOBAL } from './../global';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AsistenciaService {
  private url:string;
  userToken = localStorage.getItem('token').slice(1).slice(0,-1);

  constructor(private _http:HttpClient) {
    this.url = GLOBAL.url + 'Asistencia';
  }
  //Clientes
  getAsistenciaMinList(){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getAsistenciaMinList', {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getAsistenciaList(EmpresaID:number, Status:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getAsistenciaList?EmpresaID=' + EmpresaID + '&Status=' + Status, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }
  
  getAsistencia(AsistenciaID:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getAsistencia?AsistenciaID=' + AsistenciaID, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  updateAsistencia(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.post(this.url + '/updateAsistencia', obj, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  updateAsistenciaMin(flag:boolean, AsistenciaID:number){
    var obj = {
      flag: flag,
      AsistenciaID: AsistenciaID
    };
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.post(this.url + '/updateAsistenciaMin', obj, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }
}
