import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login/login/login.service';
import Swal from 'sweetalert2';
import {Md5} from 'ts-md5/dist/md5';
import { GLOBAL } from './../../services/global';

@Component({
  selector: 'app-loginconsultor',
  templateUrl: './loginconsultor.component.html',
  styleUrls: ['./loginconsultor.component.css']
})
export class LoginconsultorComponent implements OnInit {
  public obj:any = {};
  identity = JSON.parse(localStorage.getItem('identity_user'));
  public btnContinuar:boolean = false;
  public btnLogin:boolean = true;
  GLOBALurl = GLOBAL.urlIMG;
  response_token;

  constructor(
    private _serviceLogin: LoginService,
    public router: Router
  ) { }

  ngOnInit(): void {
    if(this.identity != null && this.identity.Rol != 1){
      this.router.navigate(['/sa-rm/admin']);
    }else if(this.identity != null && this.identity.Rol == 1){
      this.router.navigate(['/sa-rm/client/noticias']);
      //this.router.navigate(['/sa-rm/client/dashboard']);
    }  
  }

  goToMenu(rol) {
    if(rol != 1){
      this.router.navigate(['/sa-rm/admin']);
    }else if(rol == 1){
      this.router.navigate(['/sa-rm/client/noticias']);
      //this.router.navigate(['/sa-rm/client/dashboard']);
    }  
  }

  continuar(){
    if(this.obj.usuario == undefined || this.obj.usuario == ""){
      Swal.fire("Atención","Debes ingresar tu correo electrónico para poder continuar","warning");
      return false;
    }
    else if(this.obj.password == undefined || this.obj.password == ""){
      Swal.fire("Atención","Debes ingresar tu contraseña para poder continuar","warning");
      return false;
    }
    else if(!this.obj.terminos){
      Swal.fire("Atención","Debes aceptar los términos y condiciones para poder continuar","warning");
      return false;
    }
    else{
      if(this.validateEmail(this.obj.usuario)){
        this._serviceLogin.validateUser(this.obj)
        .then(response=>{
          if(response.status == 200){
            this.loginPass();
            //this._serviceLogin.getToken(this.obj)
            //.then(responseTK=>{
            //  if(responseTK.status == 200){
            //    this.btnContinuar = true;
            //    this.btnLogin = false;
            //  }else{
            //    Swal.fire("Error de token",responseTK.message,"error");
            //  }
            //})
            //.catch(error=>{
            //  Swal.fire("Error de conexión con el servidor",error.toString(),"error");
            //});
          }else if(response.status == 401){
            Swal.fire("Atención","El correo electrónico que ingresaste no tiene acceso. Por favor, revisa e inténtalo de nuevo.","warning");
          }else{
            Swal.fire("Error al validar",response.message,"error");
          }
        })
        .catch(error=>{
          Swal.fire("Error de conexión con el servidor",error.toString(),"error");
        })
      }else{
        Swal.fire("Usuario no válido","Se requiere formato de email","warning");
        return false;
      }
    }
  }

  regresar(){
    this.btnContinuar = false;
    this.btnLogin = true;
    this.obj.password = undefined;
    this.obj.usuario = undefined;
    this.obj.terminos = false;
  }

  loginPass(){    
    if(this.obj.password == undefined || this.obj.usuario == undefined){
      Swal.fire("Atención","Debes ingresar tu token de acceso para poder continuar","warning");
      return false;
    }else{
        var pass = this.obj.password;
        this._serviceLogin.login(this.obj)
        .then(response=>{
          this.response_token = JSON.stringify(response.access_token);
          this._serviceLogin.getToken(this.obj)
          .then(responseTK=>{
            if(responseTK.status == 200){
              this.btnContinuar = true;
              this.btnLogin = false;
            }else{
              Swal.fire("Error de token",responseTK.message,"error");
            }
          })
          .catch(error=>{
            Swal.fire("Error de conexión con el servidor",error.toString(),"error");
          });
        })
        .catch(error=>{
          Swal.fire("Atención","El usuario o contraseña que ingresaste es incorrecto. Por favor, revisa e inténtalo de nuevo.","warning");
        })
      this.obj.password = pass;
    }
  }

  login(){    
    if(this.obj.password == undefined || this.obj.usuario == undefined){
      Swal.fire("Atención","Debes ingresar tu token de acceso para poder continuar","warning");
      return false;
    }else{
        this._serviceLogin.validToken(this.obj)
        .then(response=>{
          if(response.status == 200){
            localStorage.setItem('token', this.response_token);
            this._serviceLogin.getUser(this.obj, true)
            .then(responseUsr=>{
              localStorage.setItem('identity_user', JSON.stringify(responseUsr.user));
              localStorage.setItem('lastActivity',new Date().getTime().toString());
              console.log(JSON.parse(localStorage.getItem('identity_user')));
              this.goToMenu(responseUsr.user.Rol);
            });
          }else{
            Swal.fire("Atención",response.message,"warning");
          }
        })
        .catch(error=>{
          Swal.fire("Atención","El Token de acceso que ingresaste es incorrecto. Por favor, revisa e inténtalo de nuevo o genera un nuevo Token.","warning");
        })
    }
  }

  validateEmail(email) 
  {        
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
  }

}
