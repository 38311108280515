import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GLOBAL } from './../../../services/global';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';

@Component({
  selector: 'app-contrato-sublist',
  templateUrl: './contrato-sublist.component.html',
  styleUrls: ['./contrato-sublist.component.css']
})
export class ContratoSublistComponent implements OnInit {
  loading = new LoadingEfectComponent();
  public catalog:any[];
  public certificaciones:any[];
  public nombre:string = "";
  public maxProceso:boolean = false;
  public flagCertificacion:boolean = false;
  public newCertificacionModal:boolean = false;
  public nivelSeguridad:number = 1;

  public id = +this._route.snapshot.paramMap.get('id')
  identity = JSON.parse(localStorage.getItem('identity_user'));

  constructor(
    private _service:ClientesService,
    private _route: ActivatedRoute,
    public router: Router,
  ) { }

  ngOnInit() {
    this.getRegistros();
  }

  validateClaim(claim: string) {
    return this.identity.permisoLists.find(x => x.nombrePermiso === claim);
  }

  public getRegistros(){
    this.loading.onLoadingRequest();
    this._service.getContratoStatus(this.id)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        this.catalog = response.content;
        this.certificaciones = response.certificaciones;
        this.maxProceso = response.maxProceso;
        this.flagCertificacion = response.flagCertificacion;
        this.nombre = response.content[0].Nombre;
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public preview(ContratoSolicitudID:number){
    window.location.href = GLOBAL.urlIMG + '/General/DownloadPdfContrato?documentoId=' + ContratoSolicitudID;
  }

  regresar(){
    this.router.navigate(["/sa-rm/admin-client/" + this.catalog[0].EmpresaID + "/contrato/" + this.catalog[0].EmpresaID]);
  }

  public resetContract(){
    Swal.fire({
      title: 'Atención',
      text: "¿Estas seguro que deseas reiniciar el proceso de contrato?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading.onLoadingRequest();
        this._service.getResetContrato(this.id)
        .then(response=>{
          this.loading.onDoneRequest();
          if(response.status == 200){
            Swal.fire('Éxito', response.message, 'success');
          
            this.getRegistros();
          }else{
            Swal.fire('Error', response.message, 'error');
          }
        }).catch(error=>{  
          this.loading.onDoneRequest();    
          Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
        })
      }
    })
  }

  public accionStatus1(open : boolean){
    this.nivelSeguridad = 1;
    if(open){     
      $(".container").css('opacity',0.5);
      $("#sidebar-wrapper").css('opacity',0.5);
      $(".modal-title").html("SELECCIONAR NIVEL DE SEGURIDAD");
    }else{      
      $(".container").css('opacity',1); 
      $("#sidebar-wrapper").css('opacity',1);      
    }
    this.newCertificacionModal = open;
  }

  public newCertificacion(){
    Swal.fire({
      title: 'Atención',
      text: "¿Estas seguro que deseas iniciar un nuevo proceso de certificación?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading.onLoadingRequest();
        this._service.getNewCertificacion(this.id, this.nivelSeguridad)
        .then(response=>{
          this.loading.onDoneRequest();
          if(response.status == 200){
            Swal.fire('Éxito', response.message, 'success');
            this.accionStatus1(false)
          
            this.getRegistros();
          }else{
            Swal.fire('Error', response.message, 'error');
          }
        }).catch(error=>{  
          this.loading.onDoneRequest();    
          Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
        })
      }
    })
  }
}
