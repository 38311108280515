<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row mapa-sitio">
            <div class="col-12">
                <h2>Noticias</h2> 
            </div>
        </div>
        <div class="row rowBuscador">
            <div class="col-sm-6">
                <input type="text" [(ngModel)]="searchText" class="form-control itemBuscador"  placeholder="Buscador">
            </div>
            <div class="col-sm-6">
                <select [(ngModel)]="ordenador" class="form-control itemOrder"  placeholder="Ordernar" (change)="ordenar()">
                    <option value="0">Ordenar de antiguo a reciente</option>
                    <option value="1">Ordenar de reciente a antiguo</option>
                </select>
            </div>
        </div>
        <div class="row">  
            <div class="col-7">
                <div class="row m-0">
                    <div *ngFor="let group of catalogFiltered | noticias: searchText;" class="col-12 mb-3 card" style="background-color: rgb(255, 254, 254, .7) !important">
                        <div class="row mt-3">
                            <div *ngIf="group.Marcar" class="col-12">
                                <label class="" style="font-weight: bold; color:red;">Noticia Urgente</label>
                            </div>
                            <div class="col-6">
                                <h3>{{group.Titulo}}</h3>
                            </div>
                            <div class="col-6">
                                <label class="noticia-area">{{group.Area}}</label>
                            </div>
                            <div class="col-12">
                                <label>{{group.INSERT_DATE | date:'dd/MM/yyyy'}}</label>
                            </div>
                            <div class="col-12" style="overflow-x: auto;" [innerHTML]="group.Cuerpo"></div>
                            <div class="col-12">
                                <label style="font-weight: bold;">Por: {{group.NombreUsuario}}</label>
                            </div>
                        </div>
                        <!--Inicio Carrousel-->
                        <div *ngIf="group.IMGs.length > 0" id="carouselExampleIndicators-{{group.NoticiaID}}" class="carousel slide" data-ride="carousel">
                            <ol class="carousel-indicators">
                                <li *ngFor="let arc of group.IMGs; index as i;" data-target="#carouselExampleIndicators-{{group.NoticiaID}}" data-slide-to="{{i}}" [ngClass]="{'active': i === 0}"></li>
                              <!--<li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                              <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>-->
                            </ol>
                            <div class="carousel-inner">
                                <div *ngFor="let arc of group.IMGs; index as i;" class="carousel-item" [ngClass]="{'active': i === 0}">
                                  <img class="d-block w-100" [src]="arc.Url" alt="First slide {{i}}">
                                </div>
                              <!--<div class="carousel-item">
                                <img class="d-block w-100" src="..." alt="Second slide">
                              </div>
                              <div class="carousel-item">
                                <img class="d-block w-100" src="..." alt="Third slide">
                              </div>-->
                            </div>
                            <a class="carousel-control-prev" href="#carouselExampleIndicators-{{group.NoticiaID}}" role="button" data-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleIndicators-{{group.NoticiaID}}" role="button" data-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="sr-only">Next</span>
                            </a>
                          </div>
                          <!--Fin Carrousel-->
                        <button *ngIf="group.Archivos.length > 0" type="button" class="btn btn-secondary btn-block mb-3" (click)="getArchivos(group.NoticiaID)">VER ARCHIVOS</button>
                    </div>
                </div>
            </div>    
            <div class="col-5">
                <div class="row m-0 large-2" style="position: fixed; max-width: 25%; max-height: 80%; overflow-y: auto;">
                    <div class="col-12 card-filter-noticia">
                        <div class="form-group">
                            <label for="areaSelect">Filtrar por área</label>
                            <ng-select id="areaSlt" 
                                    #areaSlt="ngModel" 
                                    name="areaSlt" 
                                    placeholder="Seleccionar..."                                
                                    multiple="true" [clearable]="false"
                                    [(ngModel)]="areaSelect" (change)="filtrar()">
                                <ng-option [value]="item.ID" *ngFor="let item of area">
                                    {{item.Nombre}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-12 card-filter-noticia mt-3">
                        <label style="padding-top: 5px;">Publicaciones totales</label>
                        <label class="noticia-number">{{catalogFiltered.length}}</label>
                    </div>
                    <div class="col-12 card-filter-noticia mt-3">
                        <h5 style="padding-top: 5px;">{{labelArchivos}}</h5>
                        <table class="table table-responsive">
                            <tbody style="background-color: transparent !important; color:white;">
                                <tr *ngFor="let group of archivos">
                                    <td style="border-color: transparent;">
                                        <fa-icon *ngIf="group.ContentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'" 
                                        [icon]="faFileExcel" style="font-size: 25px;"></fa-icon>
                                        <fa-icon *ngIf="group.ContentType == 'application/pdf'" 
                                        [icon]="faFilePdf" style="font-size: 25px;"></fa-icon>
                                        <fa-icon *ngIf="group.ContentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'" 
                                        [icon]="faFileWord" style="font-size: 25px;"></fa-icon>
                                        <fa-icon *ngIf="group.ContentType == 'image/jpeg'" 
                                        [icon]="faFileImage" style="font-size: 25px;"></fa-icon>
                                    </td>
                                    <td style="border-color: transparent; vertical-align: middle;">
                                        <a style="color: white;" target="_blank" href="{{group.Url}}">
                                            {{group.FileName}}
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>         
        </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>