import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { Router } from '@angular/router';
import { ItemService } from 'src/app/services/items/item.service';
import { GLOBAL } from './../../../services/global';

@Component({
  selector: 'app-estatus-carga',
  templateUrl: './estatus-carga.component.html',
  styleUrls: ['./estatus-carga.component.css']
})
export class EstatusCargaComponent implements OnInit {
  identity = JSON.parse(localStorage.getItem('identity_user'));
  loading = new LoadingEfectComponent();

  public filter:any = {
    eliminado: 2,
    extranjera: -1,
    status: [],
    itemID: []
  };
  public datos:any[];
  public catalog:any[];

  constructor(
    private _serviceItem:ItemService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this.verifyClaims();
    this.getItem();
  }

  verifyClaims(){
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Reporte Estatus Carga');
    if(claim == undefined && claim == null){
      this._router.navigate(['/sa-rm/admin']);
    }
  }

  getRegistros(){
    window.open(GLOBAL.urlIMG + 'Excel/Reporte_StatusCarga?status=' + this.filter.status.toString()
    + '&eliminado=' + this.filter.eliminado
    + '&extranjera=' + this.filter.extranjera
    + '&itemID=' + this.filter.itemID.toString());
  }

  public getItem(){    
    this.loading.onLoadingRequest();
    this._serviceItem.getItem(0)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.catalog = response.content
          console.log(this.catalog);
        }
        else{
          Swal.fire("Atención","No hay items que mostrar.","info");
        }
      }else if(response.response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }
}
