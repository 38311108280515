import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'alertaContrato'
})
export class AlertaContratoPipe implements PipeTransform {
  transform(items: any[], searchAsistencia: string): any[] {
    if (!items) return [];
    if (!searchAsistencia) return items;

    searchAsistencia = searchAsistencia.toLowerCase();

    return items.filter( it => {
      return it.Nombre.toLowerCase().includes(searchAsistencia) 
      || it.StatusNombre.toLowerCase().includes(searchAsistencia);
    });
  }
}
