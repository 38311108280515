<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row mapa-sitio">
            <div class="col-12">
                <h2>Alertas Reclamos</h2> 
            </div>
        </div>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" (click)="getRegistros(true)" role="tab" aria-controls="home" aria-selected="true">Pendientes</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="home-tab" data-toggle="tab" href="#home" (click)="getRegistros(false)" role="tab" aria-controls="home" aria-selected="false">Leídos</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="reclamo-tab" data-toggle="tab" href="#reclamo" (click)="getReclamoPendiente()" role="tab" aria-controls="reclamo" aria-selected="false">Reclamos Abiertos</a>
            </li>
        </ul>
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
            <div class="row tabla-catalogo">  
                <div class="col-12">
                    <table class="table table-responsive table-sm table-hover" #table1 datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger1"> 
                        <thead>
                            <tr>
                                <th width="10%">Folio</th>
                                <th width="10%">Control Vehicular</th>
                                <th width="30%">Nombre Empresa</th>
                                <th width="10%">Fecha de Registro</th>
                                <th width="15%">Status</th>
                                <th width="20%">Descripción</th>
                                <th width="5%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let group of catalog;" (click)="goTarifas(group.EmpresaID)" style="cursor: pointer;">
                                <td style="white-space: nowrap;">{{group.FolioReclamo}}</td>
                                <td style="white-space: nowrap;">{{group.ControlVehicular}}</td>
                                <td>{{group.NombreEmpresa}}</td>
                                <td>{{group.INSERT_DATE | date:'dd/MM/yyyy'}}</td>
                                <td>{{group.StatusNombre}}</td>
                                <td>{{group.Descripcion}}</td>
                                <td>
                                    <button class="btn btn-eliminar" routerLink="/sa-rm/admin/reclamo-ft/{{group.ReclamoID}}" title="Ver reclamo">
                                        <fa-icon [icon]="faEye"></fa-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>             
            </div>
          </div>
          <div class="tab-pane fade" id="reclamo" role="tabpanel" aria-labelledby="reclamo-tab">
            <div class="row tabla-catalogo">  
                <div class="col-12">
                  <table class="table table-responsive table-sm table-hover" #table2 datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger2"> 
                      <thead>
                          <tr>
                              <th width="10%">Folio</th>
                              <th width="10%">Control Vehicular</th>
                              <th width="30%">Status Carga</th>
                              <th width="30%">Status Cliente</th>
                              <th width="10%">Status Seguridad</th>
                              <th width="10%">Monto del Reclamo</th>
                              <th width="10%">Antigüedad</th>
                              <th width="10%">Origen</th>
                              <th width="10%">Destino</th>
                              <th width="10%">Nombre Empresa</th>
                              <th width="20%">Operador</th>
                              <th width="20%">Fecha de Siniestro</th>
                              <th width="20%">Fecha de Registro</th>
                              <th width="10%"></th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let group of reclamos;">
                              <td style="white-space: nowrap;">{{group.FolioReclamo}}</td>
                              <td>{{group.ControlVehicular}}</td>
                              <td>{{group.NombreStatusCarga}}</td>
                              <td>{{group.NombreStatusCliente}}</td>
                              <td>{{group.NombreStatusSeguridad}}</td>
                              <td>{{group.MontoFormated}}</td>
                              <td>{{group.Antiguedad}}</td>
                              <td>{{group.Origen}}</td>
                              <td>{{group.Destino}}</td>
                              <td>{{group.NombreEmpresa}}</td>
                              <td>{{group.Operador}}</td>
                              <td>{{group.FechaSiniestro | date:'dd/MM/yyyy'}}</td>
                              <td>{{group.INSERT_DATE | date:'dd/MM/yyyy'}}</td>
                              <td style="white-space: nowrap;">
                                <button class="btn btn-eliminar" routerLink="/sa-rm/admin/reclamo-ft/{{group.ReclamoID}}" title="Ver reclamo">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                </button>
                                <button [hidden]="group.DocCargaLTPendientes == 0" class="btn btn-eliminar" (click)="modalCorreoLT(group.ReclamoID)" title="Correo a transportista">
                                    <fa-icon [icon]="faEnvelope"></fa-icon>
                                </button>
                              </td>
                          </tr>
                      </tbody>
                  </table>           
              </div>
            </div>
          </div>
        </div>
    </div>
</div>

<div class="modal fade" id="correoModal" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': correoModal ? 'block' : 'none', 'opacity': 1}"> 
    <div class="modal-dialog modal-md modal-dialog-centered" role="dialog" >
      <div class="modal-content">
        <div class="modal-header mdl-hdr text-center">
          <h5 class="modal-title w-100">SEGUIMIENTO A LT</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalCorreoLT(0)">
            <span aria-hidden="true" class="mdl-close" >&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form #FormDisponibilidad="ngForm" (ngSubmit)="enviarCorreoLT()" autocomplete="off" appIdentityRevealed>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="correoLT">CORREO LINEA TRANSPORTISTA</label>
                            <input type="email" class="form-control" name="correoLT" [(ngModel)]="objCorreo.correoLT">
                        </div>
                        <label>Selecciona los documentos a solicitar:</label>
                    </div>
                    <div class="col-12 col-lg-6" *ngFor="let group of documentos;">
                        <div class="form-group">
                            <input type="checkbox" class="mr-2" id="chk-{{group.CT_ReclamoDocumentoID}}">
                            <label for="chk-{{group.CT_ReclamoDocumentoID}}">{{group.NombreDocumento}}</label>
                        </div>
                    </div>
                </div>
                <div class="div-btns row pt-3">   
                    <div class="col-6">
                      <button class="btn btn-fluid btn-Eliminar btnTableUp" (click)="modalCorreoLT(0)" data-dismiss="modal" type="button">CANCELAR</button>            
                    </div>
                    <div class="col-6">     
                      <button class="btn btn-fluid btn-layout btnTableUp">ENVIAR CORREO</button>    
                    </div>
                </div>
            </form>            
        </div>       
      </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>