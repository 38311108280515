import { Component, Inject, OnInit  } from '@angular/core';
import * as $ from 'jquery';
import { 
  faAddressBook,
  faTimes as fasTimes,
  faBars as fasBars,
  faCircle as fasCircle,
  faSignOutAlt as fasSignOut,
  faEnvelope as fasEnvelope,
  faChartArea as fasChartArea,
  faCog as fasCog,
  faTicketAlt,
  faNewspaper, faTruck, faExclamationTriangle,
  faSortAlphaUp as faSortAlphaUp
} from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AuthService } from 'src/app/services/login/auth/auth.service';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import { ReclamoService } from 'src/app/services/reclamo/reclamo.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-sidebar-cocl',
  templateUrl: './sidebar-cocl.component.html',
  styleUrls: ['./sidebar-cocl.component.css']
})
export class SidebarCoclComponent implements OnInit {
  faAddressBook = faAddressBook;
  fasTimes = fasTimes;
  fasEnvelope = fasEnvelope;
  fasBars = fasBars;
  fasCircle = fasCircle;
  fasSignOut = fasSignOut;
  faChartArea = fasChartArea;
  faNewspaper = faNewspaper;
  faTruck = faTruck;
  faCog = fasCog;
  faTicketAlt = faTicketAlt;
  faSortAlphaUp = faSortAlphaUp;
  faExclamationTriangle = faExclamationTriangle;

  private url:string;
  identity = JSON.parse(localStorage.getItem('identity_user'));
  private sesionCookies = this.identity.Sesion;
  private sesionBD: string = '';
  private objSesion: any = {};
  toggle:boolean = false;
  //Variables para Alertas
  itemsPendientes:number = 0;
  reactivosPendientes:number = 0;
  porCaducar:number = 0;
  asistenciasNuevo:number = 0;
  asistenciasCurso:number = 0;
  alertaContrato:number = 0;
  alertaTarifas:number = 0;
  //Variables para Alertas Prospecto
  prospectoPendientes:number = 0;
  prospectoExpirados:number = 0;
  prospectoPorExpirar:number = 0;

  public Empresas:boolean = true;
  public Dashboard:boolean = true;
  public Configuraciones:boolean = true;

  //Modal Contacto
  public mdlReport : boolean = false;
  public obj:any = {};

  constructor(
    private _auth: AuthService,
    private _serviceUsuario: UsuariosService,
    private _serviceCliente: ClientesService,
    private _serviceReclamo:ReclamoService,
    public router: Router,
    @Inject(DOCUMENT) document: any
  ) { 
    
  }

  ngOnInit() {
    console.log(this.identity);
    this.getAlertas();
    this.verifyClaims();
    if(this.validateClaim("Notificacion Prospectos")){
      this.getAlertasProspecto();
    }
    if(this.validateClaim("Reclamos")){
      this.getReclamoAlert();
    }
  }

  verifyClaims(){
    var claim1 = this.identity.permisoLists.find(x => x.nombrePermiso === 'Empresas');
    var claim2 = this.identity.permisoLists.find(x => x.nombrePermiso === 'Dashboard');
    var claim3 = this.identity.permisoLists.find(x => x.nombrePermiso === 'Configuraciones');
    if(claim1 == undefined && claim1 == null){
      this.Empresas = false;
    }
    if(claim2 == undefined && claim2 == null){
      this.Dashboard = false;
    }
    if(claim3 == undefined && claim3 == null){
      this.Configuraciones = false;
    }
  }
    
  validateClaim(claim: string) {
    return this.identity.permisoLists.find(x => x.nombrePermiso === claim);
  }
    
  getAlertas(){
    this._serviceCliente.getAlertas(0)
    .then(response=>{
      if(response.status == 200){
        this.itemsPendientes = response.itemsPendientes;
        this.reactivosPendientes = response.reactivosPendientes;
        this.porCaducar = response.porCaducar;
        this.asistenciasNuevo = response.asistenciasNuevo;
        this.asistenciasCurso = response.asistenciasCurso;
        this.alertaContrato = response.alertaContrato;
        this.alertaTarifas = response.alertaTarifas;
        (async () => { 
          await this.delay(10000);
          // Do something after
          this.getAlertas();
        })();
      }else if (response.status == 500){      
        console.log(response.message);
      }else {
        console.log(response.message);
      }
    }).catch(error=>{ 
      console.log( error.toString());
    })
  }

  getAlertasProspecto(){
    this._serviceUsuario.getAlertasProspecto()
    .then(response=>{
      if(response.status == 200){
        this.prospectoPendientes = response.Pendientes;
        this.prospectoExpirados = response.Expirados;
        this.prospectoPorExpirar = response.PorExpirar;
        (async () => { 
          await this.delay(10000);
          // Do something after
          this.getAlertasProspecto();
        })();
      }else if (response.status == 500){      
        console.log(response.message);
      }else {
        console.log(response.message);
      }
    }).catch(error=>{ 
      console.log( error.toString());
    })
  }

  reclamoPendientes:number = 0;
  getReclamoAlert(){
    this._serviceReclamo.getReclamoAlert(true)
    .then(response=>{
      if(response.status == 200){
          this.reclamoPendientes = response.content.length;
          (async () => { 
            await this.delay(10000);
            // Do something after
            this.getAlertasProspecto();
          })();
      }else if(response.status == 500){ 
        console.log(response.message);
      }
    })
    .catch(error=>{
      console.log(error.toString());
    });
  }
    
  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
  
  openAlertas(tipo:number){
    this.router.navigate(['/sa-rm/admin/alertas/' + tipo]);
  }
  
    ngAfterViewInit(){  
      
    }
  
    ngAfterContentInit(){   
      
    }
    
    ngAfterContentChecked(){    
    }

    dropdownfunc(id:string) {
      if(this.toggle){
        this.toggle = false;
        $("#" + id + "-submenu").slideUp(200);
        $("#" + id + "-dropdown").removeClass("active");
      }else{
        this.toggle = true;
        $("#" + id + "-submenu").slideDown(200);
        $("#" + id + "-dropdown").addClass("active");
      }
    }
    
    closesidebar() {
      $(".page-wrapper").removeClass("toggled");
    }

    showsidebar() {
      $(".page-wrapper").addClass("toggled");
    }
    
    asistencia(){
      this.router.navigate(['/sa-rm/admin/asistencia']);
    }
    
    openAlertaContrato(){
      this.router.navigate(['/sa-rm/admin/alerta-contrato']);
    }

    active(id){
      if(this.mdlDashboard){
        this.mdlDashboard = false;
      }
      window.location.href = '#/sa-rm/admin/' + id;
    }

    public mdlDashboard : boolean = false;
    public dashboardModal() : void {
      this.mdlDashboard = !this.mdlDashboard;
    }

    public openContact(open : boolean) : void {
      this.mdlReport = open;
    }

    enviarCorreo(){
      this._serviceUsuario.enviarCorreo(this.obj)
      .then(response=>{
        if(response.status == 200){
          Swal.fire('Mensaje enviado', 'Tu mensaje ha sido enviado de forma correcta', 'success');
        }else {
          Swal.fire('Error', response.message, 'error');
        }
      }).catch(error=>{ 
        Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
      })
    }
    
    cambioPass(){
      Swal.fire({
        title: '<h2>Cambio de contraseña</h2>',
        html: '<label style="color:white;">Presiona confirmar para iniciar con el proceso de cambio de contraseña. Este proceso cerrará tu sesión actual y enviará un correo con los pasos a seguir para cambiar tu contraseña.</label>',
        showCancelButton: true,
        background: '#15181e',
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#ffc107',
        cancelButtonText: `Cancelar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this._serviceUsuario.newPassword()
          .then(response=>{
            if(response.status == 200){
              this._auth.logOut()
              .then(response=>{
                this.router.navigate(['/login-consultor']);
              })
              .catch(error=>{
                this.router.navigate(['/login-consultor']);
              });
            }else if(response.status == 500){
              Swal.fire("Error",response.message,"error");
            }
          })
          .catch(error=>{
            console.log(error.toString());
          });
        }
      })
    }
}
