import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import * as Highcharts from 'highcharts';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import { LoginService } from 'src/app/services/login/login/login.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import { ItemService } from 'src/app/services/items/item.service';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';

declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let Solid = require('highcharts/modules/solid-gauge');
let Exporting = require('highcharts/modules/exporting');
let Export = require('highcharts/modules/export-data');
let Accessibility = require('highcharts/modules/accessibility');
let Drilldown = require('highcharts/modules/drilldown');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
Solid(Highcharts);
Exporting(Highcharts);
Export(Highcharts);
Accessibility(Highcharts);
Drilldown(Highcharts);

@Component({
  selector: 'app-ft',
  templateUrl: './ft.component.html',
  styleUrls: ['./ft.component.css']
})
export class FtComponent implements OnInit {
  loading = new LoadingEfectComponent();
  public id = +this._route.snapshot.paramMap.get('id');
  public obj:any = {};
  public objGraph:any = {
    DG: {
      PERTOTAL:0
    }
  };
  public claimDash:boolean = false;
  public client: any = {};
  public cuestionario: any = [];
  public catalog:any[];
  public catalogTL:any[];
  public tipoLinea:any[];
  public spn:any[];
  public spnIndex:any[];
  public editarData: boolean = false;
  public editarNC: boolean = false;
  public mdlItemList: boolean = false;
  searchText;

  identity = JSON.parse(localStorage.getItem('identity_user'));
  claimEdit = false;

  constructor(
    private _serviceLogin: LoginService,
    private _service:ClientesService,
    private _serviceDash:DashboardService,
    private _route: ActivatedRoute,
    private _serviceUsuario:UsuariosService,
    private _serviceItem:ItemService,
    private _router: Router
  ) { }

  ngOnInit(){
    this.verifyClaims();
    this.verifyClaimEdit();
    this.getCliente();
    this.getItems();
    this.getCatalog();
  }

  public registrarLog(){
    let clase = 'Ficha Técnica ' + this.client.Nombre;
    let obj = {
      //Controller: obj.Controller,
      Class: clase,
      //Table: 'ItemEmpresa',
      //ID: this.id,
      Message: 'ACCESO'
    }
    this._serviceUsuario.registrarLog(obj);
  }

  verifyClaimEdit(){
    var obj = {
      usuario: this.identity.Correo
    }
    this._serviceLogin.getUser(obj, false)
    .then(responseUsr=>{
      localStorage.setItem('identity_user', JSON.stringify(responseUsr.user));
      this.claimEdit = JSON.parse(localStorage.getItem('identity_user')).permisoLists.find(x => x.nombrePermiso === 'Editar Empresas') ? true : false;
    });
  }

  verifyClaims(){
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Empresas');
    this.claimDash = this.identity.permisoLists.find(x => x.nombrePermiso === 'Dashboard') == undefined ? false : true;
    if(claim == undefined && claim == null){
      this._router.navigate(['/sa-rm/admin']);
    }
    if(this.claimDash){
      this.getRegistros()
    }
  }

  public getCliente(){
    this._service.getCliente(this.id).then(response=>{
      this.client = response.content[0]; 
      this.spn = !this.client.NumProveedor || this.client.NumProveedor == '' ? [] : this.client.NumProveedor.split(",").map( Number );
      this.spnIndex = !this.client.NumProveedor || this.client.NumProveedor == '' ? [] : this.client.NumProveedor.split(",").map( Number );
      this.tipoLinea = !this.client.TipoLinea || this.client.TipoLinea == '' ? [] : this.client.TipoLinea.split(",").map( Number );
      if(this.client.statusacceso){
        $("#acceso").prop('checked', true);
      }
      this.registrarLog();
      console.log(this.client)
    });
  }

  public getCatalog(){    
    this.loading.onLoadingRequest();
    this._serviceUsuario.getCatalog(17)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.catalogTL = response.content;
        }
      }else if(response.response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public getItems(){    
    this._service.getItemEmpresaList(this.id).then(response=>{     
      this.cuestionario = response.content.map((x) =>
      {
        const factor = 10 ** 2;
        x.Resultado = Math.round(x.Resultado * factor)/ factor;
        return x
      });
    })
    .catch(error => {      
      console.log(error.toString());
    });
  }

  public open() : void {    
    this.editarData = true;
  }

  public update(){
    if(this.client.Eliminado && (this.client.MotivoInhabil == "" || !this.client.MotivoInhabil)){
      Swal.fire('Atención', "Debes seleccionar un motivo de inhabilitación", 'error');
    }else{
      this.client.TipoLinea = this.tipoLinea.toString();
      this.client.NumProveedor = this.spnIndex.toString();
      this.loading.onLoadingRequest();
      this._service.updateEmpresa(this.client)
          .then(response=>{
            if(response.status == 200){
              this.loading.onDoneRequest();
              this.editarData = false;
            }else{
              Swal.fire('Error', response.message, 'error');
            }        
          }).catch(error=>{   
            this.loading.onDoneRequest();   
            Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
          })
    }
  }

  public openNewContact(flag:boolean, id:number = 0){
    this.editarNC = flag;
    this.obj.idUser = id;
    this.obj.Nombre = "";
    this.obj.Correo = "";
    this.obj.Telefono = "";
    if(id != 0){
      var us = this.client.usersData.find(x=>x.UsuarioID == id);
      this.obj.Nombre = us.Nombre;
      this.obj.Correo = us.Correo;
      this.obj.Telefono = us.Telefono;
    }
  }
  
  public newContact(){
    this.obj.RolID = 1;
    this.obj.EmpresaID = this.id;
    this.loading.onLoadingRequest();
    this._serviceUsuario.update(this.obj)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        this.openNewContact(false);
        this.getCliente();
      }else if (response.status == 500){      
        Swal.fire('Error', response.message, 'error');
      }else {
        Swal.fire("Atención",response.message,"info");
      }
    }).catch(error=>{ 
      this.loading.onDoneRequest();     
      Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
    })
  }

  deleteContact(correo: string){ 
    Swal.fire({
      title: 'Atención',
      text: "¿Estas seguro que deseas eliminar el registro?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading.onLoadingRequest();
        this._serviceUsuario.deleteByUser(correo)
        .then(response=>{
          this.loading.onDoneRequest();
          if(response.status == 200){
            this.getCliente();
          }else{
            Swal.fire('Error', response.message, 'error');
          }
        }).catch(error=>{   
          this.loading.onDoneRequest();   
          Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
        })
      }
    })
  }

  public agregarItem(flag:boolean){
    if(flag){
      this.loading.onLoadingRequest();
      this._serviceItem.getItem(0)
      .then(response=>{
        this.loading.onDoneRequest();
        if(response.response.status == 200){
          if(response.content != null && response.content.length > 0){
            this.catalog = response.content;
            this.mdlItemList = flag;
          }
          else{
            Swal.fire("Atención","No hay items que mostrar.","info");
          }
        }else if(response.response.status == 500){
          Swal.fire("Error",response.message,"error");
        }
      })
      .catch(error=>{
        this.loading.onDoneRequest();
        console.log(error.toString());
      });
    }else{
      this.mdlItemList = flag;
    }
  }

  public guardarItem(){
    var objItem = {
      checkCuest: [],
      empresaID: this.id
    }
    objItem.checkCuest = [];
    
    for(var i = 0; i < this.catalog.length; i++){
      if($('#ckb-' + this.catalog[i].ItemID).prop('checked')){
        objItem.checkCuest.push(this.catalog[i].ItemID);  
      }         
    }
    if(objItem.checkCuest.length <= 0){
      Swal.fire('Atención', "Para agregar items es necesario elegir al menos uno de la tabla.", 'info');
      return false
    }
    this.loading.onLoadingRequest();
    this._service.updateItemEmpresa(objItem)
    .then(response=>{
      this.loading.onDoneRequest();
      this.agregarItem(false);
      if(response.status != 200){
        Swal.fire('Error', response.message, 'error');
      }else{
        Swal.fire('Éxito', response.message, 'success')
            .then(rs=>{
              this.getItems();
            });
      }    
    }).catch(error=>{   
      this.loading.onDoneRequest();   
      Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
    });
  }

  public openPreviewItem(id:number){
    this._router.navigate(['/sa-rm/admin-client/' + id + '/itemsublist/' + this.id + ',' + id]);
  }

  //--------------------------Seccion Dashboard------------------------
  
  public getRegistros(){
    this.loading.onLoadingRequest();
    this._serviceDash.getDashboardGeneral("Dashboard Transportista", this.id, "")//
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        this.objGraph = response
        const factor = 10 ** 2;
        this.objGraph.DG.PEROperaciones = Math.round(this.objGraph.DG.PEROperaciones * factor)/ factor;
        this.objGraph.DG.PERContrato = Math.round(this.objGraph.DG.PERContrato * factor)/ factor;
        this.objGraph.DG.PERDeseable = Math.round(this.objGraph.DG.PERDeseable * factor)/ factor;
        this.objGraph.DG.PERTOTAL = Math.round(this.objGraph.DG.PERTOTAL * factor)/ factor;
        console.log(response);
        this.graph();
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public graph(){
    var opciones:any = {
      chart: {
          type: 'solidgauge',
          height: '100%',
          backgroundColor: 'transparent'
      },
      title: {
        text: ''
      },
      tooltip: {
          borderWidth: 0,
          backgroundColor: 'none',
          shadow: false,
          style: {
              fontSize: '16px',
              color: 'black'
          },
          valueSuffix: '%',
          pointFormat: '{series.name}<br><span style="font-size:2em; color: {point.color}; font-weight: bold">{point.y}</span>',
          positioner: function (labelWidth) {
              return {
                  x: (this.chart.chartWidth - labelWidth) / 2,
                  y: (this.chart.plotHeight / 2) - 25
              };
          }
      },
      pane: {
          startAngle: 0,
          endAngle: 360,
          background: [{ // Track for Move
              outerRadius: '112%',
              innerRadius: '88%',
              backgroundColor: Highcharts.color("#D40511")
                  .setOpacity(0.3)
                  .get(),
              borderWidth: 0
          }, { // Track for Exercise
              outerRadius: '87%',
              innerRadius: '63%',
              backgroundColor: Highcharts.color("#FFEA90")
                  .get(),
              borderWidth: 0
          }, { // Track for Stand
              outerRadius: '62%',
              innerRadius: '38%',
              backgroundColor: Highcharts.color(Highcharts.getOptions().colors[1])
                  .setOpacity(0.3)
                  .get(),
              borderWidth: 0
          }]
      },
      yAxis: {
          min: 0,
          max: 100,
          lineWidth: 0,
          tickPositions: []
      },
      plotOptions: {
          solidgauge: {
              dataLabels: {
                  enabled: false
              },
              linecap: 'round',
              stickyTracking: false,
              rounded: true
          }
      },
      credits:{
        enabled: false
      },
      series: [{
          name: 'Mandatorios para contrato',
          data: [{
              color: "#D40511",
              radius: '112%',
              innerRadius: '88%',
              y: this.objGraph.DG.PERContrato
          }]
      }, {
          name: 'Mandatorios para visita de certificación',
          data: [{
              color: "#FFCC00",
              radius: '87%',
              innerRadius: '63%',
              y: this.objGraph.DG.PEROperaciones
          }]
      }, {
          name: 'No mandatorios',
          data: [{
              color: "black",
              radius: '62%',
              innerRadius: '38%',
              y: this.objGraph.DG.PERDeseable
          }]
      }]
    }

    Highcharts.chart('graph', opciones);
  }

  //--------------------------Seccion SPN-------------------------------
  public agregarSPN(){
    this.spn.push("");
    console.log(this.spn,this.spnIndex);
  }

  public deleteSPN(index){
    this.spn.splice(index, 1);
    this.spnIndex.splice(index, 1);
    console.log(this.spn);
  }

  //--------------------------Tarifas-----------------------------------
  public goRoute(route){
    this._router.navigate(['/sa-rm/admin-client/' + this.id + '/'+ route +'/' + this.id]);
  }
}
