import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import { 
  faTrash as fasTrash
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {
  fasTrash = fasTrash;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions:any = {
    dom: 'Bflrtip',
    buttons: [{
        extend:'excel',
        exportOptions: {
          columns: [0,1,2,3,4,5,6,7,8]
        }
      }
    ],
    language: {
        "decimal": "",
        "emptyTable": "No hay información",
        "info": "Mostrando _START_ a _END_ de _TOTAL_ Resultados",
        "infoEmpty": "Mostrando 0 to 0 of 0 Resultados",
        "infoFiltered": "(Filtrado de _MAX_ total resultados)",
        "infoPostFix": "",
        "thousands": ",",
        "lengthMenu": "Mostrar _MENU_ Resultados",
        "loadingRecords": "Cargando...",
        "processing": "Procesando...",
        "search": "Buscador:",
        "zeroRecords": "Sin resultados encontrados",
        "paginate": {
            "first": "Primero",
            "last": "Ultimo",
            "next": "Siguiente",
            "previous": "Anterior"
        }
    }
  };
  dtTrigger = new Subject();

  loading = new LoadingEfectComponent();
  public catalog:any[];
  public catalogComplete:any[];
  public catalogFilters:any={};
  public flagFilters:any={};
  public tipoServicio:number = 0;
  public flagInactiva:number = -1;

  identity = JSON.parse(localStorage.getItem('identity_user'));
  public deleted = this._route.snapshot.paramMap.get('deleted');
  claimEdit = false;

  constructor(
    private _service:ClientesService,
    private _serviceUsuario: UsuariosService,
    public router: Router,
    private _route: ActivatedRoute
  ) { }

  ngOnInit(){
    this.verifyClaims();
    this.getRegistros();
    this.registrarLog();
    this.getCatalog(22); //Origen
    this.getCatalog(23); //Destino
    this.getCatalog(24); //Unidad
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  verifyClaims(){
    if(this.validateClaim('Ver Eliminados')){
      this.dtOptions.buttons = [{
            extend:'excel',
            exportOptions: {
              columns: [0,1,2,3,4,5,6,7,8,9]
            }
          }
        ]
    }
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Empresas');
    this.claimEdit = this.identity.permisoLists.find(x => x.nombrePermiso === 'Editar Empresas') ? true : false;
    if(claim == undefined && claim == null){
      this.router.navigate(['/sa-rm/admin']);
    }
  }

  validateClaim(claim: string) {
    return this.identity.permisoLists.find(x => x.nombrePermiso === claim);
  }

  public registrarLog(){
    let clase = this.deleted == "true" ? 'Transportistas Inactivos' : 'Transportistas';
    let obj = {
      //Controller: obj.Controller,
      Class: clase,
      //Table: obj.Table,
      //ID: obj.ID,
      Message: 'ACCESO'
    }
    this._serviceUsuario.registrarLog(obj);
  }

  public getCatalog(type:number){    
    this._serviceUsuario.getCatalog(type)
    .then(response=>{
      if(response.response.status == 200){
        if(response.content != null && response.content.length > 0){
          if(type == 22){
            this.catalogFilters.Origen = response.content;
          }
          else if(type == 23){
            this.catalogFilters.Destino = response.content;
          }
          else if(type == 24){
            this.catalogFilters.Unidad = response.content;
          }
        }
      }else if(response.response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      console.log(error.toString());
    });
  }

  public getRegistros(){
    this.dtOptions.destroy;
    this.loading.onLoadingRequest();
    this._service.getCliente(0)
    .then(response=>{
      if(response.status == 200){
        if(response.content != null && response.content.length > 0){
          let datosComplete = response.content.map((x)=>{
            x.EliminadoValue = x.Eliminado ? 'Inactivo' : 'Activo';
            return x
          });
          let datos = response.content.map((x)=>{
            x.EliminadoValue = x.Eliminado ? 'Inactivo' : 'Activo';
            return x
          }).filter(x=> x.TipoServicio == this.tipoServicio);

          if(this.validateClaim('Ver Eliminados')){
            this.getRegistrosDeleted(datos, datosComplete);
          }
          else{
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              this.catalogComplete = datosComplete
              this.catalog = datos
              // Destroy the table first
              dtInstance.destroy();
              this.dtTrigger.next();
              this.loading.onDoneRequest();
            });
          }
        }
      }else if(response.status == 500){
        this.loading.onDoneRequest();
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public getRegistrosDeleted(datos:any = [], datosComplete:any = []){
    this._service.getClienteEliminado(0)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        if(response.content != null && response.content.length > 0){
          let datosCompleteDeleted = response.content.map((x)=>{
            x.EliminadoValue = x.Eliminado ? 'Inactivo' : 'Activo';
            return x
          });
          datosComplete = datosComplete.concat(datosCompleteDeleted);

          let datosDeleted = response.content.map((x)=>{
            x.EliminadoValue = x.Eliminado ? 'Inactivo' : 'Activo';
            return x
          }).filter(x=> x.TipoServicio == this.tipoServicio);
          datos = datos.concat(datosDeleted);

          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            this.catalogComplete = datosComplete
            this.catalog = datos
            // Destroy the table first
            dtInstance.destroy();
            this.dtTrigger.next();
            this.loading.onDoneRequest();
          });
        }
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public filters(){
    this.dtOptions.destroy;
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      switch(+this.flagInactiva) { 
        case 0: { 
           this.catalog = this.catalogComplete.filter(x=> x.TipoServicio == this.tipoServicio && !x.Eliminado
            && (!this.flagFilters.Unidad || (x.ProspectoID && x.ProspectoTipoUnidades.find(y=>y.Tipo == this.flagFilters.Unidad)))
            && (!this.flagFilters.Origen || (x.ProspectoID && x.ProspectoRutasPrincipales.find(y=>y.Origen == this.flagFilters.Origen)))
            && (!this.flagFilters.Destino || (x.ProspectoID && x.ProspectoRutasPrincipales.find(y=>y.Destino == this.flagFilters.Destino))));
           break; 
        } 
        case 1: { 
           this.catalog = this.catalogComplete.filter(x=> x.TipoServicio == this.tipoServicio && x.Eliminado
            && (!this.flagFilters.Unidad || (x.ProspectoID && x.ProspectoTipoUnidades.find(y=>y.Tipo == this.flagFilters.Unidad)))
            && (!this.flagFilters.Origen || (x.ProspectoID && x.ProspectoRutasPrincipales.find(y=>y.Origen == this.flagFilters.Origen)))
            && (!this.flagFilters.Destino || (x.ProspectoID && x.ProspectoRutasPrincipales.find(y=>y.Destino == this.flagFilters.Destino))));
           break; 
        } 
        default: { 
           this.catalog = this.catalogComplete.filter(x=> x.TipoServicio == this.tipoServicio
            && (!this.flagFilters.Unidad || (x.ProspectoID && x.ProspectoTipoUnidades.find(y=>y.Tipo == this.flagFilters.Unidad)))
            && (!this.flagFilters.Origen || (x.ProspectoID && x.ProspectoRutasPrincipales.find(y=>y.Origen == this.flagFilters.Origen)))
            && (!this.flagFilters.Destino || (x.ProspectoID && x.ProspectoRutasPrincipales.find(y=>y.Destino == this.flagFilters.Destino))));
           break; 
        } 
     } 
      // Destroy the table first
      dtInstance.destroy();
      this.dtTrigger.next();
      this.loading.onDoneRequest();
    });
  }

  deleteEmpresa(id: number){
    Swal.fire({
      title: 'Atención',
      text: "¿Estas seguro que deseas eliminar el registro? Esta acción no podrá deshacerse.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading.onLoadingRequest();
        this._service.deleteEmpresa(id)
        .then(response=>{
          this.loading.onDoneRequest();
          if(response.status == 200){
            Swal.fire('Éxito', response.message, 'success');
            var index =  this.catalogComplete.findIndex(x => x.EmpresaID == id);
            if (index > -1) {
              this.catalogComplete.splice(index, 1);
            }
            this.filters();
          }else{
            Swal.fire('Error', response.message, 'error');
          }
        }).catch(error=>{  
          this.loading.onDoneRequest();    
          Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
        })
      }
    })
  }
}
