import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reactivo'
})
export class ReactivoPipe implements PipeTransform {

  transform(reactivos: any[], searchText: string): unknown {
    if (!reactivos) return [];
    if (!searchText) return reactivos;
  
    searchText = searchText.toLowerCase();
  
    return reactivos.filter( it => {
      return it.Nombre.toLowerCase().includes(searchText)
      || it.NombreTipo.toLowerCase().includes(searchText)
      || it.NombreTipoPersona.toLowerCase().includes(searchText);
    });
  }

}
