import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterclientlist'
})
export class FilterclientlistPipe implements PipeTransform {
  transform(items: any[], searchClient: string): any[] {
    if (!items) return [];
    if (!searchClient) return items;

    searchClient = searchClient.toLowerCase();

    return items.filter( it => {
      return it.NumProveedor.toLowerCase().includes(searchClient) 
      || it.Nombre.toLowerCase().includes(searchClient)
      || it.NombreComercial.toLowerCase().includes(searchClient)
      || it.ProveedorVal.toLowerCase().includes(searchClient)
      || it.NombreTipoPersona.toLowerCase().includes(searchClient)
      || it.FechaCreacion.toLowerCase().includes(searchClient);
    });
  }

}
