<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row mapa-sitio">
            <div class="col-12">
                <h2>ORIGEN / DESTINO </h2> 
            </div>
        </div>
        <div class="row filtros">
            <div class="col-sm-12 col-md-6 col-lg-3">
                <label style="color: white;">STATUS</label>
                <select [(ngModel)]="filter.status" class="form-control">
                    <option value="-1">TODOS</option>
                    <option value="210">ACTIVOS</option>
                    <option value="4">RECHAZADOS</option>
                    <option value="5">EXPIRADOS</option>
                    <option value="6">ELIMINADOS</option>
                </select>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <label>TIPO DE SERVICIO</label>
                <select [(ngModel)]="filter.extranjera" class="form-control">
                    <option value="-1">TODAS</option>
                    <option value="0">NACIONAL</option>
                    <option value="1">EXTRANJERA</option>
                    <option value="2">AEROLINEA</option>
                </select>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <button class='btn btn-danger btn-fluid' type='button' style='margin-top: 31px;' (click)="getRegistros()">
                    Filtrar
                </button>
            </div>
        </div>
        
        <div class="row tabla-catalogo">  
            <div class="col-12">
                <table class="table table-responsive table-sm table-hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"> 
                    <thead>
                        <tr>
                            <th width="30%">Línea Transportista</th>
                            <th width="30%">Status</th>
                            <th width="30%">Origen</th>
                            <th width="30%">Destino</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of datos;">
                            <td>{{group.RazonSocial}}</td>
                            <td>{{group.NombreStatus}}</td>
                            <td>{{group.Origen}}</td>
                            <td>{{group.Destino}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>             
        </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>