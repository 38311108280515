import { Injectable } from '@angular/core';
import { GLOBAL } from './../global';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProspectoService {
  private url:string;
  userToken = localStorage.getItem('token').slice(1).slice(0,-1);

  constructor(private _http:HttpClient) {
    this.url = GLOBAL.url + 'Prospectos';
  }

  getProspecto(id:number, status:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getProspecto?id=' + id + '&status=' + status, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getProspectoAlert(tipo:string){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getProspectoAlert?tipo=' + tipo, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  updateProspecto(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.post(this.url + '/updateProspecto', obj, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  deleteProspecto(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.delete(this.url + '/deleteProspecto?id=' + id, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }
  
  deleteProspectoDef(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.delete(this.url + '/deleteProspectoDef?id=' + id, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }
  
  updateProspectoDisponibilidad(id:number, disp:Date){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/updateProspectoDisponibilidad?id=' + id + '&disp=' + disp, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }
  
  rechazarProspecto(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/rechazarProspecto?id=' + id, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  habilitarProspectoRechazado(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/habilitarProspectoRechazado?id=' + id, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  paseCandidato(id:number, empresaID:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/paseCandidato?id=' + id + '&empresaID=' + empresaID, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }
  //#region Rutas Principales
  getRutasPrincipalesByProspecto(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getRutasPrincipalesByProspecto?id=' + id, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  updateRutasPrincipales(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.post(this.url + '/updateRutasPrincipales', obj, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  deleteRutasPrincipales(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.delete(this.url + '/deleteRutasPrincipales?id=' + id, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }

  PostRutasPrincipalesCM(formdata){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.post(this.url + '/PostRutasPrincipalesCM', formdata, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }
  
  updateRutasPrincipalesCM(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.post(this.url + '/updateRutasPrincipalesCM', obj, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }
  //#endregion

  //#region Tipo Unidades
  getTipoUnidadesByProspecto(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getTipoUnidadesByProspecto?id=' + id, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  updateTipoUnidades(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.post(this.url + '/updateTipoUnidades', obj, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  deleteTipoUnidades(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.delete(this.url + '/deleteTipoUnidades?id=' + id, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }

  PostTipoUnidadesCM(formdata){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.post(this.url + '/PostTipoUnidadesCM', formdata, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  updateTipoUnidadesCM(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.post(this.url + '/updateTipoUnidadesCM', obj, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }
  //#endregion

  getItemByProspecto(id:number, ItemID:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getItemByProspecto?id=' + id + '&ItemID=' + ItemID, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }
  
  updateReactivosProspecto(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.post(this.url + '/updateReactivosProspecto', obj, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  uploadFile(formdata){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.post(GLOBAL.url + 'Values', formdata, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }
}
