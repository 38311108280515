import { Injectable } from '@angular/core';
import { GLOBAL } from './../global';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class CodigoQRService {
  private url:string;
  userToken = localStorage.getItem('token') ? localStorage.getItem('token').slice(1).slice(0,-1) : '';

  constructor(private _http:HttpClient) {
    this.url = GLOBAL.url + 'Codigoqr';
  }

  getCodigosqr(){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getCodigosqr', {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  updateCodigoqr(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.post(this.url + '/updateCodigoqr', obj, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  deleteCodigoqr(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.delete(this.url + '/deleteCodigoqr?id=' + id, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }

  getCodigoqrInfo(empresaID:number, CodigoQRID:number){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': '*/*'
    });
    return this._http.get(this.url + '/getCodigoqrInfo?empresaID=' + empresaID + '&CodigoQRID=' + CodigoQRID , {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getCodigoqrInfoMultiple(ItemEmpresaID:number, CodigoQRID:number){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': '*/*'
    });
    return this._http.get(this.url + '/getCodigoqrInfo?ItemEmpresaID=' + ItemEmpresaID + '&CodigoQRID=' + CodigoQRID , {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }
}
