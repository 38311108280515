import { Injectable } from '@angular/core';
import { GLOBAL } from './../global';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ItemService {
  private url:string;
  userToken = localStorage.getItem('token').slice(1).slice(0,-1);

  constructor(private _http:HttpClient) {
    this.url = GLOBAL.url + 'Items';
  }  

  getTipoCampo(){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(GLOBAL.url + 'Usuarios/getCatalog?idCatalog=1', {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getGroup(){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getGroup', {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  updateGroup(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.post(this.url + '/updateGroup/', obj, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }

  deleteGroup(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.delete(this.url + '/deleteGroup?id=' + id, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }

  getItem(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getItem?idItem=' + id, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  updateItem(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.post(this.url + '/updateItem/', obj, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }

  deleteItem(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.delete(this.url + '/deleteItem?id=' + id, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }

  getReactivosbyItem(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getReactivosbyItem?idItem=' + id, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  updateReactivo(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.post(this.url + '/updateReactivo/', obj, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }

  deleteReactivo(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.delete(this.url + '/deleteReactivo?id=' + id, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }

  newVersion(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getNewVersion?idItem=' + id, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }
}
