import { Component, OnInit } from '@angular/core';
import { ItemService } from 'src/app/services/items/item.service';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';

@Component({
  selector: 'app-updateitem',
  templateUrl: './updateitem.component.html',
  styleUrls: ['./updateitem.component.css']
})
export class UpdateitemComponent implements OnInit {
  identity = JSON.parse(localStorage.getItem('identity_user'));
  loading = new LoadingEfectComponent();
  private itemId = this._route.snapshot.paramMap.get('item');
  obj:any = { 
    NacionalExtranjera: "Nacional",
    GrupoID: "0"
  };
  prospecto = false;
  prospectoEXT = false;
  public grupos:any[];

  constructor(
    private _service:ItemService,
    private router:Router,
    private _route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.verifyClaims();
    this.loading.onDoneRequest();
    this.getGroup();
    if(+this.itemId != 0){
      this.getItem();
    }
  }

  verifyClaims(){
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Configuraciones');
    if(claim == undefined && claim == null){
      this.router.navigate(['/sa-rm/admin']);
    }
    this.identity.permisoLists
  }

  public getGroup(){    
    this.loading.onLoadingRequest();
    this._service.getGroup()
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.grupos = response.content;
        }
      }else if(response.response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public getItem(){    
    this.loading.onLoadingRequest();
    this._service.getItem(+this.itemId)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.obj = response.content[0];
          if(this.obj.NacionalExtranjera.includes('Prospecto')){
            this.prospecto = true;
          }
        }
        else{
          Swal.fire("Atención","No hay items que mostrar.","info");
        }
      }else if(response.response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public saveItem(){
    this.loading.onLoadingRequest();
    this._service.updateItem(this.obj)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        this.router.navigate(['/sa-rm/updateitem2/' + response.itemID.toString()]);
      }else if (response.status == 500){      
        Swal.fire('Error', response.message, 'error');
      }else {
        Swal.fire("Atención",response.message,"info");
      }
    }).catch(error=>{ 
      this.loading.onDoneRequest();     
      Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
    })
  }

  public isProspecto(){
    if(this.prospecto){
      this.obj.NacionalExtranjera = "Prospecto"
    }
    else{
      this.obj.NacionalExtranjera = "Nacional"
    }
  }
}
