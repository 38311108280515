import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { Router } from '@angular/router';
import { GLOBAL } from './../../../services/global';

@Component({
  selector: 'app-log-usuarios',
  templateUrl: './log-usuarios.component.html',
  styleUrls: ['./log-usuarios.component.css']
})
export class LogUsuariosComponent implements OnInit {
  loading = new LoadingEfectComponent();
  searchText;
  public users:any[];
  public datos:any[];
  public filter:any = {
    desde: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
    hasta: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
    eliminado: 2,
    status: [],
    usuariosID: []
  };

  identity = JSON.parse(localStorage.getItem('identity_user'));

  constructor(
    private _serviceUsuario: UsuariosService,
    private _service:DashboardService,
    private _router: Router,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.verifyClaims();
    this.getUsuarios();
    this.loading.onDoneRequest();
  }

  public getUsuarios(){    
    this.loading.onLoadingRequest();
    this._serviceUsuario.getRegistros()
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        if(response.content != null && response.content.length > 0){
           //Filtro para los usuarios que no deben aparecer en el log
          this.users = response.content.filter(x=>!x.permisoLists.includes(11));
          console.log(this.users);
        }else{
          Swal.fire("Sin registros","Aún no se han registrado usuarios","info");      
        }
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public getRegistros(){
    console.log(this.filter.usuariosID.toString());
    window.open(GLOBAL.urlIMG + 'Excel/ReporteLogUsuarios?desde=' + this.filter.desde
    + '&hasta=' + this.filter.hasta
    + '&status=' + this.filter.status.toString()
    + '&eliminado=' + this.filter.eliminado
    + '&usuariosID=' + this.filter.usuariosID.toString());
    return false
    this.loading.onLoadingRequest();
    this._service.getReporteIngreso(this.filter.desde, this.filter.hasta, this.filter.status)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        this.datos = response.DR;
        console.log(this.datos);
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  verifyClaims(){
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === "Reporte Log Usuarios");
    if(claim == undefined && claim == null){
      this._router.navigate(['/sa-rm/admin']);
    }
  }
}
