import { Injectable } from '@angular/core';
import { GLOBAL } from './../global';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NoticiaService {
  private url:string;
  userToken = localStorage.getItem('token') ? localStorage.getItem('token').slice(1).slice(0,-1) : '';

  constructor(private _http:HttpClient) {
    this.url = GLOBAL.url + 'Noticia';
  }

  getNoticias(){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getNoticias', {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getNoticiasByUser(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getNoticiasByUser?id=' + id, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  updateNoticia(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.post(this.url + '/updateNoticia/', obj, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }

  deleteNoticia(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.delete(this.url + '/deleteNoticia?id=' + id, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }
  
  uploadFile(formdata){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.post(GLOBAL.url + 'Values', formdata, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }
}
