import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers:[DatePipe]
})
export class DashboardComponent implements OnInit {
  loading = new LoadingEfectComponent();
  identity = JSON.parse(localStorage.getItem('identity_user'));
  public cuestionario: any = [];
  searchText;
  public todayDate:any = new Date();

  constructor(
    private datepipe: DatePipe,
    private _service:ClientesService,
    private _route: ActivatedRoute,
    public _router: Router,
    private _serviceUsuario:UsuariosService,
  ) { }

  ngOnInit(){
    this.todayDate = this.datepipe.transform(this.todayDate, 'yyyy-MM-dd');
    this.getItems();
  }

  public getItems(){    
    this._service.getItemEmpresaList(this.identity.empresa.EmpresaID).then(response=>{     
      this.cuestionario = response.content.filter(x=> x.VisibleEmpresa );
    })
    .catch(error => {      
      console.log(error.toString());
    });
  }

  verCuestionario(idcc:number) {
    this._router.navigate(['/sa-rm/client/itemsublist/' + idcc]);
  }

  public guardarItem(){
    Swal.fire({
      title: 'Atención',
      text: "¿Estas seguro que deseas agregar una incidencia?",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        
        var objItem = {
          checkCuest: [14],
          empresaID: this.identity.empresa.EmpresaID
        }
    
        this.loading.onLoadingRequest();
        this._service.updateItemEmpresa(objItem)
        .then(response=>{
          this.loading.onDoneRequest();
          if(response.status != 200){
            Swal.fire('Error', response.message, 'error');
          }else{
            Swal.fire('Éxito', response.message, 'success')
                .then(rs=>{
                  this.getItems();
                });
          }    
        }).catch(error=>{   
          this.loading.onDoneRequest();   
          Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
        });

      }
    })
  }

}