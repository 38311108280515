<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row">
            <div class="col-sm-12">
              <a (click)="regresar()" style="cursor: pointer;" class="float-left link-regresar"><img src="../../../assets/IMG/arrow_back.png" /></a>
            </div>
        </div>
        <div class="row mapa-sitio">
            <div class="col-12">
                <h2>TARIFAS</h2> 
            </div>
            <div class="col-9">
                <h3 style="color: white;">{{client.Nombre}}</h3> 
            </div>
        </div>
        <div class="row filtros">
            <div class="col-sm-12 col-md-6 col-lg-3">
                <label>NUM. PROVEEDOR (SPN)</label>
                <select [(ngModel)]="filter.spn" class="form-control" name="spn">
                    <option *ngFor="let numSPN of spn;" value="{{numSPN}}">{{numSPN}}</option>
                </select>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <label>DESDE</label>
                <input type="date" class="form-control" name="desde" [(ngModel)]="filter.desde">
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <label>HASTA</label>
                <input type="date" class="form-control" name="hasta" [(ngModel)]="filter.hasta">
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <button class='btn btn-danger btn-fluid' type='button' style='margin-top: 31px;' (click)="getRegistros(2)">
                    FILTRAR HISTORIAL
                </button>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <button class='btn btn-danger btn-fluid' type='button' style='margin-top: 31px;' (click)="getRegistros(1)">
                    FILTRAR ACTUAL
                </button>
            </div>
        </div>
        <div class="row tabla-catalogo">  
            <div class="col-sm-12 col-md-6 col-lg-3">
                <button class='btn btn-danger btn-fluid' type='button' style='margin-top: 31px;' (click)="openCargaExcel(true)">
                    CARGAR
                </button>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <button class='btn btn-warning btn-fluid' type='button' style='margin-top: 31px;'  (click)="exportexcel()">
                    EXPORTAR
                </button>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <input type="text" [(ngModel)]="searchText" class="form-control buscador" placeholder="Buscador">
                </div>
                <table class="table table-responsive"> 
                    <thead>
                        <tr>
                            <th>SPN</th>
                            <th>RATE_OFFERING_GID</th>
                            <th>RATE_GEO_GID</th>
                            <th>RATE_VERSION_GID</th>
                            <th>SERVPROV_GID</th>
                            <th>IS_ACTIVE</th>
                            <th>EXPIRATION_DATE</th>
                            <th>X_LANE_GID</th>
                            <th>SOURCE_REGION_GID</th>
                            <th>DEST_REGION_GID</th>
                            <th>RATE_GEO_COST_GROUP_GID</th>
                            <th>CHARGE_AMOUNT</th>
                            <th>INSERT_USER (OTM)</th>
                            <th>INSERT_DATE (OTM)</th>
                            <th>UPDATE_USER (OTM)</th>
                            <th>UPDATE_DATE (OTM)</th>
                            <th>INSERT_USER</th>
                            <th>INSERT_DATE</th>
                            <th>UPDATE_USER</th>
                            <th>UPDATE_DATE</th>
                            <th>STATUS</th>
                            <th>ENVIAR</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of datos | tarifas: searchText;">
                            <td>{{group.SPN}}</td>
                            <td>{{group.RATE_OFFERING_GID}}</td>
                            <td>{{group.RATE_GEO_GID}}</td>
                            <td>{{group.RATE_VERSION_GID}}</td>
                            <td>{{group.SERVPROV_GID}}</td>
                            <td>{{group.IS_ACTIVE}}</td>
                            <td>{{group.EXPIRATION_DATE | date:'dd/MM/yyyy'}}</td>
                            <td>{{group.X_LANE_GID}}</td>
                            <td>{{group.SOURCE_REGION_GID}}</td>
                            <td>{{group.DEST_REGION_GID}}</td>
                            <td>{{group.RATE_GEO_COST_GROUP_GID}}</td>
                            <td>{{group.CHARGE_AMOUNT}}</td>
                            <td>{{group.INSERT_USER_OTM}}</td>
                            <td>{{group.INSERT_DATE_OTM | date:'dd/MM/yyyy'}}</td>
                            <td>{{group.UPDATE_USER_OTM}}</td>
                            <td>{{group.UPDATE_DATE_OTM | date:'dd/MM/yyyy'}}</td>
                            <td>{{group.INSERT_USER_NAME}}</td>
                            <td>{{group.INSERT_DATE | date:'dd/MM/yyyy'}}</td>
                            <td>{{group.UPDATE_USER_NAME}}</td>
                            <td>{{group.UPDATE_DATE | date:'dd/MM/yyyy'}}</td>
                            <td *ngIf="group.Status == 0">Por enviar</td>
                            <td *ngIf="group.Status == 1">Enviada</td>
                            <td *ngIf="group.Status == 2">Aceptada</td>
                            <td *ngIf="group.Status == 3">Rechazada</td>
                            <td><input *ngIf="group.Status == 0" type="checkbox" checked (click)="selectTarifa(group.EmpresaTarifaID)"></td>
                        </tr>
                    </tbody>
                </table>
                <table [hidden]="true" class="table table-responsive"  id="tableEmpresas"> 
                    <thead>
                        <tr>
                            <th>RATE_OFFERING_GID</th>
                            <th>RATE_GEO_GID</th>
                            <th>RATE_VERSION_GID</th>
                            <th>SERVPROV_GID</th>
                            <th>IS_ACTIVE</th>
                            <th>EXPIRATION_DATE</th>
                            <th>X_LANE_GID</th>
                            <th>SOURCE_REGION_GID</th>
                            <th>DEST_REGION_GID</th>
                            <th>RATE_GEO_COST_GROUP_GID</th>
                            <th>CHARGE_AMOUNT</th>
                            <th>INSERT_USER</th>
                            <th>INSERT_DATE</th>
                            <th>UPDATE_USER</th>
                            <th>UPDATE_DATE</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of datos | tarifas: searchText;">
                            <td>{{group.RATE_OFFERING_GID}}</td>
                            <td>{{group.RATE_GEO_GID}}</td>
                            <td>{{group.RATE_VERSION_GID}}</td>
                            <td>{{group.SERVPROV_GID}}</td>
                            <td>{{group.IS_ACTIVE}}</td>
                            <td>{{group.EXPIRATION_DATE | date:'dd/MM/yyyy'}}</td>
                            <td>{{group.X_LANE_GID}}</td>
                            <td>{{group.SOURCE_REGION_GID}}</td>
                            <td>{{group.DEST_REGION_GID}}</td>
                            <td>{{group.RATE_GEO_COST_GROUP_GID}}</td>
                            <td>{{group.CHARGE_AMOUNT}}</td>
                            <td>{{group.INSERT_USER_OTM}}</td>
                            <td>{{group.INSERT_DATE_OTM | date:'dd/MM/yyyy'}}</td>
                            <td>{{group.UPDATE_USER_OTM}}</td>
                            <td>{{group.UPDATE_DATE_OTM | date:'dd/MM/yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>             
            <div class="col-sm-12 col-md-6 col-lg-3">
                <button [hidden]="!tarifaSelect || tarifaSelect.length == 0" class='btn btn-danger btn-fluid' type='button' style='margin-top: 31px;'  (click)="cambioStatus()">
                    ENVIAR TARIFAS
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalCargar" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': modalCargar ? 'block' : 'none', 'opacity': 1}"> 
    <div class="modal-dialog modal-lg modal-dialog-centered" role="dialog" >
      <div class="modal-content">
        <div class="modal-header mdl-hdr text-center">
          <h5 class="modal-title w-100"></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="openCargaExcel(false)">
            <span aria-hidden="true" class="mdl-close" >&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form #createForm2="ngForm" (ngSubmit)="cargaExcel()" autocomplete="off" appIdentityRevealed>
                <div class="form-group">
                    <input type="file" name="URLPDF" (change)="obtenerExcel($event)">
                </div>
                <div class="div-btns row pt-3">   
                    <div class="col-6">
                      <button class="btn btn-fluid btn-Eliminar btnTableUp" (click)="openCargaExcel(false)" data-dismiss="modal" type="button">CANCELAR</button>            
                    </div>
                    <div class="col-6">     
                      <button class="btn btn-fluid btn-layout btnTableUp">GUARDAR</button>    
                    </div>
                </div>
            </form>            
        </div>       
      </div>
    </div>
</div>

<div class="modal fade" id="modalPreview" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': modalPreview ? 'block' : 'none', 'opacity': 1}"> 
    <div class="modal-dialog modal-lg modal-dialog-centered" role="dialog" >
      <div class="modal-content">
        <div class="modal-header mdl-hdr text-center">
          <h5 class="modal-title w-100"></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="openModalPreview(false)">
            <span aria-hidden="true" class="mdl-close" >&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <table class="table table-responsive"> 
                <thead>
                    <tr>
                        <th>SPN</th>
                        <th>RATE_OFFERING_GID</th>
                        <th>RATE_GEO_GID</th>
                        <th>RATE_VERSION_GID</th>
                        <th>SERVPROV_GID</th>
                        <th>IS_ACTIVE</th>
                        <th>EXPIRATION_DATE</th>
                        <th>X_LANE_GID</th>
                        <th>SOURCE_REGION_GID</th>
                        <th>DEST_REGION_GID</th>
                        <th>RATE_GEO_COST_GROUP_GID</th>
                        <th>CHARGE_AMOUNT</th>
                        <th>INSERT_USER (OTM)</th>
                        <th>INSERT_DATE (OTM)</th>
                        <th>UPDATE_USER (OTM)</th>
                        <th>UPDATE_DATE (OTM)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let group of previewData.content;">
                        <td>{{group.SPN}}</td>
                        <td>{{group.RATE_OFFERING_GID}}</td>
                        <td>{{group.RATE_GEO_GID}}</td>
                        <td>{{group.RATE_VERSION_GID}}</td>
                        <td>{{group.SERVPROV_GID}}</td>
                        <td>{{group.IS_ACTIVE}}</td>
                        <td>{{group.EXPIRATION_DATE}}</td>
                        <td>{{group.X_LANE_GID}}</td>
                        <td>{{group.SOURCE_REGION_GID}}</td>
                        <td>{{group.DEST_REGION_GID}}</td>
                        <td>{{group.RATE_GEO_COST_GROUP_GID}}</td>
                        <td>{{group.CHARGE_AMOUNT}}</td>
                        <td>{{group.INSERT_USER_OTM}}</td>
                        <td>{{group.INSERT_DATE_OTM | date:'dd/MM/yyyy'}}</td>
                        <td>{{group.UPDATE_USER_OTM}}</td>
                        <td>{{group.UPDATE_DATE_OTM | date:'dd/MM/yyyy'}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="div-btns row pt-3">   
                <div class="col-6">
                  <button class="btn btn-fluid btn-Eliminar btnTableUp" (click)="openModalPreview(false)" data-dismiss="modal" type="button">CANCELAR</button>            
                </div>
                <div class="col-6">     
                  <button class="btn btn-fluid btn-layout btnTableUp" (click)="guardarTarifas()">GUARDAR</button>    
                </div>
            </div>
        </div>       
      </div>
    </div>
</div>

<div class="modal fade" id="modalErrors" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': modalErrors ? 'block' : 'none', 'opacity': 1}"> 
    <div class="modal-dialog modal-lg modal-dialog-centered" role="dialog" >
      <div class="modal-content">
        <div class="modal-header mdl-hdr text-center">
          <h5 class="modal-title w-100"></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="openModalErrors(false)">
            <span aria-hidden="true" class="mdl-close" >&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <table class="table table-responsive"> 
                <thead>
                    <tr>
                        <th width="10%">Celda</th>
                        <th width="100%">Mensaje Error</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let group of previewData.errors;">
                        <td>{{group.Celda}}</td>
                        <td>{{group.Error}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="div-btns row pt-3">   
                <div class="col-12">
                  <button class="btn btn-fluid btn-Eliminar btnTableUp" (click)="openModalErrors(false)" data-dismiss="modal" type="button">CERRAR</button>            
                </div>
            </div>
        </div>       
      </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>