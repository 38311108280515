<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row mapa-sitio">
            <div class="col-12">
                <h2>Alertas Prospectos {{tipo}}</h2> 
            </div>
        </div>
        
        <div class="row tabla-catalogo">  
            <div class="col-12">
                <table class="table table-responsive table-sm table-hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"> 
                    <thead>
                        <tr>
                            <th width="10%">FECHA DE CREACIÓN</th>
                            <th width="30%">NOMBRE ENTREVISTADOR</th>
                            <th width="30%">RAZÓN SOCIAL</th>
                            <th width="30%">NOMBRE COMERCIAL</th>
                            <th width="30%">TIPO DE SERVICIO</th>
                            <th width="30%">TIPO PERSONA</th>
                            <th width="30%">STATUS</th>
                            <th width="10%">FECHA DE EXPIRACIÓN</th>
                            <th width="10%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of datos;">
                            <td>{{group.INSERT_DATE | date:'dd/MM/yyyy'}}</td>
                            <td>{{group.Create_User_Name}}</td>
                            <td>{{group.RazonSocial}}</td>
                            <td>{{group.NombreComercial}}</td>
                            <td>{{group.NombreTipoServicio}}</td>
                            <td>{{group.NombreTipoPersona}}</td>
                            <td style="text-wrap: nowrap;">{{group.NombreStatus}}
                                <img *ngIf="group.Status == 0" style="width:40%" src="./../../../../../assets/IMG/resultado_intermedio.png">
                                <img *ngIf="group.Status == 1" style="width:40%" src="./../../../../../assets/IMG/resultado_fundamental.png">
                                <img *ngIf="group.Status == 2" style="width:40%" src="./../../../../../assets/IMG/resultado_avanzado.png">
                            </td>
                            <td>{{group.EXPIRE_DATE | date:'dd/MM/yyyy'}}</td>
                            <td style="text-wrap: nowrap;">
                                <button class="btn btn-layout btn-ch btnTableUp ml-3 p-0" routerLink="/sa-rm/admin/prospect-ft/{{group.ProspectoID}}">VER</button>
                                <ng-template [ngIf]="validateClaim('Candidato Prospectos') && tipo == 'Pendientes'">
                                    &nbsp;
                                    <button class="btn btn-eliminar" (click)="paseCandidato(group)" title="Pase a candidato">
                                        <fa-icon [icon]="faTruck"></fa-icon>
                                    </button>
                                </ng-template>
                                <ng-template [ngIf]="validateClaim('Candidato Prospectos') && tipo == 'Pendientes'">
                                    &nbsp;
                                    <button class="btn btn-eliminar" style="background-color: red;" (click)="rechazarProspecto(group.ProspectoID)" title="Rechazar Prospecto">
                                        <fa-icon [icon]="faTimes"></fa-icon>
                                    </button>
                                </ng-template>
                                <button *ngIf="validateClaim('Activacion Prospectos') && tipo == 'Expirados'" class="btn btn-eliminar" (click)="updateDisponibilidadModal(group.ProspectoID)" title="Aumentar disponibilidad">
                                    <fa-icon [icon]="faAddressBook"></fa-icon>
                                </button>
                                <ng-template [ngIf]="validateClaim('Alta Prospectos') && (group.Status < 2 || group.Status == 5) && identity.Rol == 2">
                                    &nbsp;
                                    <button class="btn btn-eliminar" (click)="deleteProspecto(group.ProspectoID)" title="Eliminar">
                                        <fa-icon [icon]="fasTrash"></fa-icon>
                                    </button>
                                </ng-template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>             
        </div>
        
    </div>
</div>

<div class="modal fade" id="ExtModal" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': ExtModal ? 'block' : 'none', 'opacity': 1}"> 
    <div class="modal-dialog modal-md modal-dialog-centered" role="dialog" >
      <div class="modal-content">
        <div class="modal-header mdl-hdr text-center">
          <h5 class="modal-title w-100">Actualizar Disponibilidad</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="updateDisponibilidadModal(0)">
            <span aria-hidden="true" class="mdl-close" >&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form #FormDisponibilidad="ngForm" (ngSubmit)="updateProspectoDisponibilidad()" autocomplete="off" appIdentityRevealed>
                <div class="form-group">
                    <label for="dispDate">FECHA DE DISPONIBILIDAD</label>
                    <input type="date" class="form-control" name="dispDate" [(ngModel)]="dispDate">
                </div>
                <div class="div-btns row pt-3">   
                    <div class="col-6">
                      <button class="btn btn-fluid btn-Eliminar btnTableUp" (click)="updateDisponibilidadModal(0)" data-dismiss="modal" type="button">CANCELAR</button>            
                    </div>
                    <div class="col-6">     
                      <button class="btn btn-fluid btn-layout btnTableUp">GUARDAR</button>    
                    </div>
                </div>
            </form>            
        </div>       
      </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>