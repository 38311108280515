import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashboardEmpresa'
})
export class DashboardEmpresaPipe implements PipeTransform {

  transform(items: any[], searchDash: string): unknown {
    if (!items) return [];
    if (!searchDash) return items;
  
    searchDash = searchDash.toLowerCase();
  
    return items.filter( it => {
      return it.NombreEmpresa.toLowerCase().includes(searchDash)
    });
  }
}
