import { Component, Inject, OnInit } from '@angular/core';
import { GLOBAL } from './../../services/global';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import * as $ from 'jquery';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  private url:string;
  public Empresas:boolean = true;
  public Dashboard:boolean = true;
  public Configuraciones:boolean = true;

  constructor(
    public router: Router,
    @Inject(DOCUMENT) document: any
  ) { 

  }

  identity = JSON.parse(localStorage.getItem('identity_user'));

  ngOnInit(): void {
    this.verifyClaims();
    var url = document.location.href.split('/');
    var men = url[url.length - 1];
    console.log(men)
    $("#" + men).addClass("active-menu");
  }

  verifyClaims(){
    var claim1 = this.identity.permisoLists.find(x => x.nombrePermiso === 'Empresas');
    var claim2 = this.identity.permisoLists.find(x => x.nombrePermiso === 'Dashboard');
    var claim3 = this.identity.permisoLists.find(x => x.nombrePermiso === 'Configuraciones');
    if(claim1 == undefined && claim1 == null){
      this.Empresas = false;
    }
    if(claim2 == undefined && claim2 == null){
      this.Dashboard = false;
    }
    if(claim3 == undefined && claim3 == null){
      this.Configuraciones = false;
    }
  }

  active(id){
    $(".active-menu").removeClass("active-menu");
    $("#" + id).addClass("active-menu");
    if(id == "client"){
      id = "client/false"
    }
    this.router.navigate(['/sa-rm/admin/' + id]);
  }
}
