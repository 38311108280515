<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row mapa-sitio">
            <div class="col-9">
                <h2>{{identity.empresa.Nombre}}</h2> 
                <h5 style="color: white;">SPN: {{identity.empresa.NumProveedor}}</h5> 
            </div>
        </div>
        <div class="row principal">
            <div class="col-sm-12">
                <span style="font-weight: bold; color: white;">Listado de Items {{titleAlerta}}</span>
            </div>
        </div>
        <div class="row tabla-catalogo">  
            <div class="col-12">
                <div class="form-group">
                    <input type="text" [(ngModel)]="searchText" class="form-control buscador" placeholder="Buscador">
                </div>
                <table class="table table-responsive"> 
                    <thead>
                    <tr>
                        <th  width="100%">Item</th>
                        <th  width="20%">Disponibilidad</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of cuestionario | catalog: searchText;" (click)="verCuestionario(group.ItemEmpresaID)" style="cursor: pointer;">
                            <td>{{group.Nombre}}</td>
                            <td>{{group.Disponibilidad | date:'dd/MM/yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>             
        </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>