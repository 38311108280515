<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row mapa-sitio">
            <div class="col-12">
                <h2>TIPOS DE UNIDAD</h2> 
            </div>
        </div>
        <div class="row filtros">
            <div class="col-sm-12 col-md-6 col-lg-3">
                <label style="color: white;">STATUS</label>
                <select [(ngModel)]="filter.status" class="form-control">
                    <option value="-1">TODOS</option>
                    <option value="210">ACTIVOS</option>
                    <option value="4">RECHAZADOS</option>
                    <option value="5">EXPIRADOS</option>
                    <option value="6">ELIMINADOS</option>
                </select>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <label>TIPO DE SERVICIO</label>
                <select [(ngModel)]="filter.extranjera" class="form-control">
                    <option value="-1">TODAS</option>
                    <option value="0">NACIONAL</option>
                    <option value="1">EXTRANJERA</option>
                    <option value="2">AEROLINEA</option>
                </select>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <button class='btn btn-danger btn-fluid' type='button' style='margin-top: 31px;' (click)="getRegistros()">
                    DESCARGAR
                </button>
            </div>
        </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>