<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row mapa-sitio">
            <div class="col-12">
                <h2>Administración de Noticias</h2> 
            </div>
        </div>
        <div class="row principal">
            <div class="col-sm-7 col-md-8 col-lg-9">
                <input type="text" [(ngModel)]="searchText" class="buscador form-control"  placeholder="Buscador">
            </div>
            <div class="col-sm-5 col-md-4 col-lg-3">
                <button class='btn-layout btn-fluid float-right btnTableUp' type='button' style='margin-left:10px;' routerLink="/sa-rm/admin/update-noticias/0">
                    CREAR NUEVA
                </button>
            </div>
        </div>
        <div class="row tabla-catalogo">  
            <div class="col-12">
                <table class="table table-responsive"> 
                    <thead>
                        <tr>
                            <th width="10%">ÁREA</th>
                            <th width="30%">TÍTULO</th>
                            <th width="30%">DESCRIPCIÓN</th>
                            <th width="10%">MARCADO</th>
                            <th width="20%">FECHA DE VENCIMIENTO</th>
                            <th width="20%">FECHA DE CREACIÓN</th>
                            <th width="10%"></th>
                            <th width="10%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of catalog | noticias: searchText; ">
                            <td>{{group.Area}}</td>
                            <td>{{group.Titulo}}</td>
                            <td>{{group.Descripcion}}</td>
                            <td>{{group.MarcarValue}}</td>
                            <td>{{group.Vencimiento | date:'dd/MM/yyyy'}}</td>
                            <td>{{group.INSERT_DATE | date:'dd/MM/yyyy'}}</td>
                            <td>
                                <img class="btn-accion" src="../../../assets/IMG/edit-background-gray.png" (click)="update(group.NoticiaID)" width="40" height="30" title="Editar">
                            </td>
                            <td>
                              <button class="btn btn-Eliminar btn-ch btnTableUp" (click)="delete(group.NoticiaID)">Eliminar</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>             
        </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>