<div id="site_wrapper">
    <div class="contenedor contenedor-login">
        <div class="card-login">
          <form #loginForm="ngForm" autocomplete="off" appIdentityRevealed>
             <img src="https://firebasestorage.googleapis.com/v0/b/glide-prod.appspot.com/o/icon-images%2Fanonymous-ca30ff47-9d5f-40ef-b7cd-85de1b2bde95.jpg?alt=media&amp;token=5f82f486-b3d8-483d-8e08-635fd3f943a3" alt="" class="img-logo">
             <div class="login-contenido">
                <label for="" class="lbl-bienvenido pb-3">BIENVENIDO</label>
                <label for="" class="lbl-txt mb-0">Expediente Universal del Transportista</label>
                <label for="" class="lbl-txt pb-3" style="font-size: 14px;">(Powered by SA-RM)</label>
                <div class="form-group" [hidden]="btnContinuar">  
                    <input autocomplete="off"  type="password" class="form-control" #User="ngModel" name="User" [(ngModel)]="password" placeholder="Password"  required/>
                </div>
                <div class="div-btns">
                    <button class="btn btn-md btn-login form-control" [hidden]="btnContinuar" (click)="continuar(true)">VER INFORMACIÓN</button>
                </div>
             </div>
          </form>
       </div>
    </div>
 </div>

 <div class="modal fade" id="edicionModal" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': btnContinuar ? 'block' : 'none', 'opacity': 1}"> 
     <div class="modal-dialog modal-ch modal-dialog-centered" role="dialog" >
       <div class="modal-content">
         <div class="modal-header mdl-hdr text-center">
           <h5 class="modal-title w-100">{{nombre}}</h5>
           <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="continuar(false)">
             <span aria-hidden="true" class="mdl-close" >&times;</span>
           </button>
         </div>
         <div class="modal-body">
             <div class="row">
                <div class="col-12">
                   <h5>{{NombreEmpresa}}</h5>
                </div>
             </div>
             <div class="row" *ngFor="let group of info">
                 <div class="col-12">
                    <h6>{{group.Nombre}}</h6>
                 </div>
                 <div class="col-12" *ngIf="group.Vencido">
                    <label style="color: red;">Vencido</label>
                 </div>
                 <div class="col-12" *ngIf="group.NoAplica">
                    <label style="color: red;">No Aplica</label>
                 </div>
                 <div class="col-12 mb-2">
                    <div *ngIf="group.TipoFK == 1 || group.TipoFK > 4 && !group.NoAplica;">
                        <input disabled type="text" class="form-control" [(ngModel)]="group.Respuesta">
                    </div>
                    <div *ngIf="group.TipoFK == 2 && !group.NoAplica;">
                        <input disabled type="date" class="form-control" [(ngModel)]="group.Respuesta">
                    </div>
                    <div *ngIf="group.TipoFK == 3 && !group.NoAplica;">
                        <button [hidden]="!group.Respuesta || group.Respuesta == '' || !group.Respuesta.includes('back-eutdhl')" class="btn btn-secondary mb-3" (click)="descargar(group.ReactivoItemEmpresaID)">DESCARGAR DOCUMENTO</button>
                        <button [hidden]="!group.Respuesta || group.Respuesta == '' || group.Respuesta.includes('back-eutdhl')" class="btn btn-secondary mb-3" (click)="verDoc(group.Respuesta)">DESCARGAR DOCUMENTO</button><br>
                    </div>
                    <div *ngIf="group.TipoFK == 4 && !group.NoAplica;">
                        <label>Estado</label>
                        <input disabled type="text" class="form-control" [(ngModel)]="group.Direccion.Estado">
                        <label>Ciudad/Municipio</label>                        
                        <input disabled type="text" class="form-control" [(ngModel)]="group.Direccion.Municipio">
                        <label>Código Postal</label>
                        <input disabled type="number" class="form-control" [(ngModel)]="group.Direccion.CP">
                        <label>Colonia</label>
                        <input disabled type="text" class="form-control" [(ngModel)]="group.Direccion.Colonia">
                        <label>Calle</label>
                        <input disabled type="text" class="form-control" [(ngModel)]="group.Direccion.Calle">
                        <label>Num.</label>
                        <input disabled type="text" class="form-control" [(ngModel)]="group.Direccion.Num">
                    </div>
                 </div>
             </div>
             <div class="div-btns row pt-3">
               <div class="col-12">
                 <button class="btn btn-fluid btn-Eliminar btnTableUp" (click)="continuar(false)" data-dismiss="modal" type="button">CERRAR</button>            
               </div>
             </div>          
         </div>       
       </div>
     </div>
 </div>