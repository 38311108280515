import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { ItemService } from 'src/app/services/items/item.service';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-reporte-admin',
  templateUrl: './reporte-admin.component.html',
  styleUrls: ['./reporte-admin.component.css']
})
export class ReporteAdminComponent implements OnInit {
  loading = new LoadingEfectComponent();
  searchText;
  public datos:any[];
  public catalog:any = {};
  public filter:any = {
    empresaID: 0,
    tm: "Todos",
    status: "Todos",
    itemID: 0
  };
  public alerta:any = {
    empresaID: 0,
    frecuencia: 0,
    status: "Todos",
    itemID: 0
  };
  fileName= 'ExcelSheet.xlsx';  

  identity = JSON.parse(localStorage.getItem('identity_user'));

  constructor(
    private _serviceEmp:ClientesService,
    private _serviceItem:ItemService,
    private _service:DashboardService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this.verifyClaims();
    this.getEmpresas();
    this.getItem();
    this.loading.onDoneRequest();
  }

  public getRegistros(){
    this.loading.onLoadingRequest();
    this._service.getReporteAdmin(this.filter.empresaID, this.filter.tm, this.filter.status, this.filter.itemID )
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        this.datos = response.DR;
        console.log(this.datos);
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }
  
  public getEmpresas(){
    this.loading.onLoadingRequest();
    this._serviceEmp.getCliente(0)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.catalog.empresas = response.content;
          console.log(this.catalog.empresas);
        }
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public getItem(){    
    this.loading.onLoadingRequest();
    this._serviceItem.getItem(0)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.catalog.items = response.content;
          console.log(this.catalog.items);
        }
      }else if(response.response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  verifyClaims(){
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Configuraciones');
    if(claim == undefined && claim == null){
      this._router.navigate(['/sa-rm/admin']);
    }
  }

  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('tableResumen'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
  }

  //Funcionalidad alertas
  getEmpresaAlerta(){
    var sltEmp = this.catalog.empresas.find(x=>x.EmpresaID == this.filter.empresaID);
    this.alerta.empresaID = this.filter.empresaID;
    this.alerta.frecuencia = sltEmp.Correo_Frecuencia;
    this.alerta.status = sltEmp.Correo_StatusID && sltEmp.Correo_StatusID != "" ? sltEmp.Correo_StatusID : "Todos";
    this.alerta.itemID = !sltEmp.Correo_ItemsID || sltEmp.Correo_ItemsID == '' ? [] : sltEmp.Correo_ItemsID.split(",").map( Number );
  }

  updateEmpresaAlerta(){
    this.alerta.itemIDs = this.alerta.itemID ? this.alerta.itemID.toString() : "";
    this.loading.onLoadingRequest();
    this._service.updateEmpresaAlerta( this.alerta )
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        Swal.fire("Éxito","La alerta de la empresa fue actualizada correctamente","success");
        this.getEmpresas();
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }
}
