<footer class="footer-solav">
    <nav class="navbar navbar-expand">
        <div class="col-12" id="navbarColor01">
          <form class="form-inline my-2 my-lg-0">
            <ul class="navbar-nav col-12" style="align-items: center;">
                <li *ngIf="Empresas" class="nav-item col-4" (click)="active('client')">
                    <a>
                        <span class="icon icon-custom" id="client"></span>
                    </a>
                </li>
                <li *ngIf="Dashboard" class="nav-item col-4" (click)="active('dashboard')">
                    <a>
                        <span class="icon icon-speed" id="dashboard"></span>
                    </a>
                </li>
                <li *ngIf="Configuraciones" class="nav-item col-4" (click)="active('config')">
                    <a>
                        <span class="icon icon-evo" id="config"></span>
                    </a>
                </li>
            </ul>
          </form>
        </div>
      </nav>
</footer>