<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row mapa-sitio">
            <div class="col-sm-12 col-md-9">
                <div>
                    <a class="lbl-noActive" href="/#/sa-rm/admin/dashboard/{{flagEXT}}">Dashboard General > </a>
                    <a class="lbl-noActive" href="/#/sa-rm/admin/dashboard-empresa/{{rolID}},{{flagEXT}}">Detalle {{role}} > </a>
                    <label class="lbl-active">&nbsp;Resumen</label>
                </div>
                <h2>Resumen {{role}}</h2> 
                <button class='btn-layout pl-5 pr-5 btnTableUp' type='button' (click)="exportexcel()">
                    EXCEL
                </button>
            </div>
            <div class="col-sm-12">
                <a href="/#/sa-rm/admin/dashboard-empresa/{{rolID}},{{flagEXT}}" class="float-left link-regresar" ><img src="../../../assets/IMG/arrow_back.png" /></a>
            </div>
        </div>
        <div class="row tabla-catalogo">  
            <div class="col-12">
                <table class="table table-responsive" style="height:400px;" id="tableResumen">
                    <thead>
                        <tr>
                            <th width="50%">ITEM</th>
                            <th width="10%">TOTAL REACTIVOS</th>
                            <th width="10%">TOTAL CARGA</th>
                            <th width="10%">POR VALIDAR</th>
                            <th width="10%">VALIDADO</th>
                            <th width="10%">RECHAZADO</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of obj.DR">
                            <td>{{group.NombreItem}}</td>
                            <td>{{group.Total}}</td>
                            <td>{{group.Carga}}</td>
                            <td>{{group.PorValidar}}</td>
                            <td>{{group.Validado}}</td>
                            <td>{{group.Rechazado}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>