import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';

@Component({
  selector: 'app-alertas',
  templateUrl: './alertas.component.html',
  styleUrls: ['./alertas.component.css']
})
export class AlertasComponent implements OnInit {
  loading = new LoadingEfectComponent();
  identity = JSON.parse(localStorage.getItem('identity_user'));
  public ItemId = +this._route.snapshot.paramMap.get('id');
  public cuestionario: any = [];
  searchText;
  public todayDate:any = new Date();
  public titleAlerta:string = "";

  constructor(
    private _service:ClientesService,
    private _route: ActivatedRoute,
    public _router: Router,
    private _serviceUsuario:UsuariosService,
  ) { }

  ngOnInit(){
    this._route.paramMap.subscribe((params: ParamMap) => {
      this.ItemId = +params.get('id');
      if(this.ItemId == 0){
        this.titleAlerta = "Pendientes";
      }
      else if(this.ItemId == 1){
        this.titleAlerta = "Rechazados";
      }
      else if(this.ItemId == 2){
        this.titleAlerta = "Por Caducar";
      }
      else if(this.ItemId == 3){
        this.titleAlerta = "Caducados";
      }
      this.getItems();
    });
  }

  public getItems(){    
    this._service.getItemAlerta(this.identity.empresa.EmpresaID, this.ItemId).then(response=>{     
      this.cuestionario = response.content;
    })
    .catch(error => {      
      console.log(error.toString());
    });
  }

  verCuestionario(idcc:number) {
    this._router.navigate(['/sa-rm/alertareactcl/' + idcc + ',' + this.ItemId]);
  }
}
