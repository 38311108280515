import { Component, OnInit, ɵConsole } from '@angular/core';
import {Router} from '@angular/router';
import { AuthService } from 'src/app/services/login/auth/auth.service';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.css']
})
export class ConfigComponent implements OnInit {
  identity = JSON.parse(localStorage.getItem('identity_user'));

  constructor(
    private _serviceUsuario:UsuariosService,
    public router: Router,
    private _auth: AuthService,
  ) { }

  ngOnInit(): void {
    this.verifyClaims();
    this.registrarLog();
  }

  public registrarLog(){
    let clase = 'Configuraciones';
    let obj = {
      //Controller: obj.Controller,
      Class: clase,
      //Table: 'ItemEmpresa',
      //ID: this.id,
      Message: 'ACCESO'
    }
    this._serviceUsuario.registrarLog(obj);
  }

  verifyClaims(){
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Configuraciones');
    if(claim == undefined && claim == null){
      this.router.navigate(['/sa-rm/admin']);
    }
  }

  goToUsuarios(){
    this.router.navigate(['/sa-rm/usuarios']);   
  }

  goToRoles(){
    this.router.navigate(['/sa-rm/rol']);   
  }

  goToGrupos() {
    this.router.navigate(['/sa-rm/grupo']);
  }

  goToArea() {
    this.router.navigate(['/sa-rm/area']);
  }

  goToGeneral(){
    this.router.navigate(['/sa-rm/general']);
  }

  goToItems() {
    this.router.navigate(['/sa-rm/items']);
  }

  goToTP(){
    this.router.navigate(['/sa-rm/tipo-contrato']);
  }
}
