<div id="site_wrapper">
    <div class="contenedor-principal">
      <div class="row mapa-sitio">
        <div class="col-sm-12 col-md-9">
            <div>
                <a class="lbl-noActive" href="/#/sa-rm/admin/config">Configuraciones > </a><a href="/#/sa-rm/admin/config" class="lbl-noActive">&nbsp;ITEMS > </a><label class="lbl-active">&nbsp;Lista</label>
            </div>
            <h2>Items</h2> 
        </div>
      </div>
      <div class="row principal">
          <div class="col-sm-7 col-md-8 col-lg-9 col-xl-10">
            <a href="/#/sa-rm/admin/config" class="float-left link-regresar" ><img src="../../../assets/IMG/arrow_back.png" /></a>
              <input type="text" [(ngModel)]="searchText" class="buscador form-control" placeholder="Buscador">
          </div>
          <div class="col-sm-5 col-md-4 col-lg-3 col-xl-2">
              <button class='btn-layout btn-fluid float-right btnTableUp' (click)='update(0)' type='button' style='margin-left:10px;'>
                  CREAR NUEVO
              </button>
          </div>
      </div>
      <div class="row tabla-catalogo">  
          <div class="col-12">
              <table class="table table-responsive"> 
                  <thead>
                  <tr>
                      <th width="40%">ID ITEM</th>
                      <th width="40%">NOMBRE ITEM</th>
                      <th width="40%">TIPO DE SERVICIO</th>
                      <th width="10%">GRUPO</th>
                      <th width="10%">DEFAULT</th>
                      <th width="10%">NUM. REACTIVOS</th>
                      <th width="10%">PUNTOS TOTALES</th>
                      <th width="20%">FECHA DE CREACIÓN</th>
                      <th class="no-sort"></th>
                      <th class="no-sort"></th>
                      <th class="no-sort"></th>
                      <th class="no-sort"></th>
                      <th class="no-sort"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let group of catalog | item: searchText; ">
                      <td>{{group.ItemID}}</td>
                      <td>{{group.Nombre}}</td>
                      <td>{{group.NacionalExtranjera}}</td>
                      <td>{{group.NombreGrupo}}</td>
                      <td>{{group.Default}}</td>
                      <td>{{group.totalPreguntas}}</td>
                      <td>{{group.Calificacion}}</td>
                      <td>{{group.FechaCreacion | date:'dd/MM/yyyy'}}</td>
                      <td>
                        <img class="btn-accion" src="../../../assets/IMG/preview-background-gray.png" (click)="preview(group.ItemID)" width="40" height="30" title="Preview">
                      </td>
                      <td>
                        <img class="btn-accion" src="../../../assets/IMG/edit-background-gray.png" (click)="update(group.ItemID)" width="40" height="30" title="Editar">
                      </td>
                      <td>
                        <img class="btn-accion" src="../../../assets/IMG/newVersion-background-gray.png" (click)="newVersion(group.ItemID)" width="40" height="30" title="Nueva Versión">
                      </td>
                      <td>
                        <button class="btn btn-eliminar" title="Asignación masiva" (click)="masiveAsign(group.ItemID)">
                          <fa-icon [icon]="faCheckCircle"></fa-icon>
                        </button>
                      </td>
                      <td>
                        <button class="btn btn-Eliminar btn-ch btnTableUp" (click)="delete(group.ItemID)">Eliminar</button>
                      </td>
                  </tr>
                  </tbody>
              </table>
          </div>             
      </div>
    </div>
</div>
  
<div class="modal fade" id="previewModal" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': mdlPreview ? 'block' : 'none', 'opacity': 1}"> 
  <div class="modal-dialog modal-xl modal-dialog-centered" role="dialog" >
    <div class="modal-content">
      <div class="modal-header mdl-hdr text-center">
        <h5 class="modal-title w-100">PREVIEW</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="preview(0)">
          <span aria-hidden="true" class="mdl-close" >&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <app-preview [objPreview]="objPreview"></app-preview>
      </div>       
    </div>
  </div>
</div>

<div class="modal fade" id="previewModal" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': mdlMasiveAsign ? 'block' : 'none', 'opacity': 1}"> 
  <div class="modal-dialog modal-xl modal-dialog-centered" role="dialog" >
    <div class="modal-content">
      <div class="modal-header mdl-hdr text-center">
        <h5 class="modal-title w-100">Asignación masiva</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="masiveAsign(0)">
          <span aria-hidden="true" class="mdl-close" >&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row mapa-sitio">
            <div class="col-12">
                <h3>Empresas</h3> 
            </div>
            <div class="col-md-12 col-lg-6 col-xl-3 mt-4" style="align-self: center;">
              <input class="needsclick" type="checkbox" id="flagSelectAll" [(ngModel)]="flagSelectAll" (change)="selectAll()"/>
              <label for="flagSelectAll">&nbsp; Seleccionar todo</label>
            </div>
            <div class="col-md-12 col-lg-6 col-xl-3 mt-4" style="align-self: center;">
                <label class="switch" id="flagExtranjera">
                    <input [(ngModel)]="flagExtranjera" (change)="filters()" type="checkbox">
                    <span class="slider round"></span>
                </label>
                &nbsp;
                <label class="lblSwitch" for="flagExtranjera">LTs Extranjeras</label>
            </div>
            <div class="col-md-12 col-lg-6 col-xl-3">
                <label>STATUS</label>
                <select [(ngModel)]="flagInactiva" (change)="filters()" class="form-control">
                    <option value="-1">TODOS</option>
                    <option value="0">ACTIVOS</option>
                    <option value="1">INACTIVOS</option>
                </select>
            </div>
        </div>
        <div class="row tabla-catalogo">  
            <div class="col-12">
                <table class="table table-responsive table-sm table-hover" style="max-height: 260px; margin-bottom: 0px;"> 
                    <thead>
                        <tr>
                            <th width="10%">NUM PROVEEDOR</th>
                            <th width="30%">NOMBRE EMPRESA</th>
                            <th width="30%">NOMBRE COMERCIAL</th>
                            <th width="10%">TIPO PERSONA</th>
                            <th width="10%">PROVEEDOR</th>
                            <th width="10%">ACTIVA/INACTIVA</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of catalogEmp;">
                            <td>
                              <input type="checkbox" id="ckb-{{group.EmpresaID}}" [(ngModel)]="group.Checked" (change)="selectItem()">
                              <label class="lblSwitch" for="ckb-{{group.EmpresaID}}">{{group.NumProveedor}}</label>
                            </td>
                            <td>{{group.Nombre}}</td>
                            <td>{{group.NombreComercial}}</td>
                            <td>{{group.NombreTipoPersona}}</td>
                            <td>{{group.ProveedorVal}}</td>
                            <td>{{group.EliminadoValue}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>             
        </div>
      </div>       
      <div class="modal-footer">
        <div class="row">
          <div class="col-6">
            <button class="btn btn-med btn-Eliminar btnTableUp" (click)="masiveAsign(0)" data-dismiss="modal" type="button">CANCELAR</button>            
          </div>
          <div class="col-6">    
            <button class="btn btn-med btn-layout btnTableUp" (click)="guardarAsignacion()">GUARDAR</button>   
          </div> 
        </div>
      </div>    
    </div>
  </div>
</div>

<app-loading-efect></app-loading-efect>