import { Component, OnInit, ɵConsole  } from '@angular/core';
import { AsistenciaService } from 'src/app/services/asistencia/asistencia.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { AuthService } from 'src/app/services/login/auth/auth.service';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { 
  faSignOutAlt as fasSignOut,
  faTicketAlt
} from '@fortawesome/free-solid-svg-icons';
import { Idle, NotIdle  } from 'idlejs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  loading = new LoadingEfectComponent();
  identity = JSON.parse(localStorage.getItem('identity_user'));
  private sesionCookies = this.identity.Sesion;
  private sesionBD: string = '';
  private objSesion: any = {};
  fasSignOut = fasSignOut;
  faTicketAlt = faTicketAlt;
  public empresaID:number = 0;
  public catalog1:any[];
  public asistenciasNuevo:number = 0;
  public catalog2:any[];
  public asistenciasCurso:number = 0;
  public catalog3:any[];
  public asistenciasMin:number = 0;
  //Variables para Flujo Tickets
  public mdlTicket: boolean = false;
  public asistenciaREQ: any = {};
  public asistencia:any = {};
  //Variables para preview
  public idPreview:any = {};
  public mdlPreview:boolean = false;

  constructor(
    private _service:AsistenciaService,
    private _auth: AuthService,
    private _router: Router,
    private _serviceUsuario: UsuariosService
  ) { 
    if(this.identity.empresa){
      this.empresaID = this.identity.empresa.EmpresaID
    }
  }

    ngOnInit() {
      this.getRegistrosMin();

      const LIMIT= 60000 * 360; //6 horas
      const notIdle = new NotIdle()
      .whenInteractive()
      .within(5,1000)
      .do(() => {
        let la = +localStorage.getItem('lastActivity');
        if (new Date().getTime() - la > LIMIT) {
          this.identity = JSON.parse(localStorage.getItem('identity_user'));
          if(this.identity != null){
            this.logout();
          }else{
            this._router.navigate(['/login-consultor']);
          }  
        }else{
          localStorage.setItem('lastActivity',new Date().getTime().toString())
        }
      })
      .start();
      
      setInterval(() => {
        let la = +localStorage.getItem('lastActivity');
        if (new Date().getTime() - la > LIMIT) {
          this.identity = JSON.parse(localStorage.getItem('identity_user'));
          if(this.identity != null){
            this.logout();
          }else{
            this._router.navigate(['/login-consultor']);
          }  
        }
      }, 60000); //comprobar cada minuto
    }
    
    ngAfterViewInit(){  
      
    }
  
    ngAfterContentInit(){   
      
    }
    
    ngAfterContentChecked(){    
    }

    async logout(){
      this._auth.logOut()
      .then(response=>{
        this._router.navigate(['/login-consultor']);
      })
      .catch(error=>{
        this._router.navigate(['/login-consultor']);
      });
    }

    public getRegistrosMin(){
      this._service.getAsistenciaMinList()
      .then(response=>{
        if(response.status == 200){
          this.catalog3 = response.content;
          this.asistenciasMin = response.content.length;
          (async () => { 
            await this.delay(10000);
            // Do something after
            this.getRegistrosMin();
          })();
        }else if(response.status == 500){
          Swal.fire("Error",response.message,"error");
        }
      })
      .catch(error=>{
        console.log(error.toString());
      });
    }

    delay(ms: number) {
      return new Promise( resolve => setTimeout(resolve, ms) );
    }

    public modalTicket(flag:boolean, id:number){
      if(flag){
        this.asistenciaREQ.AsistenciaID = id;
        this.asistenciaREQ.ReactivoItemEmpresaID = 0;
        this.asistenciaREQ.UsuarioID = this.identity.UsuarioID;
        this.asistenciaREQ.Comentario = "";
  
        this.loading.onLoadingRequest();
        this._service.getAsistencia(id)
        .then(response=>{
          this.loading.onDoneRequest();
          if(response.status == 200){
            if(response.content != null){
              this.asistencia = response.content;
              console.log(this.asistencia);
              this.mdlTicket = flag;
            }else{
              Swal.fire("Sin registros","Aún no se han registrado tickets","info");      
            }
          }else if(response.status == 500){
            Swal.fire("Error",response.message,"error");
          }
        })
        .catch(error=>{
          this.loading.onDoneRequest();
          console.log(error.toString());
        });

        const bodyElement = document.body;
        if (bodyElement) {
          bodyElement.classList.add("bodyClip");
        }
      }else{
        this.mdlTicket = flag;
        const bodyElement = document.body;
        if (bodyElement) {
          bodyElement.classList.remove("bodyClip");
        }

        //Quitar miniatura en caso de que exista
        this._service.updateAsistenciaMin(false, this.asistenciaREQ.AsistenciaID)
        .then(response=>{
          this.loading.onDoneRequest();
          if(response.status == 200){
            console.log(response);
            this.getRegistrosMin();
          }else if(response.status == 500){
            Swal.fire("Error",response.message,"error");
          }
        })
        .catch(error=>{
          console.log(error.toString());
        });
      }
    }

    public minTicket(flag:boolean){
      this._service.updateAsistenciaMin(flag, this.asistenciaREQ.AsistenciaID)
      .then(response=>{
        if(response.status == 200){
          console.log(response);
          this.mdlTicket = false;
          const bodyElement = document.body;
          if (bodyElement) {
            bodyElement.classList.remove("bodyClip");
          }
          this.getRegistrosMin();
        }else if(response.status == 500){
          Swal.fire("Error",response.message,"error");
        }
      })
      .catch(error=>{
        console.log(error.toString());
      });
    }

    minTicketClickOut(event) {
       if(event.target.className === "modal fade") {
        this._service.updateAsistenciaMin(true, this.asistenciaREQ.AsistenciaID)
        .then(response=>{
          if(response.status == 200){
            console.log(response);
            this.mdlTicket = false;
            const bodyElement = document.body;
            if (bodyElement) {
              bodyElement.classList.remove("bodyClip");
            }
            this.getRegistrosMin();
          }else if(response.status == 500){
            Swal.fire("Error",response.message,"error");
          }
        })
        .catch(error=>{
          console.log(error.toString());
        });
       }
    }

    public crearTicket(status:number){
      this.asistenciaREQ.Status = status;
      this.loading.onLoadingRequest();
      this._service.updateAsistencia(this.asistenciaREQ)
      .then(response=>{
        this.loading.onDoneRequest();
        if(response.status != 200){
          Swal.fire('Error', response.message, 'error');
        }else{
          Swal.fire('Éxito', response.message, 'success')
              .then(rs=>{
                this.mdlTicket = false;
                this.getRegistrosMin();
              });
        }    
      }).catch(error=>{   
        this.loading.onDoneRequest();   
        Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
      });
    }
  
    public openPreviewItem(id:number){
      this._router.navigate(['/sa-rm/itemreview/' + id]);
    }

}
