<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row mapa-sitio">
          <div class="col-sm-12 col-md-9">
              <div>
                  <a class="lbl-noActive" href="/#/sa-rm/admin/reclamo">Reclamos ></a>
                  <label class="lbl-active">&nbsp;Nuevo Reclamo</label>
              </div>
          </div>
          <div class="col-sm-12">
              <a href="/#/sa-rm/admin/reclamo" class="float-left link-regresar" style="cursor: pointer;"><img src="../../../assets/IMG/arrow_back.png" /></a>
          </div>
          <div class="col-sm-12">
            <h2>Nuevo Reclamo</h2>
          </div>
          <div class="col-sm-12 col-md-6">
            <h5 style="color: white;">Fecha de Registro: {{fechaHoy | date:'dd/MM/yyyy'}}</h5>
          </div>
        </div>
        <form #createForm="ngForm" (ngSubmit)="update()" autocomplete="off" appIdentityRevealed>
            <div class="row">
                <div class="col-lg-12">
                    <div class="row graphs-client ml-0 mr-0 pt-2 pb-2">
                        <div class="col-sm-12 col-md-6">
                            <div class="form-group">
                                <label for="ControlVehicular">Control Vehicular</label>
                                <input type="text" class="form-control" id="ControlVehicular" #ControlVehicular="ngModel" name="ControlVehicular" [(ngModel)]="Obj.ControlVehicular" required>
                                <div *ngIf="ControlVehicular.invalid" class="lbl-error">
                                    <span *ngIf="ControlVehicular.errors.required">
                                        El campo es requerido
                                    </span>            
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <div class="form-group">
                                <label for="FechaSiniestro">Fecha de Siniestro</label>
                                <input type="date" class="form-control" id="FechaSiniestro" #FechaSiniestro="ngModel" name="FechaSiniestro" [(ngModel)]="Obj.FechaSiniestro" required>
                                <div *ngIf="FechaSiniestro.invalid" class="lbl-error">
                                    <span *ngIf="FechaSiniestro.errors.required">
                                        El campo es requerido
                                    </span>            
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Linea Transportista</label>
                                <ng-select appendTo=".contenedor-principal" #EmpresaID="ngModel" name="EmpresaID" [clearable]="false" [searchable]="true" [(ngModel)]="Obj.EmpresaID" required>
                                    <ng-option [value]="group.EmpresaID" *ngFor="let group of catalog;">
                                        {{group.Nombre}}
                                    </ng-option>
                                </ng-select>
                                <div *ngIf="EmpresaID.invalid" class="lbl-error">
                                    <span *ngIf="EmpresaID.errors.required">
                                        La linea transportista es requerida
                                    </span>            
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 pt-3">
                    <button class='btn-layout btn-med float-right btnTableUp' [disabled]="createForm.invalid">
                        GUARDAR
                    </button>
                </div>
            </div>
        </form>  
        <app-loading-efect></app-loading-efect>     
      </div>
</div>