import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { ItemService } from 'src/app/services/items/item.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css']
})
export class PreviewComponent implements OnChanges {
  @Input() objPreview: any = {};
  public preguntas:any = [];
  identity = JSON.parse(localStorage.getItem('identity_user'));

  constructor(
    private _service:ItemService,
    private router:Router
  ) { }

  ngOnChanges() {
    if(this.objPreview.ItemID){
      this.getReactivosbyItem();
    }
  }
  
  public getReactivosbyItem(){    
    this._service.getReactivosbyItem(+this.objPreview.ItemID)
    .then(response=>{
      if(response.response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.preguntas = response.content;
          console.log(this.preguntas);
        }
        else{
          Swal.fire("Atención","No hay items que mostrar.","info");
        }
      }else if(response.response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      console.log(error.toString());
    });
  }

  public anadirReact(id:number){
    let selectedReact = this.preguntas.find(x=>x.ReactivoID == id);
    var maxID = Math.max.apply(Math,this.preguntas.map(function(o){return o.ReactivoID;}));
    let newReact:any = JSON.parse(JSON.stringify(selectedReact));
    newReact.ReactivoID = maxID + 1;
    this.preguntas.push(newReact)
  }
}
