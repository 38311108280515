import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noticias'
})
export class NoticiasPipe implements PipeTransform {

  transform(items: any[], searchText: string): unknown {
    if (!items) return [];
    if (!searchText) return items;
  
    searchText = searchText.toLowerCase();
  
    return items.filter( it => {
      return it.Area.toLowerCase().includes(searchText)
      || it.Titulo.toLowerCase().includes(searchText)
      || it.Descripcion.toLowerCase().includes(searchText)
      || it.INSERT_DATE.toLowerCase().includes(searchText);
    });
  }

}
