import { Component, OnInit } from '@angular/core';
import { ProspectoService } from 'src/app/services/prospecto/prospecto.service';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {Md5} from 'ts-md5/dist/md5';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';

@Component({
  selector: 'app-nuevo-prospecto',
  templateUrl: './nuevo-prospecto.component.html',
  styleUrls: ['./nuevo-prospecto.component.css']
})
export class NuevoProspectoComponent implements OnInit {
  loading = new LoadingEfectComponent();
  public Emp:any = {};
  public catalogTL = [];
  identity = JSON.parse(localStorage.getItem('identity_user'));

  constructor(
    private _service:ProspectoService,
    private _serviceUsuario:UsuariosService,
    private _router:Router
  ) { }

  ngOnInit(){
    this.verifyClaims();
    this.getCatalog();
  }

  verifyClaims(){
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Alta Prospectos');
    if(claim == undefined && claim == null){
      this._router.navigate(['/sa-rm/admin']);
    }
  }

  public getCatalog(){    
    this.loading.onLoadingRequest();
    this._serviceUsuario.getCatalog(17)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.catalogTL = response.content;
        }
      }else if(response.response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public update(){
    this.Emp.ProspectoID = 0;
    this.loading.onLoadingRequest();
    this.Emp.TipoLinea = this.Emp.TipoLineaArray.toString();
    this.Emp.RazonSocial = this.Emp.RazonSocial.trim();
    this.Emp.NombreComercial = this.Emp.NombreComercial.trim();
    this._service.updateProspecto(this.Emp)
        .then(response=>{
          this.loading.onDoneRequest();   
          if(response.status == 200){
            Swal.fire("Registro exitoso","Se ha dado de alta el prospecto de manera exitosa.","success")
            .then(rs=>{
              this._router.navigate(['/sa-rm/admin/prospect-ft/' + response.EmpresaID]);
            });
          }else{
            Swal.fire('Error', response.message, 'error');
          }        
        }).catch(error=>{   
          this.loading.onDoneRequest();   
          Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
        })
  }
}
