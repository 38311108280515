import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { Router } from '@angular/router';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import { ReclamoService } from 'src/app/services/reclamo/reclamo.service';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-reclamo',
  templateUrl: './reclamo.component.html',
  styleUrls: ['./reclamo.component.css']
})
export class ReclamoComponent implements OnInit {
  faPlus = faPlus;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions:any = {
    dom: 'Bflrtip',
    buttons: [{
        extend:'excel',
        exportOptions: {
          columns: [0,1,2,3,4,5,6,7,8,9,10,11,12]
        }
      }
    ],
    language: {
        "decimal": "",
        "emptyTable": "No hay información",
        "info": "Mostrando _START_ a _END_ de _TOTAL_ Resultados",
        "infoEmpty": "Mostrando 0 to 0 of 0 Resultados",
        "infoFiltered": "(Filtrado de _MAX_ total resultados)",
        "infoPostFix": "",
        "thousands": ",",
        "lengthMenu": "Mostrar _MENU_ Resultados",
        "loadingRecords": "Cargando...",
        "processing": "Procesando...",
        "search": "Buscador:",
        "zeroRecords": "Sin resultados encontrados",
        "paginate": {
            "first": "Primero",
            "last": "Ultimo",
            "next": "Siguiente",
            "previous": "Anterior"
        }
    }
  };
  dtTrigger = new Subject();

  loading = new LoadingEfectComponent();
  public catalog:any[];
  public reclamos:any[];
  public flagFilters:any={
    reclamoID:0,
    CV:"",
    fechaSiniestro:null,
    fechaRegistro:null,
    statusCarga:-1,
    statusCliente:-1,
    statusSeguridad:-1,
    CUENTA:null,
    SECTOR:"",
    LT:null,};

  identity = JSON.parse(localStorage.getItem('identity_user'));

  constructor(
    private _service:ClientesService,
    private _serviceUsuario: UsuariosService,
    private _serviceReclamo:ReclamoService,
    public router: Router
  ) { }

  ngOnInit(){
    this.verifyClaims();
    this.getEmpresas();
    this.registrarLog();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  verifyClaims(){
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Reclamos');
    if(claim == undefined && claim == null){
      this.router.navigate(['/sa-rm/admin']);
    }
  }

  validateClaim(claim: string) {
    return this.identity.permisoLists.find(x => x.nombrePermiso === claim);
  }

  public registrarLog(){
    let clase = 'Reclamos';
    let obj = {
      Class: clase,
      Message: 'ACCESO'
    }
    this._serviceUsuario.registrarLog(obj);
  }

  public getEmpresas(){
    this.loading.onLoadingRequest();
    this._service.getCliente(0)
    .then(response=>{
      if(response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.loading.onDoneRequest();
          this.catalog = response.content;
        }
      }else if(response.status == 500){
        this.loading.onDoneRequest();
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public getRegistros(){
    this.dtOptions.destroy;
    this.loading.onLoadingRequest();
    this._serviceReclamo.getReclamo(this.flagFilters)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          this.reclamos = response.content;
          // Destroy the table first
          dtInstance.destroy();
          this.dtTrigger.next();
          this.loading.onDoneRequest();
        });
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }
}
