import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reporteAdmin'
})
export class ReporteAdminPipe implements PipeTransform {

  transform(reactivos: any[], searchText: string): unknown {
    if (!reactivos) return [];
    if (!searchText) return reactivos;
  
    searchText = searchText.toLowerCase();
  
    return reactivos.filter( it => {
      return it.Empresa.toLowerCase().includes(searchText)
      || it.Item.toLowerCase().includes(searchText)
      || it.Reactivo.toLowerCase().includes(searchText)
      || it.Mandatorio.toLowerCase().includes(searchText)
      || it.Status.toLowerCase().includes(searchText);
    });
  }
}
