<div id="site_wrapper">
    <div class="contenedor-principal">
        <h2>Reclamos
            <button *ngIf="validateClaim('Alta Reclamos')" class="btn btn-eliminar" title="Nuevo Reclamo" routerLink="/sa-rm/nuevoreclamo">
                <fa-icon [icon]="faPlus"></fa-icon>
            </button>
        </h2> 
        <div class="row mapa-sitio">
            <div class="col-md-12 col-lg-6 col-xl-3">
                <div class="form-group">
                    <label style="color: white;">CONTROL VEHICULAR</label>
                    <input type="text" class="form-control" [(ngModel)]="flagFilters.CV"/>
                </div>
                <div class="form-group">
                    <label style="color: white;">LINEA TRANSPORTISTA</label>
                    <ng-select appendTo=".contenedor-principal" [clearable]="true" [searchable]="true" [(ngModel)]="flagFilters.LT">
                        <ng-option [value]="group.EmpresaID" *ngFor="let group of catalog;">
                            {{group.Nombre}}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="col-md-12 col-lg-6 col-xl-3">
                <div class="form-group">
                    <label style="color: white;">FECHA DE SINIESTRO</label>
                    <input type="date" class="form-control" [(ngModel)]="flagFilters.fechaSiniestro"/>
                </div>
                <div class="form-group">
                    <label style="color: white;">FECHA DE REGISTRO</label>
                    <input type="date" class="form-control" [(ngModel)]="flagFilters.fechaRegistro"/>
                </div>
            </div>
            <div class="col-md-12 col-lg-6 col-xl-3">
                <div class="form-group">
                    <label style="color: white;">ESTATUS CARGA</label>
                    <select class="form-control" [(ngModel)]="flagFilters.statusCarga">
                        <option value="-1">Todos</option>
                        <option value="0">Pendiente</option>
                        <option value="1">En proceso</option>
                        <option value="100">Completado</option>
                    </select>
                </div>
                <div class="form-group">
                    <label style="color: white;">ESTATUS CLIENTE</label>
                    <select class="form-control" [(ngModel)]="flagFilters.statusCliente">
                        <option value="-1">Todos</option>
                        <option value="0">Pendiente</option>
                        <option value="1">En proceso</option>
                        <option value="2">Completado</option>
                    </select>
                </div>
            </div>
            <div class="col-md-12 col-lg-6 col-xl-3">
                <div class="form-group">
                    <label style="color: white;">ESTATUS SEGURIDAD</label>
                    <select class="form-control" [(ngModel)]="flagFilters.statusSeguridad">
                        <option value="-1">Todos</option>
                        <option value="0">Pendiente</option>
                        <option value="1">En proceso</option>
                        <option value="2">Completado</option>
                    </select>
                </div>
                <button class='btn btn-danger btn-fluid' type='button' style='margin-top: 31px;' (click)="getRegistros()">
                    Filtrar
                </button>
            </div>
        </div>
        <div class="row tabla-catalogo">  
            <div class="col-12">
                <table class="table table-responsive table-sm table-hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"> 
                    <thead>
                        <tr>
                            <th width="10%">Folio</th>
                            <th width="10%">Control Vehicular</th>
                            <th width="30%">Status Carga</th>
                            <th width="30%">Status Cliente</th>
                            <th width="10%">Status Seguridad</th>
                            <th width="10%">Monto del Reclamo</th>
                            <th width="10%">Antigüedad</th>
                            <th width="10%">Origen</th>
                            <th width="10%">Destino</th>
                            <th width="10%">Nombre Empresa</th>
                            <th width="20%">Operador</th>
                            <th width="20%">Fecha de Siniestro</th>
                            <th width="20%">Fecha de Registro</th>
                            <th width="10%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of reclamos;">
                            <td style="white-space: nowrap;">{{group.FolioReclamo}}</td>
                            <td>{{group.ControlVehicular}}</td>
                            <td>{{group.NombreStatusCarga}}</td>
                            <td>{{group.NombreStatusCliente}}</td>
                            <td>{{group.NombreStatusSeguridad}}</td>
                            <td>{{group.MontoFormated}}</td>
                            <td>{{group.Antiguedad}}</td>
                            <td>{{group.Origen}}</td>
                            <td>{{group.Destino}}</td>
                            <td>{{group.NombreEmpresa}}</td>
                            <td>{{group.Operador}}</td>
                            <td>{{group.FechaSiniestro | date:'dd/MM/yyyy'}}</td>
                            <td>{{group.INSERT_DATE | date:'dd/MM/yyyy'}}</td>
                            <td>
                                <button class="btn btn-ch btnTableUp" routerLink="/sa-rm/admin/reclamo-ft/{{group.ReclamoID}}">VER</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>             
        </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>