<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row">
            <div class="col-sm-12 btn-regresar-2">
              <a (click)="regresar()" style="cursor: pointer;" class="float-left link-regresar"><img src="../../../assets/IMG/arrow_back.png" /></a>
            </div>
        </div>
        <div class="row mapa-sitio">
            <div class="col-12">
                <h2>Procesos Contrato {{nombre}}</h2> 
            </div>
        </div>
        <div class="row tabla-catalogo">  
            <div class="col-12">
                <table class="table table-responsive"> 
                    <thead>
                        <tr>
                            <th width="10%">NO. PROCESO</th>
                            <th width="30%">STATUS</th>
                            <th width="30%">FECHA INICIO</th>
                            <th width="30%">FECHA FIN</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of catalog;" (click)="goRoute(group.ContratoProcesoID)"  style="cursor: pointer;">
                            <td>{{group.NoProceso}}</td>
                            <td>{{group.status}}</td>
                            <td>{{group.INSERT_DATE | date:'dd/MM/yyyy'}}</td>
                            <td>{{group.UPDATE_DATE | date:'dd/MM/yyyy'}}</td>
                            <td>
                                <div *ngIf="group.StatusID == 2111">
                                    <img class="btn-accion" src="../../../assets/IMG/circulo_rojo.png" width="20" height="20">
                                </div>
                                <div *ngIf="group.FlagActivo && group.StatusID != 2111">
                                    <img class="btn-accion" src="../../../assets/IMG/circulo_verde.png" width="20" height="20">
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>        
            <div class="col-12" style="margin-top: 20px;" *ngIf="catalog && catalog.length > 0 && catalog[catalog.length - 1].status == 'Terminado' && validateClaim('Contrato Nuevo Proceso')">
                <button class="btn btn-danger" style="margin-top: -10px !important;" (click)="newContract()">Nuevo Proceso de Contrato</button>
            </div>     
        </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>