import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tarifas'
})
export class TarifasPipe implements PipeTransform {

  transform(reactivos: any[], searchText: string): unknown {
    if (!reactivos) return [];
    if (!searchText) return reactivos;
  
    searchText = searchText.toLowerCase();
  
    return reactivos.filter( it => {
      return it.RATE_OFFERING_GID.toLowerCase().includes(searchText)
      || it.RATE_GEO_GID.toLowerCase().includes(searchText)
      || it.SERVPROV_GID.toLowerCase().includes(searchText)
      || it.X_LANE_GID.toLowerCase().includes(searchText)
      || it.SOURCE_REGION_GID.toLowerCase().includes(searchText)
      || it.DEST_REGION_GID.toLowerCase().includes(searchText)
      || it.RATE_GEO_COST_GROUP_GID.toLowerCase().includes(searchText)
      || it.CHARGE_AMOUNT == searchText
      || it.INSERT_USER_OTM.toLowerCase().includes(searchText)
      || it.INSERT_DATE_OTM.toLowerCase().includes(searchText)
      || it.UPDATE_USER_OTM.toLowerCase().includes(searchText)
      || it.UPDATE_DATE_OTM.toLowerCase().includes(searchText);
    });
  }

}
