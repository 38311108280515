<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row">
            <div class="col-sm-12 btn-regresar-2">
              <a (click)="regresar()" style="cursor: pointer;" class="float-left link-regresar"><img src="../../../assets/IMG/arrow_back.png" /></a>
            </div>
        </div>
        <div class="row mapa-sitio">
            <div class="col-12">
                <h2>Códigos QR {{nombre}}</h2> 
            </div>
        </div>
        <div class="row tabla-catalogo">  
            <div class="col-12">
                <table class="table table-responsive"> 
                    <thead>
                        <tr>
                            <th width="40%">NOMBRE</th>
                            <th width="10%">DESCRIPCIÓN</th>
                            <th width="20%">FECHA DE CREACIÓN</th>
                            <th class="no-sort"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of catalog;">
                            <td>{{group.Nombre}}</td>
                            <td>{{group.Descripcion}}</td>
                            <td>{{group.INSERT_DATE | date:'dd/MM/yyyy'}}</td>
                            <td>
                              <img class="btn-accion" src="../../../assets/IMG/preview-background-gray.png" (click)="preview(true,group.CodigoQRID)" width="40" height="30" title="Preview">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="edicionModal" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': modalQR ? 'block' : 'none', 'opacity': 1}"> 
    <div class="modal-dialog modal-ch modal-dialog-centered" role="dialog" >
      <div class="modal-content">
        <div class="modal-header mdl-hdr text-center">
          <h5 class="modal-title w-100">{{nombreCode}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="preview(false,0)">
            <span aria-hidden="true" class="mdl-close" >&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <ngx-qrcode 
                        style="text-align: center;"
                        [elementType]="elementType"
                        [value]="value"
                        cssClass="aClass"
                        [errorCorrectionLevel]="errorCorrectionLevel">
                    </ngx-qrcode>
                </div>
            </div>
            <div class="div-btns row pt-3">
              <div class="col-12">
                <button class="btn btn-fluid btn-Eliminar btnTableUp" (click)="preview(false,0)" data-dismiss="modal" type="button">CERRAR</button>            
              </div>
            </div>          
        </div>       
      </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>