<div id="site_wrapper">
  <div class="contenedor-principal">
    <div class="row mapa-sitio">
        <div class="col-sm-12 col-md-9">
            <div>
                <a class="lbl-noActive" href="/#/sa-rm/admin/config">Configuraciones > </a><a href="/#/sa-rm/admin/config" class="lbl-noActive">&nbsp;Usuarios > </a><label class="lbl-active">&nbsp;Lista</label>
            </div>
            <h2>Usuarios</h2> 
        </div>
        <div class="col-sm-12">
            <a href="/#/sa-rm/admin/config" class="float-left link-regresar" ><img src="../../../assets/IMG/arrow_back.png" /></a>
        </div>
    </div>
    <div class="row principal">
        <div class="col-sm-7 col-md-8 col-lg-9 col-xl-10">
            <input type="text" [(ngModel)]="searchText" class="buscador form-control" placeholder="Buscador">
        </div>
        <div class="col-sm-5 col-md-4 col-lg-3 col-xl-2">
            <button class='btn-layout btn-fluid float-right btnTableUp' (click)='open(true, 0)' type='button'>
                 NUEVO USUARIO
            </button>
        </div>
    </div>
    <div class="row tabla-catalogo">  
        <div class="col-12">
            <table class="table table-responsive"> 
                <thead>
                <tr>
                    <th width="25%">NOMBRE DE USUARIO</th>
                    <th width="25%">CORREO</th>
                    <th width="20%">ÁREA</th>
                    <th width="20%">ROL</th>
                    <th width="10%">FECHA DE CREACIÓN</th>
                    <th width="35%" class="no-sort"></th>
                    <th class="no-sort"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let group of users | filtercatalogo: searchText; ">
                    <td>{{group.Nombre}}</td>
                    <td>{{group.Correo}}</td>
                    <td>{{group.NombreArea}}</td>
                    <td>{{group.NombreRol}}</td>
                    <td>{{group.FechaCreacion | date:'dd/MM/yyyy'}}</td>
                    <td>
                        <button class="btn btn-layout btn-ch float-right btnTableUp" (click)='open(true, group.UsuarioID)'>Editar</button>
                    </td>
                    <td>
                        <button class="btn btn-Eliminar btn-ch float-right btnTableUp" (click)="delete(group.UsuarioID)">Eliminar</button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>             
    </div>
  </div>
</div>

<div class="modal fade" id="edicionModal" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': mdlEdicion ? 'block' : 'none', 'opacity': 1}"> 
  <div class="modal-dialog modal-lg modal-dialog-centered" role="dialog" >
    <div class="modal-content">
      <div class="modal-header mdl-hdr text-center">
        <h5 class="modal-title w-100"></h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="open(false, 0)">
          <span aria-hidden="true" class="mdl-close" >&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #editForm="ngForm" (ngSubmit)="update()" autocomplete="off" appIdentityRevealed method="POST" enctype="multipart/form-data">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="Nombre">NOMBRE</label>
                <input type="text" class="form-control" #Nombre="ngModel" name="Nombre" [(ngModel)]="obj.Nombre" placeholder="Ingresa texto"  required />
                <div *ngIf="Nombre.invalid"
                    class="lbl-error">
                    <span *ngIf="Nombre.errors.required">
                        El nombre es requerido
                    </span>            
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="Correo">CORREO ELECTRÓNICO</label>
                <input type="email" class="form-control" #Correo="ngModel" name="Correo" [(ngModel)]="obj.Correo" placeholder="Ingresa texto"  required email/>
                <div *ngIf="Correo.invalid"
                    class="lbl-error">
                    <span *ngIf="Correo.errors.required">
                        El correo es requerido
                    </span>
                    <span *ngIf="Correo.errors.email">
                      El correo no es válido
                  </span>      
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="Permiso">ÁREA</label>
                <select class="form-control" #Permiso="ngModel" name="Permiso" [(ngModel)]="obj.AreaId" placeholder="Ingresa texto" required>
                  <option value="{{group.ID}}" *ngFor="let group of catalog.Areas;">{{group.Nombre}}</option>
                </select>
                <div *ngIf="Permiso.invalid"
                    class="lbl-error">
                    <span *ngIf="Permiso.errors.required">
                        El permiso es requerido
                    </span>            
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="Rol">ROL</label>
                <select class="form-control" #Rol="ngModel" name="Rol" [(ngModel)]="obj.RolId" placeholder="Ingresa texto"  required>
                  <option value="{{group.ID}}" *ngFor="let group of catalog.Roles;">{{group.Nombre}}</option>
                </select>
                <div *ngIf="Rol.invalid"
                    class="lbl-error">
                    <span *ngIf="Rol.errors.required">
                        El rol es requerido
                    </span>            
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>Permiso Carga Empresas</label>
                <ng-select
                    #ClaimCargaEmpresa="ngModel" name="ClaimCargaEmpresa"
                    multiple="true" [clearable]="false"
                    [searchable]="true"
                    [(ngModel)]="empresasID">
                    <ng-option [value]="group.EmpresaID" *ngFor="let group of catalog.empresas;">
                        {{group.Nombre}}
                    </ng-option>
                </ng-select>
              </div>
            </div>
            <div class="col-12">
              <h5>PERMISOS</h5>
            </div>
            <div *ngFor="let permiso of catalog.permisoLists" class="col-12">
             <hr>
             <label style="font-weight: bold;">{{permiso[0].moduloPermiso}}</label>
             <div *ngFor="let permisos of permiso">
               <label class="switch">
                   <input type="checkbox" [checked]="validateClaim(permisos.PermisoID)" name="{{permisos.nombrePermiso}}" (change)="updateModuleClaim(permisos.PermisoID)">
                   <span class="slider round"></span>
               </label>
               &nbsp;
               <label>{{permisos.descripcionPermiso}}</label>
              </div>
             </div>
          </div>
          
          <div class="div-btns row pt-3">
            <div class="col-6">
              <button class="btn btn-fluid btn-Eliminar btnTableUp" (click)="open(false, 0)" data-dismiss="modal" type="button">CANCELAR</button>            
            </div>
            <div class="col-6">    
              <button class="btn btn-fluid btn-layout btnTableUp" [disabled]="editForm.invalid">GUARDAR</button>   
            </div> 
          </div>
        </form>               
      </div>       
    </div>
  </div>
</div>

<app-loading-efect></app-loading-efect>