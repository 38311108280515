<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row">
            <div class="col-sm-12 btn-regresar-2">
              <a (click)="regresar()" style="cursor: pointer;" class="float-left link-regresar"><img src="../../../assets/IMG/arrow_back.png" /></a>
            </div>
        </div>
        <div class="row mapa-sitio">
            <div class="col-9">
                <h2>{{client.Nombre}}</h2> 
                <h5  style="color: white;">SPN: {{client.NumProveedor}}</h5> 
            </div>
        </div>
        <div class="row principal">
            <div class="col-sm-12">
                <span style="font-weight: bold; color: white;">Listado de Items {{titleAlerta}}</span>
            </div>
        </div>
        <div class="row tabla-catalogo">  
            <div class="col-12">
                <div class="form-group">
                    <input type="text" [(ngModel)]="searchText" class="form-control buscador" placeholder="Buscador">
                </div>
                <table class="table table-responsive"> 
                    <thead>
                        <tr>
                            <th  width="50%">Item</th>
                            <th  width="15%">Disponibilidad</th>
                            <th  width="10%">Fecha Asignación</th>
                            <th  width="15%">Status</th>
                            <th  width="10%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of cuestionario">
                            <td width="50%">{{group.Nombre}}</td>
                            <td width="15%">{{group.Disponibilidad | date:'dd/MM/yyyy'}}</td>
                            <td width="10%">{{group.FechaCreacion | date:'dd/MM/yyyy'}}</td>
                            <td width="15%">{{group.NombreStatus}}</td>
                            <td width="10%">
                                <button (click)="openPreviewItem(group.ItemEmpresaID)" class="btn btn-layout btn-ch float-right btnTableUp">REVISAR</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>             
        </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>