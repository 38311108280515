<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row mapa-sitio">
            <div class="col-12">
                <h2>Listado de Ingreso</h2> 
            </div>
        </div>
        <div class="row principal">
            <div class="col-sm-7 col-md-8 col-lg-9">
                <input type="text" [(ngModel)]="searchText" class="buscador form-control"  placeholder="Buscador">
            </div>
        </div>
        <div class="row filtros">
            <div class="col-sm-12 col-md-6 col-lg-3">
                <label>DESDE</label>
                <input type="date" class="form-control" name="desde" [(ngModel)]="filter.desde">
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <label>HASTA</label>
                <input type="date" class="form-control" name="hasta" [(ngModel)]="filter.hasta">
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <label>STATUS</label>
                <select [(ngModel)]="filter.status" class="form-control">
                    <option value="TODOS">TODOS</option>
                    <option value="INGRESO">INGRESO</option>
                    <option value="NO INGRESO">NO INGRESO</option>
                    <option value="INGRESO / SIN CARGA">INGRESO / SIN CARGA</option>
                    <option value="INGRESO / CARGA">INGRESO / CARGA</option>
                </select>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <button class='btn btn-danger btn-fluid' type='button' style='margin-top: 31px;' (click)="getRegistros()">
                    FILTRAR
                </button>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <button class='btn btn-warning btn-fluid' type='button' style='margin-top: 31px;'  (click)="exportexcel()">
                    EXCEL
                </button>
            </div>
        </div>
        <div class="row tabla-catalogo">  
            <div class="col-12">
                <table class="table table-responsive" id="tableResumen"> 
                    <thead>
                        <tr>
                            <th width="20%">FECHA DE CREACIÓN</th>
                            <th width="20%">FECHA DE ÚLTIMO INGRESO</th>
                            <th width="50%">EMPRESA</th>
                            <th width="15%">STATUS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of datos | reporteIngreso: searchText; ">
                            <td>{{group.FechaCreacion | date:'dd/MM/yyyy'}}</td>
                            <td>{{group.FechaIngreso | date:'dd/MM/yyyy'}}</td>
                            <td>{{group.Empresa}}</td>
                            <td>{{group.Status}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>             
        </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>