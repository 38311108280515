<div class="page-wrapper chiller-theme toggled">
  <a id="show-sidebar" class="btn btn-sm btn-dark" (click)="showsidebar()">
    <fa-icon [icon]="fasBars"></fa-icon>
  </a>
  <nav id="sidebar" class="sidebar-wrapper">
    <div class="sidebar-content">
      <div class="sidebar-brand">
        <a (click)="openContact(true)" style="cursor: pointer;">Powered by SA-RM</a>
        <div id="close-sidebar" (click)="closesidebar()">
          <fa-icon [icon]="fasTimes"></fa-icon>
        </div>
      </div>
      <div class="sidebar-header">
        <div class="user-pic">
          <img class="img-responsive img-rounded" src="../../../assets/IMG/userimg.png" alt="User picture">
        </div>
        <div class="user-info">
          <span class="user-name">
            <strong>{{identity.Nombre}}</strong>
          </span>
          <span class="user-role"><strong>{{identity.NombreRol}}</strong></span>
          <span class="user-status">
            <span class="onlinelbl pl-2 pr-2">Online</span>
          </span>
        </div>
      </div>
      <!-- sidebar-header  -->
      <div class="sidebar-menu">
        <ul>
          <li class="header-menu">
            <span>Menú</span>
          </li>
          <li *ngIf="Empresas" style="cursor: pointer;" (click)="active('noticias')">
              <a>
                <fa-icon [icon]="faNewspaper"></fa-icon>
                <span>     Noticias</span>
              </a>
          </li>
          <li *ngIf="Empresas" style="cursor: pointer;" (click)="active('client/false')">
              <a>
                <fa-icon [icon]="faTruck"></fa-icon>
                <span>     Transportistas</span>
              </a>
          </li>
          <li *ngIf="validateClaim('Prospectos')" style="cursor: pointer;" (click)="active('prospect')">
              <a>
                <fa-icon [icon]="faAddressBook"></fa-icon>
                <span>     Prospectos</span>
              </a>
          </li>
          <li *ngIf="Dashboard" style="cursor: pointer;" (click)="dashboardModal()">
              <a>
                <fa-icon [icon]="faChartArea"></fa-icon>
                <span>     Dashboard</span>
              </a>
          </li>

          <!--Inicio Sección de Reportes -->
          <li style="cursor: pointer;" id="reportes-dropdown" class="sidebar-dropdown">
            <a (click)="dropdownfunc('reportes')">
              <fa-icon [icon]="faSortAlphaUp"></fa-icon>
              <span>     Reportes</span>
            </a>
            <div id="reportes-submenu" class="sidebar-submenu">
              <ul>
                <li style="cursor: pointer;" (click)="active('reportes-directorio-general')">
                    <a>
                      <span>     Directorio General </span>
                    </a>
                </li>
                <li *ngIf="validateClaim('Reporte Opinion de Cumplimiento')" style="cursor: pointer;" (click)="active('reportes-opinion-cumplimiento')">
                    <a>
                      <span>     Opinión de cumplimiento </span>
                    </a>
                </li>
                <li *ngIf="validateClaim('Reporte Codigo de Conducta')" style="cursor: pointer;" (click)="active('reportes-codigo-conducta')">
                    <a>
                      <span>     Código de conducta </span>
                    </a>
                </li>
                <li *ngIf="validateClaim('Reporte Documentos de Auditoria')" style="cursor: pointer;" (click)="active('reportes-documentos-auditoria')">
                    <a>
                      <span>     Documentos de Auditoría </span>
                    </a>
                </li>
                <li *ngIf="validateClaim('Reporte Estatus Carga')" style="cursor: pointer;" (click)="active('reportes-estatus-carga')">
                    <a>
                      <span>     Estatus de carga</span>
                    </a>
                </li>
                <li *ngIf="validateClaim('Reporte Log Usuarios')" style="cursor: pointer;" (click)="active('reportes-log-usuarios')">
                    <a>
                      <span>     Log Usuarios</span>
                    </a>
                </li>
                <li style="cursor: pointer;" (click)="active('reportes-estatus-contrato')">
                    <a>
                      <span>     Estatus de Contrato</span>
                    </a>
                </li>
                <li *ngIf="validateClaim('Reporte InfoContrato')" style="cursor: pointer;" (click)="active('reportes-legal-infocont')">
                    <a>
                      <span>     Información de Contrato</span>
                    </a>
                </li>
                <li *ngIf="validateClaim('Reporte SegVisitaCert')" style="cursor: pointer;" (click)="active('reportes-seguridad-visitacert')">
                    <a>
                      <span>     Visita de Certificación</span>
                    </a>
                </li>
                <li style="cursor: pointer;" (click)="active('reportes-tipo-unidad')">
                    <a>
                      <span>     Tipos de Unidad</span>
                    </a>
                </li>
                <li style="cursor: pointer;" (click)="active('reportes-origen-destino')">
                    <a>
                      <span>     Origen / Destino</span>
                    </a>
                </li>
                <li *ngIf="Configuraciones" style="cursor: pointer;" (click)="active('reporte-admin')">
                    <a>
                      <span>     Administrador</span>
                    </a>
                </li>
                <li *ngIf="Configuraciones" style="cursor: pointer;" (click)="active('reporte-ingreso')">
                    <a>
                      <span>     Ingreso</span>
                    </a>
                </li>
              </ul>
            </div>
          </li>
          <!--Fin Sección de Reportes -->
          
          <li class="header-menu">
            <span>Administración</span>
          </li>
          <li *ngIf="Configuraciones" style="cursor: pointer;" (click)="active('config')">
              <a>
                <fa-icon [icon]="faCog"></fa-icon>
                <span>     Configuraciones</span>
              </a>
          </li>
          <li *ngIf="Empresas" style="cursor: pointer;" (click)="active('codes-qr')">
              <a>
                <fa-icon [icon]="faCog"></fa-icon>
                <span>     Códigos QR</span>
              </a>
          </li>
          <li style="cursor: pointer;" (click)="active('config-noticias')">
              <a>
                <fa-icon [icon]="faCog"></fa-icon>
                <span>     Noticias</span>
              </a>
          </li>
          <li style="cursor: pointer;" (click)="cambioPass()">
              <a>
                <fa-icon [icon]="faCog"></fa-icon>
                <span>     Cambiar contraseña</span>
              </a>
          </li>
          <li class="header-menu">
            <span>Centro de Ayuda</span>
          </li>
          <li style="cursor: pointer;" (click)="asistencia()">
            <a class="btn btn-warning mb-2" style="color: black;">
              <fa-icon [icon]="faTicketAlt"></fa-icon>
              <span>     Tickets nuevos</span>
              <span class="badge badge-pill badge-danger">{{asistenciasNuevo}}</span>
            </a>
          </li>
          <li style="cursor: pointer;" (click)="asistencia()">
            <a class="btn btn-warning" style="color: black;">
              <fa-icon [icon]="faTicketAlt"></fa-icon>
              <span>     Tickets en curso</span>
              <span class="badge badge-pill badge-danger">{{asistenciasCurso}}</span>
            </a>
          </li>
          
          <ng-template [ngIf]="validateClaim('Reclamos')">
            <li class="header-menu">
              <span>Reclamos</span>
            </li>
            <li style="cursor: pointer;" (click)="active('reclamo')">
              <a>
                <fa-icon [icon]="faExclamationTriangle"></fa-icon>
                <span>     Lista</span>
              </a>
            </li>
            <li style="cursor: pointer;" (click)="active('reclamo-notif')">
              <a>
                <fa-icon [icon]="fasEnvelope"></fa-icon>
                <span>     Pendientes</span>
                <span class="badge badge-pill badge-primary">{{reclamoPendientes}}</span>
              </a>
            </li>
          </ng-template>
          
          <li class="header-menu">
            <span>Alertas</span>
          </li>
          <li style="cursor: pointer;" (click)="openAlertas(5)">
            <a>
              <fa-icon [icon]="fasEnvelope"></fa-icon>
              <span>     Items pendientes</span>
              <span class="badge badge-pill badge-primary">{{itemsPendientes}}</span>
            </a>
          </li>
          <li style="cursor: pointer;" (click)="openAlertas(4)">
            <a>
              <fa-icon [icon]="fasEnvelope"></fa-icon>
              <span>     Reactivos pendientes</span>
              <span class="badge badge-pill badge-primary">{{reactivosPendientes}}</span>
            </a>
          </li>
          <li style="cursor: pointer;" (click)="openAlertas(2)">
            <a>
              <fa-icon [icon]="fasEnvelope"></fa-icon>
              <span>     Por Caducar</span>
              <span class="badge badge-pill badge-primary">{{porCaducar}}</span>
            </a>
          </li>
          <li class="header-menu">
            <span>Alertas Contratos</span>
          </li>
          <li style="cursor: pointer;" (click)="openAlertaContrato()">
            <a>
              <fa-icon [icon]="fasEnvelope"></fa-icon>
              <span>     Pendientes</span>
              <span class="badge badge-pill badge-primary">{{alertaContrato}}</span>
            </a>
          </li>
          <li class="header-menu">
            <span>Alertas Tarifas</span>
          </li>
          <li style="cursor: pointer;" (click)="active('alerta-tarifa')">
            <a>
              <fa-icon [icon]="fasEnvelope"></fa-icon>
              <span>     Pendientes</span>
              <span class="badge badge-pill badge-primary">{{alertaTarifas}}</span>
            </a>
          </li>
          <ng-template [ngIf]="validateClaim('Notificacion Prospectos')">
          <li class="header-menu">
            <span>Alertas Prospectos</span>
          </li>
          <li style="cursor: pointer;" (click)="active('prospect-notif/Pendientes')">
            <a>
              <fa-icon [icon]="fasEnvelope"></fa-icon>
              <span>     Pendientes</span>
              <span class="badge badge-pill badge-primary">{{prospectoPendientes}}</span>
            </a>
          </li>
          <li style="cursor: pointer;" (click)="active('prospect-notif/Expirados')">
            <a>
              <fa-icon [icon]="fasEnvelope"></fa-icon>
              <span>     Expirados</span>
              <span class="badge badge-pill badge-primary">{{prospectoExpirados}}</span>
            </a>
          </li>
          <li style="cursor: pointer;" (click)="active('prospect-notif/Por-Expirar')">
            <a>
              <fa-icon [icon]="fasEnvelope"></fa-icon>
              <span>     Por Expirar</span>
              <span class="badge badge-pill badge-primary">{{prospectoPorExpirar}}</span>
            </a>
          </li>
          </ng-template>
          <br>
        </ul>
      </div>
      <!-- sidebar-menu  -->
    </div>
    <!-- sidebar-content  -->
  </nav>
  <!-- sidebar-wrapper  -->
  <div id="page-content-wrapper" class="page-content">
    
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </div>
  <!-- page-content" -->
</div>
<!-- page-wrapper -->


<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': mdlReport ? 'block' : 'none', 'opacity': 1, 'background-color': 'rgb(0, 0, 0, 0.7)'}" style="overflow: auto;"> 
  <div class="modal-dialog modal-lg modal-dialog-centered" role="dialog" >
    <div class="modal-content">
      <div class="modal-header mdl-hdr text-center">
        <h5 class="modal-title w-100">Contáctanos</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="openContact(false)">
          <span aria-hidden="true" class="mdl-close" >&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <form #createForm="ngForm" (ngSubmit)="enviarCorreo()" autocomplete="off" appIdentityRevealed method="POST" enctype="multipart/form-data">
            <div class="row">
              <div class="col-md-12 col-lg-6">
                <div class="form-group">
                  <label for="Nombre">Nombre</label>
                  <input type="text" class="form-control" #Nombre="ngModel" name="Nombre" [(ngModel)]="obj.Nombre" placeholder="Ingresa texto"  required />
                  <div *ngIf="Nombre.invalid"
                      class="lbl-error">
                      <span *ngIf="Nombre.errors.required">
                          El campo es requerido
                      </span>            
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-6">
                <div class="form-group">
                  <label for="Empresa">Empresa</label>
                  <input type="text" class="form-control" #Empresa="ngModel" name="Empresa" [(ngModel)]="obj.Empresa" placeholder="Ingresa texto"  required />
                  <div *ngIf="Empresa.invalid"
                      class="lbl-error">
                      <span *ngIf="Empresa.errors.required">
                          El campo es requerido
                      </span>            
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-6">
                <div class="form-group">
                  <label for="correo">Correo</label>
                  <input type="text" class="form-control" #correo="ngModel" name="correo" [(ngModel)]="obj.Correo" placeholder="Ingresa texto" email required/>
                  <div *ngIf="correo.invalid" class="lbl-error">
                      <span *ngIf="correo.errors.required">
                          El campo es requerido
                      </span>
                      <span *ngIf="correo.errors.email">
                          Escriba un correo válido
                      </span>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-6">
                <div class="form-group">
                  <label for="telefono">Teléfono</label>
                  <input type="number" class="form-control" #telefono="ngModel" name="telefono" [(ngModel)]="obj.telefono" placeholder="Ingresa teléfono"/>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label for="Descripcion">Comentarios</label>
                  <textarea placeholder="Ingresa texto" #Descripcion="ngModel" name="Descripcion" [(ngModel)]="obj.Descripcion" class="form-control" id="Descripcion" style="resize: none;" rows="3"></textarea>
                </div>
              </div>
            </div>
              <div class="div-btns row pt-3">   
                <div class="col-6">
                  <button class="btn btn-fluid btn-light" (click)="openContact(false)" data-dismiss="modal" type="button">CANCELAR</button>            
                </div>
                <div class="col-6">     
                  <button class="btn btn-fluid btn-dark" [disabled]="createForm.invalid">ENVIAR CORREO</button>    
                </div>
              </div>
          </form>            
      </div>       
    </div>
  </div>
</div>

<div class="modal fade" id="dashboardModal" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': mdlDashboard ? 'block' : 'none', 'opacity': 1, 'background-color': 'rgb(0, 0, 0, 0.7)'}" style="overflow: auto;"> 
  <div class="modal-dialog modal-md modal-dialog-centered" role="dialog" >
    <div class="modal-content" style="background: rgb(21, 24, 30); text-align: center;">
      <div class="modal-body">
        <h2 class="swal2-title" id="swal2-title"><h2>Dashboard</h2></h2>
        <label style="color:white;">Selecciona el dashboard que desees visualizar</label>
        <div class="div-btns row pt-3">   
          <div class="col-12">
            <button id="fourthButton" class="swal2-confirm swal2-styled" style="background-color: #383d41;" (click)="active('dashboard/Aerolinea')">Aerolinea</button>
          </div>
          <div class="col-12">
            <button type="button" class="swal2-confirm swal2-styled" style="background-color: rgb(220, 53, 69); border-left-color: rgb(220, 53, 69); border-right-color: rgb(220, 53, 69);" (click)="active('dashboard/Nacional')">Nacional</button>
            <button type="button" class="swal2-deny swal2-styled" style="background-color: rgb(255, 193, 7);" (click)="active('dashboard/Extranjera')">Extranjera</button>
          </div>
          <div class="col-12">
            <button type="button" class="swal2-cancel swal2-styled" (click)="dashboardModal()" data-dismiss="modal">Cancelar</button>
          </div>
        </div>
      </div>       
    </div>
  </div>
</div>