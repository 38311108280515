import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { GLOBAL } from './../../services/global';
import { ItemService } from 'src/app/services/items/item.service';
import { AsistenciaService } from 'src/app/services/asistencia/asistencia.service';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

@Component({
  selector: 'app-reactivo-preview',
  templateUrl: './reactivo-preview.component.html',
  styleUrls: ['./reactivo-preview.component.css']
})
export class ReactivoPreviewComponent implements OnChanges {
  @Input() idPreview: number;
  loading = new LoadingEfectComponent();
  public id: number;
  public objPreview: any = {};
  public preguntas:any = [];
  public idNewReact: number = 0;
  //Variables para Flujo Tickets
  public mdlTicket: boolean = false;
  public asistenciaREQ: any = {};

  //Variables para opcional general
  public opcionalGen: boolean = true;
  public aplicaGen: boolean = true;

  //Variables para disponibilidad
  public mdlItemList: boolean = false;
  public disponibilidad:any;

  //Variable Lista de botones de certificacion de reactivos a esconder
  public hiddenCert:number[] = [];

  //Variables Historial
  public mdlHistorial: boolean = false;
  public preguntasHist: any = {};

  //Variables Codigo QR
  public modalQR:boolean = false;
  public btnQR:boolean = false;
  public nombre:string = "";
  elementType = NgxQrcodeElementTypes.URL;
  errorCorrectionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value = "";
  nombreCode:string = "";

  //Variables Motivo de Rechazo
  public mdlMotivo:boolean = false;
  public idReact:number = 0;
  public idMotivo:string = "0";
  public commentMotivo:string = "";
  public catalogTL:any[];

  uploadedFiles: Array<File>;
  identity = JSON.parse(localStorage.getItem('identity_user'));

  constructor(
    private _clienteService:ClientesService,
    private _asistService:AsistenciaService,
    private _serviceUsuario:UsuariosService,
    private router:Router
  ) { }

  ngOnChanges() {
    if(this.idPreview){
      this.id = +this.idPreview;
      this.getItem();
      this.getCatalog();
    }
  }
  
  regresar(){
    if(this.identity.empresa){
      this.router.navigate(['/sa-rm/client/itemsublist/' + this.objPreview.ItemID]);
    }else{
      this.router.navigate(["/sa-rm/admin-client/" + this.objPreview.ItemID + "/itemsublist/" + this.objPreview.EmpresaID + "," + this.objPreview.ItemID]);
    }
  }

  public getItem(){    
    this.loading.onLoadingRequest();
    this._clienteService.getItemByEmpresa(this.id)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        if(response.content != null && response.content.length > 0){
          console.log(response.content[0])
          this.objPreview = response.content[0];
          this.getReactivosbyItem(response.content[0].ItemEmpresaID);
          if(this.objPreview.QR > 0){
            this.btnQR = true;
          }
        }
        else{
          Swal.fire("Atención","No hay items que mostrar.","info");
        }
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public getReactivosbyItem(id:number){    
    this._clienteService.getReactivosbyItemEmpresa(id)
    .then(response=>{
      if(response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.objPreview.accessC = response.content.find(x => x.accessC.length > 0) ? true : false;
          this.objPreview.accessV = response.content.find(x => x.accessV.length > 0) ? true : false;
          this.objPreview.accessW = response.content.find(x => x.accessW.length > 0) ? true : false;

          this.preguntas = response.content.map((x) =>
          {
            //Reactivo 11.13 Alcance de operación de unidad - Esconder reactivos dependiendo si es federal o estatal
            let hideFedEst = [140,141,142,143,144,145,146]
            if(hideFedEst.includes(x.ReactivoID)){
              x.hidden = x.NoAplica;
            }else{
              x.hidden = false;
            }
            //-------------------------------------------------------------------------------------------------------
            //Reactivos agrupados
            x.Group = !x.agrupados || x.agrupados == '' ? [] : x.agrupados.split(",").map( Number );
            if(x.Group.length > 0){
              this.hiddenCert.push.apply(this.hiddenCert,x.Group);
              console.log(this.hiddenCert);
            }
            //-------------------------------------------------------------------------------------------------------
            x.Aplica = String(x.NoAplica);
            x.Disponibilidad = x.Disponibilidad.slice(0, -9);
            if(x.VigenciaFIN){
              x.VigenciaFIN = x.VigenciaFIN.slice(0, -9);
            }
            if(x.NombreTipo == "Direccion" && x.Respuesta){
              x.Direccion = JSON.parse(x.Respuesta);
            }else{
              let Res = '{"Estado": "", "Municipio": "", "CP": 0, "Colonia": "", "Calle": "", "Num": ""}';
              x.Direccion = JSON.parse(Res);
            }

            if(!x.Opcional){
              this.opcionalGen = false;
            }
            if(!x.NoAplica){
              this.aplicaGen = false;
            }
            return x
          });
          console.log(this.preguntas);
        }
        else{
          Swal.fire("Atención","No hay items que mostrar.","info");
        }
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      console.log(error.toString());
    });
  }

  public noAplicaGen(){
    this.preguntas.forEach(x => {
      x.NoAplica = !this.aplicaGen;
    });
  }

  public anadirReact(id:number){
    let selectedReact = this.preguntas.find(x=>x.ReactivoItemEmpresaID == id);
    let newReact:any = JSON.parse(JSON.stringify(selectedReact));
    newReact.ReactivoItemEmpresaID = this.idNewReact - 1;
    newReact.Respuesta = null;
    newReact.VigenciaFIN = null;
    this.preguntas.push(newReact)
  }

  public certificar(id:number, status:number){
    let selectedReact = this.preguntas.find(x=>x.ReactivoItemEmpresaID == id);
    selectedReact.Status = status;
    if(selectedReact.Group.length > 0){
      this.preguntas.map((y) =>
      {
        if(selectedReact.Group.includes(y.ReactivoID)){
          y.Status = status;
        }
        return y
      });
    }
    if(status == 3){
      this.modalMotivo(true, id);
    }
  }

  public guardar(status:number){
    this.preguntas.map((x) =>
    {
      if(x.NombreTipo == "Direccion"){
        x.Respuesta = JSON.stringify(x.Direccion);
      }
      return x
    });
    this.loading.onLoadingRequest();
    this.objPreview.Status = status;
    //-----Revisar preguntas agrupadas-----------
    let selectedReact = this.preguntas.filter(x=> x.agrupados && x.agrupados != '');
    if(selectedReact.length > 0){
      var ultimoReact = 0;
      var validReact = true;
      selectedReact.forEach((element) => {
        ultimoReact++;
        let reactsGV = this.preguntas.filter(x=> (element.Group.includes(x.ReactivoID) || element.ReactivoID == x.ReactivoID) && ((x.Opcional && !x.NoAplica) || !x.Opcional) && (!x.Respuesta || x.Respuesta == ''));
        if((element.Group.length + 1) != reactsGV.length && reactsGV.length > 0){
          validReact = false;
        }
        if(ultimoReact == selectedReact.length){
          if(validReact){
            this.save();
          }else{
            Swal.fire('Atención', 'Debes llenar todos los reactivos relacionados para poder continuar.', 'warning');
            this.loading.onDoneRequest();
            return false
          }
        }
      });
    }else{
      this.save();
    }
    //---------------------------------------
  }

  public save(){
    this.objPreview.preguntas = this.preguntas;
    this._clienteService.updateReactivosEmpresa(this.objPreview)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 300){
        Swal.fire('Atención', response.message, 'warning')
            .then(rs=>{
              this.regresar();
            });
      }
      else if(response.status != 200){
        Swal.fire('Error', response.message, 'error');
      }else{
        Swal.fire('Éxito', response.message, 'success')
            .then(rs=>{
              this.regresar();
            });
      }    
    }).catch(error=>{   
      this.loading.onDoneRequest();   
      Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
    });
  }

  readUrl(event:any, id:number) { 
    if (event.target.files && event.target.files[0]) { 
      this.loading.onLoadingRequest();
      this.uploadedFiles = event.target.files;
      let formdata = new FormData();
      for (let i = 0; i< this.uploadedFiles.length; i++){
        formdata.append(id.toString(), this.uploadedFiles[i], this.uploadedFiles[i].name);
      }
      this._clienteService.uploadFile(formdata).then((res)=>{
        this.loading.onDoneRequest();
        let selectedReact = this.preguntas.find(x=>x.ReactivoItemEmpresaID == id);
        selectedReact.Respuesta = res[0];
      });
    }
  }

  descargar(id:number){
    window.open(GLOBAL.urlIMG + '/General/DownloadPdf?documentoId=' + id);
  }

  verDoc(id:string){
    window.open(id);
  }

  public ticket(flag:boolean, id:number){
    if(flag){
      this.asistenciaREQ.AsistenciaID = 0;
      this.asistenciaREQ.ReactivoItemEmpresaID = id;
      this.asistenciaREQ.Status = 1;
      this.asistenciaREQ.UsuarioID = this.identity.UsuarioID;
      this.asistenciaREQ.Comentario = "";
      this.mdlTicket = flag;
    }else{
      this.mdlTicket = flag;
    }
  }

  public crearTicket(){
    this.loading.onLoadingRequest();
    this._asistService.updateAsistencia(this.asistenciaREQ)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status != 200){
        Swal.fire('Error', response.message, 'error');
      }else{
        Swal.fire('Éxito', response.message, 'success')
            .then(rs=>{
              this.mdlTicket = false;
            });
      }    
    }).catch(error=>{   
      this.loading.onDoneRequest();   
      Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
    });
  }

  public aplica(id:number, aplica:boolean){
    let selectedReact = this.preguntas.find(x=>x.ReactivoItemEmpresaID == id);
    selectedReact.NoAplica = aplica;
  }

  public agregarItem(flag:boolean){
    this.mdlItemList = flag;
  }

  public guardarDisp(){
    var objItem = {
      checkCuest: [],
      disponibilidad: this.disponibilidad
    }
    objItem.checkCuest = [];
    
    for(var i = 0; i < this.preguntas.length; i++){
      if($('#ckb-' + this.preguntas[i].ReactivoItemEmpresaID).prop('checked')){
        objItem.checkCuest.push(this.preguntas[i].ReactivoItemEmpresaID);  
      }         
    }
    if(objItem.checkCuest.length <= 0){
      Swal.fire('Atención', "Para cambiar la disponibilidad es necesario elegir al menos un reactivo de la tabla.", 'info');
      return false
    }
    this.loading.onLoadingRequest();
    this._clienteService.updateDisponibilidadReactivo(objItem)
    .then(response=>{
      this.loading.onDoneRequest();
      this.agregarItem(false);
      if(response.status != 200){
        Swal.fire('Error', response.message, 'error');
      }else{
        Swal.fire('Éxito', response.message, 'success')
      }    
    }).catch(error=>{   
      this.loading.onDoneRequest();   
      Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
    });
  }

public historial(open : boolean, id: number) : void {
  if(open){     
    let userAct = this.preguntas.find(usr => usr.ReactivoItemEmpresaID === id);
    console.log(userAct)
    this.preguntasHist.Nombre = userAct.Nombre;
    this.preguntasHist.TipoFK = userAct.TipoFK;
    this.preguntasHist.Catalogos = userAct.Catalogos;
    this.preguntasHist.Historial = userAct.Historial;
    $(".container").css('opacity',0.5);
    $("#sidebar-wrapper").css('opacity',0.5);
  }else{      
    $(".container").css('opacity',1); 
    $("#sidebar-wrapper").css('opacity',1);      
  }
  this.mdlHistorial = open;
}

  //Reactivo 11.13 Alcance de operación de unidad - Esconder reactivos dependiendo si es federal o estatal
  hideFedEst(id:number){
    let userAct = this.preguntas.find(usr => usr.ReactivoItemEmpresaID === id);
    
    let react1116 = this.preguntas.find(usr => usr.ReactivoID === 140);
    let react11161 = this.preguntas.find(usr => usr.ReactivoID === 141);
    let react1117 = this.preguntas.find(usr => usr.ReactivoID === 142);
    let react11171 = this.preguntas.find(usr => usr.ReactivoID === 143);
    let react1118= this.preguntas.find(usr => usr.ReactivoID === 144);
    let react1119 = this.preguntas.find(usr => usr.ReactivoID === 145);
    let react1120 = this.preguntas.find(usr => usr.ReactivoID === 146);
    if(userAct.Respuesta == 20){ //Federal
      react1116.NoAplica = false;  react1116.hidden = false;
      react11161.NoAplica = false; react11161.hidden = false;
      react1117.NoAplica = false;  react1117.hidden = false;
      react11171.NoAplica = false; react11171.hidden = false;
      react1118.NoAplica = true;   react1118.hidden = true;
      react1119.NoAplica = true;   react1119.hidden = true;
      react1120.NoAplica = true;   react1120.hidden = true;
    }else if(userAct.Respuesta == 21){ //Estatal
      react1116.NoAplica = true;  react1116.hidden = true;
      react11161.NoAplica = true; react11161.hidden = true;
      react1117.NoAplica = true;  react1117.hidden = true;
      react11171.NoAplica = true; react11171.hidden = true;
      react1118.NoAplica = false; react1118.hidden = false;
      react1119.NoAplica = false; react1119.hidden = false;
      react1120.NoAplica = false; react1120.hidden = false;
    }else{
      react1116.NoAplica = false;  react1116.hidden = false;
      react11161.NoAplica = false; react11161.hidden = false;
      react1117.NoAplica = false;  react1117.hidden = false;
      react11171.NoAplica = false; react11171.hidden = false;
      react1118.NoAplica = false;  react1118.hidden = false;
      react1119.NoAplica = false;  react1119.hidden = false;
      react1120.NoAplica = false;  react1120.hidden = false;
    }
  }

  //Funcionalidad para QR
  public preview(flag:boolean){
    this.nombreCode = 'Código QR';
    this.value = window.location.origin + '/#/infomultiple-qr/' + this.objPreview.ItemEmpresaID + ',' + this.objPreview.QR;
    this.modalQR = flag;
  }

  //Motivo de Rechazo
  public getCatalog(){    
    this.loading.onLoadingRequest();
    this._serviceUsuario.getCatalog(18)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.catalogTL = response.content;
        }
      }else if(response.response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public modalMotivo(flag:boolean, id:number){
    this.idMotivo = "0";
    this.commentMotivo = "";
    this.idReact = id;
    this.mdlMotivo = flag;
  }

  public updateMotivo(){
    if(this.idMotivo == "0"){
      Swal.fire("Atención","Debes seleccionar un motivo de rechazo.","info");
    }else{
      let selectedReact = this.preguntas.find(x=>x.ReactivoItemEmpresaID == this.idReact);
      selectedReact.ComentarioRechazo = this.commentMotivo;
      selectedReact.MotivoRechazo = this.idMotivo;
      if(selectedReact.Group.length > 0){
        this.preguntas.map((y) =>
        {
          if(selectedReact.Group.includes(y.ReactivoID)){
            y.ComentarioRechazo = this.commentMotivo;
            y.MotivoRechazo = this.idMotivo;
          }
          return y
        });
      }
    }
    this.modalMotivo(false,0);
  }
}