<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row mapa-sitio">
            <div class="col-sm-12 col-md-9">
                <div>
                    <a class="lbl-noActive" href="/#/sa-rm/admin/dashboard/{{flagEXT}}">Dashboard General > </a><label class="lbl-active">&nbsp;ÁREAS</label>
                </div>
                <h1>Detalle</h1> 
            </div>
            <div class="col-sm-12">
                <a href="/#/sa-rm/admin/dashboard/{{flagEXT}}" class="float-left link-regresar" ><img src="../../../assets/IMG/arrow_back_red.png" /></a>
            </div>
        </div>
        <div class="row">
            <div class="col-3" style="height: fit-content;">
                <label class="dash-title">ÁREAS</label><br>
                <label class="font-size: 18px;">Número obtenido del porcentaje de validación de items mandatorios por área.</label>
                <br><br>
                <label class="lbl-filtros">Filtrar</label><br>
                <h3>Por Área</h3>
                <select name="selectArea" [(ngModel)]="selectArea" class="form-control">
                    <option value="0">Seleccionar...</option>
                    <option *ngFor="let item of obj.Roles" [value]="item.ID">{{item.Nombre}}</option>
                </select><br><br>
                <button (click)="gotoDashboard()" class="btn-layout btn-fluid btnTableUp">FILTRAR</button>
            </div>
            <div class="col-9">
                <div id="graph" class="" style="height: 400px;"></div>
            </div>
        </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>