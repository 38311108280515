import { Component, OnInit, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { Router } from '@angular/router';
import { ReclamoService } from 'src/app/services/reclamo/reclamo.service';
import { faEye, faEnvelope } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-reclamo-notif',
  templateUrl: './reclamo-notif.component.html',
  styleUrls: ['./reclamo-notif.component.css']
})
export class ReclamoNotifComponent implements OnInit {
  faEye = faEye;
  faEnvelope = faEnvelope;
  @ViewChildren(DataTableDirective)
  dtElements: QueryList<DataTableDirective>;
  dtOptions:any = {
    dom: 'Bflrtip',
    buttons: [{
        extend:'excel',
        exportOptions: {
          columns: [0,1,2,3,4,5,6,7,8,9,10,11,12]
        }
      }
    ],
    language: {
        "decimal": "",
        "emptyTable": "No hay información",
        "info": "Mostrando _START_ a _END_ de _TOTAL_ Resultados",
        "infoEmpty": "Mostrando 0 to 0 of 0 Resultados",
        "infoFiltered": "(Filtrado de _MAX_ total resultados)",
        "infoPostFix": "",
        "thousands": ",",
        "lengthMenu": "Mostrar _MENU_ Resultados",
        "loadingRecords": "Cargando...",
        "processing": "Procesando...",
        "search": "Buscador:",
        "zeroRecords": "Sin resultados encontrados",
        "paginate": {
            "first": "Primero",
            "last": "Ultimo",
            "next": "Siguiente",
            "previous": "Anterior"
        }
    },
    destroy:true //Add to allow the datatable to destroy
  };
  dtTrigger1 = new Subject();
  dtTrigger2 = new Subject();

  loading = new LoadingEfectComponent();
  identity = JSON.parse(localStorage.getItem('identity_user'));
  public catalog:any[];
  public reclamos:any[];

  constructor(
    private _serviceReclamo:ReclamoService,
    public router: Router
  ) { }

  ngOnInit(){
    this.verifyClaims();
    this.getRegistros(true);
  }

  ngAfterViewInit(): void {
    this.dtTrigger1.next();
    this.dtTrigger2.next();
  }
  
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger1.unsubscribe();
    this.dtTrigger2.unsubscribe();
  }

  verifyClaims(){
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Reclamos');
    if(claim == undefined && claim == null){
      this.router.navigate(['/sa-rm/admin']);
    }
  }

  rerender(): void {
    this.dtElements.forEach((dtElement: DataTableDirective) => {
      if (dtElement.dtInstance)
        dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
    });
    setTimeout(() => {
      this.dtTrigger1.next();
      this.dtTrigger2.next();
    });

  }

  validateClaim(claim: string) {
    return this.identity.permisoLists.find(x => x.nombrePermiso === claim);
  }

  public getRegistros(flagActivo:boolean){
    this.dtOptions.destroy;
    this.loading.onLoadingRequest();
    this._serviceReclamo.getReclamoAlert(flagActivo)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
          this.catalog = response.content.map((x)=>{
            x.FolioReclamo = x.FolioReclamo + x.ReclamoID.toString().padStart(4, '0')
            return x
          });
          this.rerender();
          this.loading.onDoneRequest();
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public getReclamoPendiente(){
    this.dtOptions.destroy;
    this.loading.onLoadingRequest();
    this._serviceReclamo.getReclamoPendiente()
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
          this.reclamos = response.content;
          this.rerender();
          this.loading.onDoneRequest();
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  //#region Correo Notificación Documentos Pendientes a LT
  public correoModal = false;
  objCorreo:any = {
    reclamoID: 0,
    correoLT: "",
    documentos: []
  }
  public documentos:any = [];

  public modalCorreoLT(id: number){
    this.correoModal = !this.correoModal;
    if(this.correoModal){
      this.objCorreo.correoLT = this.reclamos.find(x=>x.ReclamoID == id).CorreoEmpresa;
      this.objCorreo.reclamoID = id
      this.getReclamoDoc();
    }
  }

  public getReclamoDoc(){
    this.loading.onLoadingRequest();
    this._serviceReclamo.getReclamoDoc(this.objCorreo.reclamoID,1,1)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
          this.documentos = response.content;
          this.loading.onDoneRequest();
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public enviarCorreoLT(){
    this.objCorreo.documentos = [];
    
    for(var i = 0; i < this.documentos.length; i++){
      if($('#chk-' + this.documentos[i].CT_ReclamoDocumentoID).prop('checked')){
        this.objCorreo.documentos.push(this.documentos[i].NombreDocumento);  
      }         
    }
    console.log(this.objCorreo);
    if(this.objCorreo.documentos.length <= 0){
      Swal.fire('Atención', "Para agregar items es necesario elegir al menos uno de la tabla.", 'info');
      return false
    }
    
    this.loading.onLoadingRequest();
    this._serviceReclamo.enviarCorreoLT(this.objCorreo)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status != 200){
        Swal.fire('Error', response.message, 'error');
      }else{
        Swal.fire('Éxito', response.message, 'success')
            .then(rs=>{
              this.modalCorreoLT(0);
            });
      }
    }).catch(error=>{   
      this.loading.onDoneRequest();   
      Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
    });
  }
  //#endregion
}
