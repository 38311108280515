import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GLOBAL } from './../../global';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private url:string;
  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': '*/*'
  });

  constructor(
    private _http:HttpClient
  ) {
    //this.leerToken();
    this.url = GLOBAL.url + 'Usuarios';
   }
   
   validateUser(obj:any){
    return this._http.post(this.url + '/validUser?user=' + obj.usuario, obj.usuario, { headers: this.headers })
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
   }

   getToken(obj:any){
    const url = GLOBAL.url + '/Account/getToken?user=' + obj.usuario;
    return this._http.get(url, { headers: this.headers })
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
   }

   validToken(obj:any){
    const url = GLOBAL.url + '/Account/validToken';

    return this._http.post(url, obj, { headers: this.headers })
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
   }

   login(usuario:any){
    const url = GLOBAL.url.replace("api/","Token");

    const body = new HttpParams()
     .set('grant_type', 'password')
     .set('username', usuario.usuario)
     .set('password', usuario.password);
    
    return this._http.post(url, body, { headers: this.headers })
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
   }

   getUser(obj:any, flagLogin){
    const userToken = localStorage.getItem('token').slice(1).slice(0,-1);
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${userToken}`
    });
    
    return this._http.get(this.url + '/getUser?user=' + obj.usuario + '&flagLogin=' + flagLogin, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
   }
}
