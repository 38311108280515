<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row mapa-sitio">
            <div class="col-sm-12 col-md-9">
                <div>
                    <a class="lbl-noActive" href="/#/sa-rm/admin/dashboard/{{flagEXT}}">Dashboard General > </a><label class="lbl-active">&nbsp;Detalle {{role}}</label>
                </div>
                <h1>Detalle</h1> 
            </div>
            <div class="col-sm-12">
                <a href="/#/sa-rm/admin/dashboard/{{flagEXT}}" class="float-left link-regresar" ><img src="../../../assets/IMG/arrow_back_red.png" /></a>
            </div>
        </div>
        <div class="row">
            <div class="col-12" style="height: fit-content;">
                <label class="dash-title">{{role}}</label>
                <a href="/#/sa-rm/admin/dashboard-resumen/{{rolID}},{{selectTP}},{{selectTM}},{{selectTL}},{{flagEXT}}" class="btn btn-danger ml-3 pl-4 pr-4">RESUMEN</a>
            </div>
        </div>
        <div class="row">
            <div class="col-8" style="height: fit-content;">
                <div id="graph" class="" style="height: 400px;"></div>
            </div>
            <div class="col-4">
                    <h3>Tipo de Persona</h3>
                    <select name="selectArea" [(ngModel)]="selectTP" class="form-control">
                        <option value="3">Ambas</option>
                        <option value="1">Moral</option>
                        <option value="2">Física</option>
                    </select><br>
                    <h3>Tipo de Mandatorio</h3>
                    <select name="selectArea" [(ngModel)]="selectTM" class="form-control">
                        <option value="Todos">Todos</option>
                        <option value="Para Firma de Contrato">Para Firma de Contrato</option>
                        <option value="Para Visita de Certificación">Para Visita de Certificación</option>
                        <option value="Para Iniciar Operaciones">Para Iniciar Operaciones</option>
                        <option value="Deseable">No Mandatorio</option>
                    </select><br>
                    <h3>Tipo de Linea</h3>
                    <select name="selectArea" [(ngModel)]="selectTL" class="form-control">
                        <option *ngFor="let tl of catalogTL" value="{{tl.ID}}">{{tl.Nombre}}</option>
                    </select><br>
                    <button (click)="getRegistros()" class="btn-layout btn-fluid btnTableUp">FILTRAR</button>
            </div>
        </div>
        <div [hidden]="tableEmpresas" class="row">
            <div class="col-12" style="height: fit-content;">
                <div class="row principal">
                    <div class="col-sm-7 col-md-8 col-lg-9 col-xl-10">
                        <input type="text" [(ngModel)]="searchDash" class="buscador form-control"  placeholder="Buscador">
                    </div>
                    <div class="col-sm-5 col-md-4 col-lg-3 col-xl-2">
                        <button class='btn-layout btn-fluid float-right btnTableUp' type='button' style='margin-left:10px;' (click)="exportexcel()">
                            EXCEL
                        </button>
                    </div>
                </div>
                <div class="row tabla-catalogo">  
                    <div class="col-12">
                        <table class="table table-responsive" id="tableEmpresas">
                            <thead>
                                <tr>
                                    <th>Razón Social</th>
                                    <th>SPN</th>
                                    <th></th>
                                    <th  *ngFor="let group of obj.DDG" colspan="2">{{group.Nombre}}</th>
                                    <th  colspan="2">Total</th>
                                </tr>
                                <tr>
                                    <th style="position: static;"></th>
                                    <th style="position: static;"></th>
                                    <th style="position: static;"></th>
                                    <ng-template ngFor let-group [ngForOf]="obj.DDG">
                                        <th style="position: static;">% Carga</th>
                                        <th style="position: static;">% Validación</th>
                                    </ng-template>
                                    <th style="position: static;">% Carga</th>
                                    <th style="position: static;">% Validación</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let group of obj.ListDD | dashboardEmpresa: searchDash; ">
                                    <td>{{group.NombreEmpresa}}</td>
                                    <td>{{group.spn}}</td>
                                    <td><a href="/#/sa-rm/admin/dashboard-detalle/{{group.EmpresaId}},{{rolID}},{{flagEXT}}" style="color: red !important;">Ver detalle</a></td>
                                    <ng-template ngFor let-groupData [ngForOf]="group.Data">
                                        <ng-template [ngIf]="validateId(groupData.NombreItem)">
                                            <td data-t="s" style="white-space: nowrap;">{{groupData.Carga}}</td>
                                            <td data-t="s" style="white-space: nowrap;">{{groupData.Validacion}}</td>
                                        </ng-template>
                                    </ng-template>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>