import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css']
})
export class GeneralComponent implements OnInit {
  loading = new LoadingEfectComponent();
  searchText;
  public obj:any = {};
  
  public catalog:any[];

  identity = JSON.parse(localStorage.getItem('identity_user'));

  constructor(
    private _service:UsuariosService,
    public router: Router
    ) { }
  
  public mdl : boolean = false;

  ngOnInit() {
    this.verifyClaims();
    this.getTypeCatalog();
    this.loading.onDoneRequest();
  }

  verifyClaims(){
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Configuraciones');
    if(claim == undefined && claim == null){
      this.router.navigate(['/sa-rm/admin']);
    }
    this.identity.permisoLists
  }

  public getTypeCatalog(){    
    this.loading.onLoadingRequest();
    this._service.getTypeCatalog(0)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.catalog = response.content;
        }
      }else if(response.response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public open(open : boolean, id: number) : void {
    this.obj.Nombre = "";
    this.obj.id = id;
    if(open){     
      $(".container").css('opacity',0.5);
      $("#sidebar-wrapper").css('opacity',0.5);
      $(".modal-title").html("CREAR ÁREA");
      if(id != 0){
        $(".modal-title").html("EDITAR ÁREA");
        let catAct = this.catalog.find(cat => cat.ID === id);
        console.log(catAct)
        this.obj.Nombre = catAct.Nombre;
      }
    }else{      
      $(".container").css('opacity',1); 
      $("#sidebar-wrapper").css('opacity',1);      
    }
    this.mdl = open;
  }

  update(){
    this.loading.onLoadingRequest();
    this._service.updateTypeCatalog(this.obj)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        Swal.fire('Éxito', response.message, 'success');
        this.open(false, 0);
        this.getTypeCatalog();
      }else if (response.status == 500){      
        Swal.fire('Error', response.message, 'error');
      }else {
        Swal.fire("Atención",response.message,"info");
      }
    }).catch(error=>{ 
      this.loading.onDoneRequest();     
      Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
    })
  }

  delete(id: number){
    Swal.fire({
      title: 'Atención',
      text: "¿Estas seguro que deseas eliminar el registro?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading.onLoadingRequest();
        this._service.deleteTypeCatalog(id)
        .then(response=>{
          this.loading.onDoneRequest();
          if(response.status == 200){
            Swal.fire('Éxito', response.message, 'success');
            this.open(false, 0);        
          
            this.getTypeCatalog();
          }else{
            Swal.fire('Error', response.message, 'error');
          }
        }).catch(error=>{  
          this.loading.onDoneRequest();    
          Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
        })
      }
    })
  }
}