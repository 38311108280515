import { Component, OnInit } from '@angular/core';
import { ItemService } from 'src/app/services/items/item.service';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';

@Component({
  selector: 'app-updateitem2',
  templateUrl: './updateitem2.component.html',
  styleUrls: ['./updateitem2.component.css']
})
export class Updateitem2Component implements OnInit {
  identity = JSON.parse(localStorage.getItem('identity_user'));
  loading = new LoadingEfectComponent();
  searchText;
  private itemId = this._route.snapshot.paramMap.get('item');
  public catalog:any[];
  public agrupados:any[];
  public tipoLinea:any[];
  catalogs:any = {
    reactivos:[]
  };
  public mdl : boolean = false;
  item:any = {};
  obj:any = {};
  public Periodicidadswt : boolean = false;
  public items = [];

  constructor(
    private _service:ItemService,
    private user_service:UsuariosService,
    private router:Router,
    private _route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.verifyClaims();
    this.getItem();
    this.getReactivos();
    this.getTipoCampo();
    this.getTipoCatalogo();
    this.getRoles();
    this.getCatalog();
    this.loading.onDoneRequest();
  }

  verifyClaims(){
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Configuraciones');
    if(claim == undefined && claim == null){
      this.router.navigate(['/sa-rm/admin']);
    }
    this.identity.permisoLists
  }

  public getItem(){
    this.obj.ItemID = +this.itemId;
    this.loading.onLoadingRequest();
    this._service.getItem(+this.itemId)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.item = response.content[0];
          if(this.item.NacionalExtranjera.includes('Prospecto')){
            this.getItems();
          }
          console.log(this.item);
        }
        else{
          Swal.fire("Atención","No hay items que mostrar.","info");
        }
      }else if(response.response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public getReactivos(){    
    this.loading.onLoadingRequest();
    this._service.getReactivosbyItem(+this.itemId)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.catalog = response.content;
          console.log(this.catalog);
        }
        else{
          Swal.fire("Atención","No hay reactivos que mostrar.","info");
        }
      }else if(response.response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public getCatalog(){    
    this.loading.onLoadingRequest();
    this.user_service.getCatalog(17)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.catalogs.TipoLinea = response.content;
        }
      }else if(response.response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public getTipoCampo(){    
    this._service.getTipoCampo()
    .then(response=>{
      if(response.response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.catalogs.TipoCampo = response.content;
        }
      }else if(response.response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      console.log(error.toString());
    });
  }

  public getTipoCatalogo(){    
    this.user_service.getTypeCatalog(0)
    .then(response=>{
      if(response.response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.catalogs.TipoCatalog = response.content;
        }
      }else if(response.response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      console.log(error.toString());
    });
  }

  public getRoles(){    
    this.user_service.getRoles()
    .then(response=>{
      if(response.response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.catalogs.Roles = response.content;
        }
      }else if(response.response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      console.log(error.toString());
    });
  }

  public goBack(){
    this.router.navigate(['/sa-rm/updateitem/' + this.itemId.toString()]);
  }

  delete(id: number){
    Swal.fire({
      title: 'Atención',
      text: "¿Estas seguro que deseas eliminar el reactivo?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading.onLoadingRequest();
        this._service.deleteReactivo(id)
        .then(response=>{
          this.loading.onDoneRequest();
          if(response.status == 200){
            Swal.fire('Éxito', response.message, 'success');
            this.getItem();
            this.getReactivos();
          }else{
            Swal.fire('Error', response.message, 'error');
          }
        }).catch(error=>{  
          this.loading.onDoneRequest();    
          Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
        })
      }
    })
  }

  public open(open : boolean, id: number) : void {
    this.obj.ReactivoID = id;
    this.obj.Nombre = "";
    this.obj.Descripcion = "";
    this.obj.Orden = "";
    this.obj.TipoFK = 0;
    this.obj.TipoCatalogoID = 0;
    this.obj.TipoPersona = 0;
    this.obj.Mandatorio = "0";
    if(this.item.NacionalExtranjera.includes('Prospecto'))
    {
      this.obj.Mandatorio = "Deseable";
      this.obj.NacionalExtranjera = "Todas";
    }else{
      this.obj.NacionalExtranjera = this.item.NacionalExtranjera;
    }
    this.obj.ItemVinculado = null;
    this.obj.ReactivoVinculado = null;
    this.obj.TipoLinea = "";
    this.tipoLinea = [];
    this.obj.Periodicidad = 0;
    this.Periodicidadswt = false;
    this.obj.Vencimiento = false;
    this.obj.Opcional = false;
    this.obj.Multiple = false;
    this.obj.MultipleTipoPersona = null;
    this.obj.Calificacion = "";
    this.obj.agrupados = "";
    this.agrupados = [];
    this.obj.accessV = [];
    this.obj.accessC = [];
    this.obj.accessW = [];
    if(open){     
      $(".container").css('opacity',0.5);
      $("#sidebar-wrapper").css('opacity',0.5);
      $(".modal-title").html("CREAR REACTIVO");
      if(id != 0){
        $(".modal-title").html("EDITAR REACTIVO");
        let catAct = this.catalog.find(cat => cat.ReactivoID === id);
        console.log(catAct)
        this.obj.Nombre = catAct.Nombre;
        this.obj.Descripcion = catAct.Descripcion;
        this.obj.Orden = catAct.Orden;
        this.obj.TipoFK = catAct.TipoFK;
        this.obj.TipoCatalogoID = catAct.TipoCatalogoID;
        this.obj.TipoPersona = catAct.TipoPersona;
        this.obj.Periodicidad = catAct.Periodicidad;
        this.Periodicidadswt = +catAct.Periodicidad > 0 ? true : false;
        this.obj.Vencimiento = catAct.Vencimiento;
        this.obj.Mandatorio = catAct.Mandatorio;
        this.obj.NacionalExtranjera = catAct.NacionalExtranjera;
        this.obj.ItemVinculado = catAct.ItemVinculado;
        if(catAct.ItemVinculado != null){
          this.getReactivosByItem(catAct.ReactivoVinculado);
        }
        this.obj.NacionalExtranjera = catAct.NacionalExtranjera;
        this.tipoLinea = !catAct.TipoLinea || catAct.TipoLinea == '' ? [] : catAct.TipoLinea.split(",").map( Number );
        this.obj.Opcional = catAct.Opcional;
        this.obj.Multiple = catAct.Multiple;
        this.obj.MultipleTipoPersona = catAct.MultipleTipoPersona;
        this.obj.Calificacion = catAct.Calificacion;
        this.agrupados = !catAct.agrupados || catAct.agrupados == '' ? [] : catAct.agrupados.split(",").map( Number );
        this.obj.accessV = catAct.accessV;
        this.obj.accessC = catAct.accessC;
        this.obj.accessW = catAct.accessW;
      }
    }else{      
      $(".container").css('opacity',1); 
      $("#sidebar-wrapper").css('opacity',1);      
    }
    this.mdl = open;
  }

  setPeriodicidad(){
    this.obj.Periodicidad = 0;
  }

  setMultipleTipoPersona(){
    if(this.obj.Multiple){
      this.obj.MultipleTipoPersona = 3;
    }else{
      this.obj.MultipleTipoPersona = null;
    }
  }
  
  update(){
    if(this.obj.TipoFK == 8 && this.obj.TipoCatalogoID == 0){
      Swal.fire('Atención', 'Debes seleccionar un tipo de catálogo para el selector.', 'info');
      return false;
    }
    this.obj.agrupados = this.agrupados.toString();
    this.obj.TipoLinea = this.tipoLinea.toString();
    this.loading.onLoadingRequest();
    this._service.updateReactivo(this.obj)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        this.open(false, 0);
        this.getItem();
        this.getReactivos();
      }else if (response.status == 500){      
        Swal.fire('Error', response.message, 'error');
      }else {
        Swal.fire("Atención",response.message,"info");
      }
    }).catch(error=>{ 
      this.loading.onDoneRequest();     
      Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
    })
  }

  public saveItem(){
    this.router.navigate(['/sa-rm/items']);
  }

  //#region Reactivos Vinculados (Solo Prospectos)
  public getItems(){
    this.obj.ItemID = +this.itemId;
    this.loading.onLoadingRequest();
    this._service.getItem(0)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.items = response.content.filter(x=> !x.NacionalExtranjera.includes('Prospecto'));
          console.log(this.items);
        }
        else{
          Swal.fire("Atención","No hay items que mostrar.","info");
        }
      }else if(response.response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }
  
  public getReactivosByItem(ReactivoVinculado:number = null){
    this.obj.ReactivoVinculado = null;
    if(this.obj.ItemVinculado == null){
      this.catalogs.reactivos = [];
    }else{
      this.loading.onLoadingRequest();
      this._service.getReactivosbyItem(+this.obj.ItemVinculado)
      .then(response=>{
        this.loading.onDoneRequest();
        if(response.response.status == 200){
          if(response.content != null && response.content.length > 0){
            this.catalogs.reactivos = response.content.filter(x=>x.TipoFK !=  7);
            if(ReactivoVinculado != null){
              this.obj.ReactivoVinculado = ReactivoVinculado;
            }
          }
        }else if(response.response.status == 500){
          Swal.fire("Error",response.message,"error");
        }
      })
      .catch(error=>{
        this.loading.onDoneRequest();
        console.log(error.toString());
      });
    }
  }
  //#endregion
}
