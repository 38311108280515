import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import { ItemService } from 'src/app/services/items/item.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';

@Component({
  selector: 'app-item-sublist',
  templateUrl: './item-sublist.component.html',
  styleUrls: ['./item-sublist.component.css'],
  providers:[DatePipe]
})
export class ItemSublistComponent implements OnInit {
  loading = new LoadingEfectComponent();
  identity = JSON.parse(localStorage.getItem('identity_user'));
  public ItemId = +this._route.snapshot.paramMap.get('id');
  public cuestionario: any = [];
  public item: any = {};
  searchText;
  public todayDate:any = new Date();

  constructor(
    private datepipe: DatePipe,
    private _service:ClientesService,
    private _serviceItem:ItemService,
    private _route: ActivatedRoute,
    public _router: Router
    ) { }

    ngOnInit(){
      this.todayDate = this.datepipe.transform(this.todayDate, 'yyyy-MM-dd');
      this.getItem();
      this.getItems();
    }

    public getItem(){
      this.loading.onLoadingRequest();
      this._serviceItem.getItem(+this.ItemId)
      .then(response=>{
        this.loading.onDoneRequest();
        if(response.response.status == 200){
          if(response.content != null && response.content.length > 0){
            this.item = response.content[0];
            console.log(this.item);
          }
          else{
            Swal.fire("Atención","No hay items que mostrar.","info");
          }
        }else if(response.response.status == 500){
          Swal.fire("Error",response.message,"error");
        }
      })
      .catch(error=>{
        this.loading.onDoneRequest();
        console.log(error.toString());
      });
    }

    public getItems(){    
      this._service.getItemEmpresaSubList(this.identity.empresa.EmpresaID, this.ItemId).then(response=>{     
        this.cuestionario = response.content.map((x) =>
          {
            x.Disponibilidad = x.Disponibilidad.slice(0, -9);
            return x
          });
      })
      .catch(error => {      
        console.log(error.toString());
      });
    }

    multiple() {
      var objItem = {
        checkCuest: [this.ItemId],
        empresaID: this.identity.empresa.EmpresaID
      }
      
      if(objItem.checkCuest.length <= 0){
        Swal.fire('Atención', "Para agregar items es necesario elegir al menos uno de la tabla.", 'info');
        return false
      }
      this.loading.onLoadingRequest();
      this._service.updateItemEmpresa(objItem)
      .then(response=>{
        this.loading.onDoneRequest();
        if(response.status != 200){
          Swal.fire('Error', response.message, 'error');
        }else{
          Swal.fire('Éxito', response.message, 'success')
              .then(rs=>{
                this.getItems();
              });
        }    
      }).catch(error=>{   
        this.loading.onDoneRequest();   
        Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
      });
    }

    verCuestionario(idcc:number) {
      this._router.navigate(['/sa-rm/itemreview/' + idcc]);
    }
    
    regresar(){
      this._router.navigate(['/sa-rm/client/dashboard']);
    }
}
