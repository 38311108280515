import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtercatalogo'
})

export class FiltercatalogoPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;

    searchText = searchText.toLowerCase();

    return items.filter( it => {
      return it.Nombre.toLowerCase().includes(searchText)
      || it.Correo.toLowerCase().includes(searchText)
      || it.NombreArea.toLowerCase().includes(searchText)
      || it.NombreRol.toLowerCase().includes(searchText)
      || it.FechaCreacion.toLowerCase().includes(searchText);
    });
  }
}
