<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row mapa-sitio">
            <div class="col-12">
                <h2>Empresas</h2> 
            </div>
            <div class="col-md-12 col-lg-6 col-xl-3">
                <label style="color: white;">TIPO DE SERVICIO</label>
                <select [(ngModel)]="tipoServicio" (change)="filters()" class="form-control">
                    <option value="0">NACIONAL</option>
                    <option value="1">EXTRANJERA</option>
                    <option value="2">AEROLINEA</option>
                </select>
            </div>
            <div *ngIf="validateClaim('Ver Eliminados')" class="col-md-12 col-lg-6 col-xl-3">
                <label style="color: white;">STATUS</label>
                <select [(ngModel)]="flagInactiva" (change)="filters()" class="form-control">
                    <option value="-1">TODOS</option>
                    <option value="0">ACTIVOS</option>
                    <option value="1">INACTIVOS</option>
                </select>
            </div>
            <div class="col-md-12 col-lg-6 col-xl-3">
                <label style="color: white;">TIPO DE UNIDAD</label>
                <ng-select [searchable]="true" (change)="filters()" [(ngModel)]="flagFilters.Unidad" placeholder="Seleccionar...">
                    <ng-option [value]="dato.Nombre" *ngFor="let dato of catalogFilters.Unidad;">
                        {{dato.Nombre}}
                    </ng-option>
                </ng-select>
            </div>
            <div class="col-md-12 col-lg-6 col-xl-3">
                <label style="color: white;">ORIGEN</label>
                <ng-select [searchable]="true" (change)="filters()" [(ngModel)]="flagFilters.Origen" placeholder="Seleccionar...">
                    <ng-option [value]="dato.Nombre" *ngFor="let dato of catalogFilters.Origen;">
                        {{dato.Nombre}}
                    </ng-option>
                </ng-select>
            </div>
            <div class="col-md-12 col-lg-6 col-xl-3">
                <label style="color: white;">DESTINO</label>
                <ng-select [searchable]="true" (change)="filters()" [(ngModel)]="flagFilters.Destino" placeholder="Seleccionar...">
                    <ng-option [value]="dato.Nombre" *ngFor="let dato of catalogFilters.Destino;">
                        {{dato.Nombre}}
                    </ng-option>
                </ng-select>
            </div>
        </div>
        <div class="row principal">
            <div class="col-sm-5 col-md-4 col-lg-3">
                <button class='btn-layout btn-fluid float-right btnTableUp' [hidden]="!claimEdit || deleted == 'true'" type='button' style='margin-left:10px;' routerLink="/sa-rm/nuevocliente">
                    CREAR NUEVO
                </button>
            </div>
        </div>
        <div class="row tabla-catalogo">  
            <div class="col-12">
                <table class="table table-responsive table-sm table-hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"> 
                    <thead>
                        <tr>
                            <th width="10%">NUM PROVEEDOR</th>
                            <th width="30%">NOMBRE EMPRESA</th>
                            <th width="30%">NOMBRE COMERCIAL</th>
                            <th width="10%">TIPO PERSONA</th>
                            <th width="10%">PROVEEDOR</th>
                            <th width="10%">ACTIVA/INACTIVA</th>
                            <th width="10%" *ngIf="validateClaim('Ver Eliminados')">MOTIVO INHABILITACIÓN</th>
                            <th width="10%">ITEM A</th>
                            <th width="10%">ITEM V</th>
                            <th width="20%">FECHA DE CREACIÓN</th>
                            <th width="10%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of catalog;">
                            <td>{{group.NumProveedor}}</td>
                            <td>{{group.Nombre}}</td>
                            <td>{{group.NombreComercial}}</td>
                            <td>{{group.NombreTipoPersona}}</td>
                            <td>{{group.ProveedorVal}}</td>
                            <td>{{group.EliminadoValue}}</td>
                            <td *ngIf="validateClaim('Ver Eliminados')">{{group.MotivoInhabil}}</td>
                            <td>{{group.itemA}}</td>
                            <td>{{group.itemV}}</td>
                            <td>{{group.FechaCreacion | date:'dd/MM/yyyy'}}</td>
                            <td style="text-wrap: nowrap;">
                                <button class="btn btn-layout btn-ch btnTableUp" routerLink="/sa-rm/admin-client/{{group.EmpresaID}}/ft/{{group.EmpresaID}}">VER</button>
                                <ng-template [ngIf]="validateClaim('Eliminar Empresa')">
                                    &nbsp;
                                    <button (click)="deleteEmpresa(group.EmpresaID)" class="btn btn-eliminar" title="Eliminar">
                                        <fa-icon [icon]="fasTrash"></fa-icon>
                                    </button>
                                </ng-template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>             
        </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>