<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row mapa-sitio">
            <div class="col-9 mb-2">
                <div>
                    <a class="lbl-noActive" href="/#/sa-rm/admin/prospect">Prospectos > </a><a href="/#/sa-rm/admin/prospect-ft/{{id}}" class="lbl-noActive">&nbsp;{{Pros.RazonSocial}} > </a><label class="lbl-active">&nbsp;Ficha Técnica</label>
                </div>
                <h2>Prospecto</h2>
            </div>
        </div>
        <div class="row card-body mb-3" style="border-left: 30px solid #ffcc01;">
            <div class="col-md-12">
                <h3>{{Pros.RazonSocial}} 
                    <img *ngIf="Pros.Status == 0" src="./../../../../../assets/IMG/resultado_intermedio.png">
                    <img *ngIf="Pros.Status == 1" src="./../../../../../assets/IMG/resultado_fundamental.png">
                    <img *ngIf="Pros.Status == 2" src="./../../../../../assets/IMG/resultado_avanzado.png">
                </h3>
                <h5>{{Pros.NombreTipoServicio}}</h5>
                <h5>Entrevistador: {{Pros.Create_User_Name}}</h5>
                <h5>Fecha de Entrevista: {{Pros.INSERT_DATE | date:'dd/MM/yyyy'}}</h5>
            </div>
        </div>
    
        <form #createForm="ngForm" autocomplete="off" appIdentityRevealed>
            <div class="row editar-empresa">
                <div class="col-md-12 col-lg-6 mb-3">
                    <div class="graph-title">DATOS EMPRESA
                        <button class='btn-Eliminar btn-ch btnTableUp ml-2' [hidden]="editarData || !validateClaim('Carga Prospectos') || Pros.Status >= 3 || (identity.Rol != 2 && Pros.Create_User != identity.UsuarioID)" type='button' (click)='editarFT()'>
                            EDITAR
                        </button>
                        <button class='btn-Eliminar btn-ch btnTableUp ml-2' [disabled]="createForm.invalid" [hidden]="!editarData" (click)='update()'>
                            GUARDAR
                        </button>
                    </div>
                    <div class="row graphs-client ml-0 mr-0 pt-2 pb-2">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="RazonSocial">RAZÓN SOCIAL</label>
                                <input type="text" class="form-control" id="RazonSocial" #RazonSocial="ngModel" name="RazonSocial" [(ngModel)]="Pros.RazonSocial" [disabled]="!editarData" oninput="this.value = this.value.toUpperCase().replace(/[^a-zA-Z0-9 ]/g, '')">
                            </div>
                            <div class="form-group">
                                <label for="NombreComercial">NOMBRE COMERCIAL</label>
                                <input type="text" class="form-control" id="NombreComercial" #NombreComercial="ngModel" name="NombreComercial" [(ngModel)]="Pros.NombreComercial" required [disabled]="!editarData" oninput="this.value = this.value.toUpperCase().replace(/[^a-zA-Z0-9 ]/g, '')">
                                <div *ngIf="NombreComercial.invalid" class="lbl-error">
                                    <span *ngIf="NombreComercial.errors.required">
                                        El campo es requerido
                                    </span>            
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="TipoPersona">TIPO PERSONA</label>
                                <select name="TipoPersona" id="TipoPersona" #TipoPersona="ngModel" name="TipoPersona" [(ngModel)]="Pros.TipoPersona" class="form-control" required pattern="[^0].*" [disabled]="!editarData">
                                    <option value="0" disabled>Seleccionar...</option>
                                    <option value="1">Moral</option>
                                    <option value="2">Física</option>
                                    <option value="3">Ambas</option>
                                </select>
                                <div *ngIf="TipoPersona.invalid" class="lbl-error">
                                    <span *ngIf="TipoPersona.errors.required || TipoPersona.errors.pattern">
                                        El tipo de persona es requerido
                                    </span>            
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="TipoLineaArray">TIPO DE LINEA</label>
                                <ng-select id="TipoLineaArray" #TipoLineaArray="ngModel" name="TipoLineaArray" placeholder="Seleccionar..."                                
                                        multiple="true" [clearable]="false" appendTo=".contenedor-principal" [(ngModel)]="Pros.TipoLineaArray" required [disabled]="!editarData">
                                    <ng-option [value]="tl.ID" *ngFor="let tl of catalogTL">{{tl.Nombre}}</ng-option>
                                </ng-select>
                                <div *ngIf="TipoLineaArray.invalid" class="lbl-error">
                                    <span *ngIf="TipoLineaArray.errors.required">
                                        El tipo de linea es requerido
                                    </span>            
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-6 mb-3">
                    <div class="graph-title">CONTACTO</div>
                    <div class="row graphs-client ml-0 mr-0 pt-2 pb-2">
                        <div class="col-12">
                            <div class="form-group">
                              <label for="NombreContacto">NOMBRE COMPLETO</label>
                              <input type="text" class="form-control" #NombreContacto="ngModel" name="NombreContacto" [(ngModel)]="Pros.NombreContacto" placeholder="Ingresa texto"  required [disabled]="!editarData"/>
                              <div *ngIf="NombreContacto.invalid" class="lbl-error">
                                  <span *ngIf="NombreContacto.errors.required">
                                      El correo es requerido
                                  </span> 
                              </div>
                            </div>
                            <div class="form-group">
                                <label for="Correo">CORREO</label>
                                <input type="text" class="form-control" id="Correo" #Correo="ngModel" name="Correo" [(ngModel)]="Pros.Correo" required email [disabled]="!editarData">
                                <div *ngIf="Correo.invalid" class="lbl-error">
                                    <span *ngIf="Correo.errors.required">
                                        El campo es requerido
                                    </span>        
                                    <span *ngIf="Correo.errors.email">
                                        El correo no es válido
                                    </span>     
                                </div>
                            </div>
                            <div class="form-group">
                              <label for="Telefono">TELÉFONO</label>
                              <input type="tel" class="form-control" #Telefono="ngModel" name="Telefono" [(ngModel)]="Pros.Telefono" placeholder="Ingresa texto"  required mask='0000000000' [disabled]="!editarData"/>
                              <div *ngIf="Telefono.invalid" class="lbl-error">
                                  <span *ngIf="Telefono.errors.required">
                                      El telefono es requerido
                                  </span>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div class="row tabla-RutasPrincipales">  
            <div class="col-12">
                <table class="table table-responsive table-hover"> 
                    <thead>
                        <tr>
                            <th width="100%" colspan="3">Rutas Principales donde da servicio la LT
                                <ng-template [ngIf]="!editarDataRP && validateClaim('Carga Prospectos') && Pros.Status < 3 && (identity.Rol == 2 || Pros.Create_User == identity.UsuarioID)">
                                    <button class='btn-Eliminar btn-ch btnTableUp ml-2' type='button' (click)='editarRP()'>
                                        Agregar
                                    </button>
                                    &nbsp;
                                    <button class='btn-Eliminar btn-ch btnTableUp ml-2' type='button' (click)='openCargaExcel(true,1)'>
                                        Carga Masiva
                                    </button>
                                    &nbsp;
                                </ng-template>
                                <button [hidden]="editarDataRP" class='btn-Eliminar btn-ch btnTableUp ml-2' type='button' (click)='exportexcel(1)'>
                                    Exportar
                                </button>
                                <button class='btn-guardar btn-ch btnTableUp ml-2' [hidden]="!editarDataRP" type='button' (click)='updateRutasPrincipales()'>
                                    Guardar
                                </button>
                                <button class='btn-red btn-ch btnTableUp ml-2' [hidden]="!editarDataRP" type='button' (click)='editarRP()'>
                                    Cancelar
                                </button>
                            </th>
                        </tr>
                        <tr>
                            <th  width="50%">Origen</th>
                            <th  width="50%">Destino</th>
                            <th  width="10%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of RutasPrincipales" style="cursor: pointer;">
                            <div *ngIf="group.ProspectoRutasPrincipalesID > 0; then thenBlock else elseStatement"></div>
                            <ng-template #thenBlock>
                                <td>{{group.Origen}}</td>
                                <td>{{group.Destino}}</td>
                                <td>
                                    <button [hidden]="!validateClaim('Carga Prospectos') || Pros.Status >= 3 || (identity.Rol != 2 && Pros.Create_User != identity.UsuarioID)" class="btn btn-eliminar" (click)="deleteRutasPrincipales(group.ProspectoRutasPrincipalesID)" title="Eliminar">
                                        <fa-icon [icon]="fasTrash"></fa-icon>
                                    </button>
                                </td>
                            </ng-template>
                            <ng-template #elseStatement>
                                <td>
                                    <ng-select [searchable]="true" [(ngModel)]="group.Origen" placeholder="Seleccionar..." appendTo=".contenedor-principal">
                                        <ng-option [value]="dato.Nombre" *ngFor="let dato of catalog.Origen;">
                                            {{dato.Nombre}}
                                        </ng-option>
                                    </ng-select>
                                </td>
                                <td>
                                    <ng-select [searchable]="true" [(ngModel)]="group.Destino" placeholder="Seleccionar..." appendTo=".contenedor-principal">
                                        <ng-option [value]="dato.Nombre" *ngFor="let dato of catalog.Destino;">
                                            {{dato.Nombre}}
                                        </ng-option>
                                    </ng-select>
                                </td>
                                <td></td>
                            </ng-template>
                        </tr>
                    </tbody>
                </table>
                <table [hidden]="true" class="table table-responsive"  id="tableRutasPrincipales"> 
                    <thead>
                        <tr>
                            <th  width="50%">Origen</th>
                            <th  width="50%">Destino</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of RutasPrincipales">
                            <td>{{group.Origen}}</td>
                            <td>{{group.Destino}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>             
        </div>

        <div class="row tabla-TipoUnidades">  
            <div class="col-12">
                <table class="table table-responsive table-hover"> 
                    <thead>
                        <tr>
                            <th width="100%" colspan="4">Tipo de Unidad
                                <ng-template [ngIf]="!editarDataTU && validateClaim('Carga Prospectos') && Pros.Status < 3 && (identity.Rol == 2 || Pros.Create_User == identity.UsuarioID)">
                                    <button class='btn-Eliminar btn-ch btnTableUp ml-2' type='button' (click)='editarTU()'>
                                        Agregar
                                    </button>
                                    &nbsp;
                                    <button class='btn-Eliminar btn-ch btnTableUp ml-2' type='button' (click)='openCargaExcel(true,2)'>
                                        Carga Masiva
                                    </button>
                                    &nbsp;
                                </ng-template>
                                <button [hidden]="editarDataTU" class='btn-Eliminar btn-ch btnTableUp ml-2' type='button' (click)='exportexcel(2)'>
                                    Exportar
                                </button>
                                <button class='btn-guardar btn-ch btnTableUp ml-2' [hidden]="!editarDataTU" type='button' (click)='updateTipoUnidades()'>
                                    Guardar
                                </button>
                                <button class='btn-red btn-ch btnTableUp ml-2' [hidden]="!editarDataTU" type='button' (click)='editarTU()'>
                                    Cancelar
                                </button>
                            </th>
                        </tr>
                        <tr>
                            <th  width="50%">Tipo</th>
                            <th  width="25%">Capacidad</th>
                            <th  width="25%">Cantidad</th>
                            <th  width="10%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of TipoUnidades" style="cursor: pointer;">
                            <div *ngIf="group.ProspectoTipoUnidadesID > 0; then thenBlock else elseStatement"></div>
                            <ng-template #thenBlock>
                                <td>{{group.Tipo}}</td>
                                <td>{{group.Capacidad}}</td>
                                <td>{{group.Cantidad}}</td>
                                <td>
                                    <button [hidden]="!validateClaim('Carga Prospectos') || Pros.Status >= 3 || (identity.Rol != 2 && Pros.Create_User != identity.UsuarioID)" class="btn btn-eliminar" (click)="deleteTipoUnidades(group.ProspectoTipoUnidadesID)" title="Eliminar">
                                        <fa-icon [icon]="fasTrash"></fa-icon>
                                    </button>
                                </td>
                            </ng-template>
                            <ng-template #elseStatement>
                                <td>
                                    <ng-select [searchable]="true" [(ngModel)]="group.Tipo" placeholder="Seleccionar..." appendTo=".contenedor-principal">
                                        <ng-option [value]="dato.Nombre" *ngFor="let dato of catalog.Unidad;">
                                            {{dato.Nombre}}
                                        </ng-option>
                                    </ng-select>
                                </td>
                                <td>
                                    <ng-select [searchable]="true" [(ngModel)]="group.Capacidad" placeholder="Seleccionar..." appendTo=".contenedor-principal">
                                        <ng-option [value]="dato.Nombre" *ngFor="let dato of catalog.Capacidad;">
                                            {{dato.Nombre}}
                                        </ng-option>
                                    </ng-select>
                                </td>
                                <td><input type="number" class="form-control" [(ngModel)]="group.Cantidad"></td>
                                <td></td>
                            </ng-template>
                        </tr>
                    </tbody>
                </table>
                <table [hidden]="true" class="table table-responsive"  id="tableTipoUnidades"> 
                    <thead>
                        <tr>
                            <th  width="50%">Tipo</th>
                            <th  width="25%">Capacidad</th>
                            <th  width="25%">Cantidad</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of TipoUnidades">
                            <td>{{group.Tipo}}</td>
                            <td>{{group.Capacidad}}</td>
                            <td>{{group.Cantidad}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>             
        </div>

        <!--Seccion Item - Reativos de Prospecto -->
        <ng-container *ngFor="let item of preguntas">
            <div class="row card-body mb-3" style="border-left: 30px solid #ffcc01;">
                <div class="col-md-12">
                    <h3>{{item.Nombre}}</h3>
                    <p>{{item.Descripcion}}</p>
                </div>
            </div>
            
        <div id="SeccionPreguntas">
            <div class="back-cuestionario">
                <ng-container *ngFor="let group of item.reactivos">
                    <div [ngClass]="{'mt-3': group.TipoFK == 7 || group.agrupados == ''}">
                        <div *ngIf="group.TipoFK == 7 || group.agrupados == ''" class="card-header">
                            <p class="mt-3">
                                <label class="react-text" placement="right" [ngbTooltip]="group.Descripcion" [disableTooltip]="group.Descripcion == ' '">{{group.Nombre}}</label>
                                <ng-container *ngIf="group.Opcional;" class="switch-form-base">
                                    <label class="switch">
                                        <input [disabled]="!validateClaim('Carga Prospectos') || Pros.Status >= 3 || (identity.Rol != 2 && Pros.Create_User != identity.UsuarioID)" [(ngModel)]="group.NoAplica" type="checkbox" id="swtNA-{{group.ReactivoItemProspectoID}}" name="swtNA-{{group.ReactivoItemProspectoID}}" (click)="noAplica(group)">
                                        <span class="slider round"></span>
                                    </label>
                                    &nbsp;
                                    <label class="lblSwitch">No Aplica</label>
                                </ng-container>
                            </p>
                        </div>
                        <div *ngIf="group.TipoFK != 7 && !group.NoAplica;" class="card-body">
                            <label *ngIf="group.TipoFK != 7 && group.agrupados != ''" class="react-text" placement="right" [ngbTooltip]="group.Descripcion" [disableTooltip]="group.Descripcion == ' '">{{group.Nombre}}</label>
                            <div *ngIf="group.TipoFK == 1 && !group.NoAplica;">
                                <input [disabled]="!validateClaim('Carga Prospectos') || Pros.Status >= 3 || (identity.Rol != 2 && Pros.Create_User != identity.UsuarioID)" type="text" class="form-control" name="pregunta-{{group.ReactivoItemProspectoID}}" [(ngModel)]="group.Respuesta">
                            </div>
                            <div *ngIf="group.TipoFK == 2 && !group.NoAplica;">
                                <input [disabled]="!validateClaim('Carga Prospectos') || Pros.Status >= 3 || (identity.Rol != 2 && Pros.Create_User != identity.UsuarioID)" type="date" class="form-control" name="pregunta-{{group.ReactivoItemProspectoID}}" [(ngModel)]="group.Respuesta">
                            </div>
                            <div *ngIf="group.TipoFK == 3 && !group.NoAplica;">
                                <button [hidden]="!group.Respuesta || group.Respuesta == '' || !group.Respuesta.includes('back-eutdhl')" class="btn btn-secondary mb-3" (click)="descargar(group.ReactivoItemProspectoID)">DESCARGAR DOCUMENTO</button>
                                <button [hidden]="!group.Respuesta || group.Respuesta == '' || group.Respuesta.includes('back-eutdhl')" class="btn btn-secondary mb-3" (click)="verDoc(group.Respuesta)">DESCARGAR DOCUMENTO</button><br>
                                <input [disabled]="!validateClaim('Carga Prospectos') || Pros.Status >= 3 || (identity.Rol != 2 && Pros.Create_User != identity.UsuarioID)" type="file" name="pregunta-{{group.ReactivoItemProspectoID}}" accept=".pdf" (change)="readUrl($event, group)">
                            </div>
                            <div *ngIf="group.TipoFK == 4 && !group.NoAplica;">
                                <label>Estado</label>
                                <input [disabled]="!validateClaim('Carga Prospectos') || Pros.Status >= 3 || (identity.Rol != 2 && Pros.Create_User != identity.UsuarioID)" type="text" class="form-control" [(ngModel)]="group.Direccion.Estado">
                                <label>Ciudad/Municipio</label>
                                <input [disabled]="!validateClaim('Carga Prospectos') || Pros.Status >= 3 || (identity.Rol != 2 && Pros.Create_User != identity.UsuarioID)" type="text" class="form-control" [(ngModel)]="group.Direccion.Municipio">
                                <label>Código Postal</label>
                                <input [disabled]="!validateClaim('Carga Prospectos') || Pros.Status >= 3 || (identity.Rol != 2 && Pros.Create_User != identity.UsuarioID)" type="number" class="form-control" [(ngModel)]="group.Direccion.CP">
                                <label>Colonia</label>
                                <input [disabled]="!validateClaim('Carga Prospectos') || Pros.Status >= 3 || (identity.Rol != 2 && Pros.Create_User != identity.UsuarioID)" type="text" class="form-control" [(ngModel)]="group.Direccion.Colonia">
                                <label>Calle</label>
                                <input [disabled]="!validateClaim('Carga Prospectos') || Pros.Status >= 3 || (identity.Rol != 2 && Pros.Create_User != identity.UsuarioID)" type="text" class="form-control" [(ngModel)]="group.Direccion.Calle">
                                <label>Num.</label>
                                <input [disabled]="!validateClaim('Carga Prospectos') || Pros.Status >= 3 || (identity.Rol != 2 && Pros.Create_User != identity.UsuarioID)" type="text" class="form-control" [(ngModel)]="group.Direccion.Num">
                            </div>
                            <div *ngIf="group.TipoFK == 5 && !group.NoAplica;">
                                <input [disabled]="!validateClaim('Carga Prospectos') || Pros.Status >= 3 || (identity.Rol != 2 && Pros.Create_User != identity.UsuarioID)" type="radio" name="pregunta-{{group.ReactivoItemProspectoID}}" id="pregunta-si-{{group.ReactivoID}}" value="si" [(ngModel)]="group.Respuesta" style="cursor: pointer;"><label for="pregunta-si-{{group.ReactivoID}}" style="cursor: pointer;">Si &nbsp; &nbsp;</label>
                                <input [disabled]="!validateClaim('Carga Prospectos') || Pros.Status >= 3 || (identity.Rol != 2 && Pros.Create_User != identity.UsuarioID)" type="radio" name="pregunta-{{group.ReactivoItemProspectoID}}" id="pregunta-no-{{group.ReactivoID}}" value="no" [(ngModel)]="group.Respuesta" style="cursor: pointer;"><label for="pregunta-no-{{group.ReactivoID}}" style="cursor: pointer;">No</label>
                            </div>
                            <div *ngIf="group.TipoFK == 6 && !group.NoAplica;">
                                <input [disabled]="!validateClaim('Carga Prospectos') || Pros.Status >= 3 || (identity.Rol != 2 && Pros.Create_User != identity.UsuarioID)" type="email" class="form-control" name="pregunta-{{group.ReactivoItemProspectoID}}" [(ngModel)]="group.Respuesta">
                            </div>
                            <div *ngIf="group.TipoFK == 8 && !group.NoAplica;">
                                <select [disabled]="!validateClaim('Carga Prospectos') || Pros.Status >= 3 || (identity.Rol != 2 && Pros.Create_User != identity.UsuarioID)" name="pregunta-{{group.ReactivoItemProspectoID}}" [(ngModel)]="group.Respuesta" class="form-control">
                                    <option *ngFor="let item of group.Catalogos" [value]="item.ID">{{item.Nombre}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div *ngIf="validateClaim('Carga Prospectos') && Pros.Status < 3 && (identity.Rol == 2 || Pros.Create_User == identity.UsuarioID)" class="mt-3 row col-12">
                <button class="btn-med btn-guardar mb-4" (click)="save()">GUARDAR</button>
            </div>
        </div>
        </ng-container>
    </div>
    
</div>
<!---------------------Seccion Carga Masiva----------------->
<div class="modal fade" id="modalCargar" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': modalCargar ? 'block' : 'none', 'opacity': 1}"> 
    <div class="modal-dialog modal-lg modal-dialog-centered" role="dialog" >
      <div class="modal-content">
        <div class="modal-header mdl-hdr text-center">
          <h5 class="modal-title w-100"></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="openCargaExcel(false)">
            <span aria-hidden="true" class="mdl-close" >&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form #createForm2="ngForm" (ngSubmit)="cargaExcel()" autocomplete="off" appIdentityRevealed>
                <div class="form-group">
                    <input type="file" name="URLPDF" (change)="obtenerExcel($event)">
                </div>
                <div class="div-btns row pt-3">   
                    <div class="col-6">
                      <button class="btn btn-fluid btn-Eliminar btnTableUp" (click)="openCargaExcel(false)" data-dismiss="modal" type="button">CANCELAR</button>            
                    </div>
                    <div class="col-6">     
                      <button class="btn btn-fluid btnTableUp">GUARDAR</button>    
                    </div>
                </div>
            </form>            
        </div>       
      </div>
    </div>
</div>

<div class="modal fade" id="modalPreview" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': modalPreview ? 'block' : 'none', 'opacity': 1}"> 
    <div class="modal-dialog modal-lg modal-dialog-centered" role="dialog" >
      <div class="modal-content">
        <div class="modal-header mdl-hdr text-center">
          <h5 class="modal-title w-100"></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="openModalPreview(false)">
            <span aria-hidden="true" class="mdl-close" >&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <table *ngIf="tipoCM == 1" class="table table-responsive" style="display: table;"> 
                <thead>
                    <tr>
                        <th>Origen</th>
                        <th>Destino</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let group of previewDataRP.content;">
                        <td>{{group.Origen}}</td>
                        <td>{{group.Destino}}</td>
                    </tr>
                </tbody>
            </table>
            <table *ngIf="tipoCM == 2" class="table table-responsive" style="display: table;"> 
                <thead>
                    <tr>
                        <th  width="60%">Tipo</th>
                        <th  width="25%">Capacidad</th>
                        <th  width="25%">Cantidad</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let group of previewDataTU.content;">
                        <td>{{group.Tipo}}</td>
                        <td>{{group.Capacidad}}</td>
                        <td>{{group.Cantidad}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="div-btns row pt-3">   
                <div class="col-6">
                  <button class="btn btn-fluid btn-Eliminar btnTableUp" (click)="openModalPreview(false)" data-dismiss="modal" type="button">CANCELAR</button>            
                </div>
                <div class="col-6">     
                  <button *ngIf="tipoCM == 1" class="btn btn-fluid btnTableUp" (click)="guardarRutasPrincipales()">GUARDAR</button>    
                  <button *ngIf="tipoCM == 2" class="btn btn-fluid btnTableUp" (click)="guardarTipoUnidades()">GUARDAR</button>    
                </div>
            </div>
        </div>       
      </div>
    </div>
</div>

<div class="modal fade" id="modalErrors" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': modalErrors ? 'block' : 'none', 'opacity': 1}"> 
    <div class="modal-dialog modal-lg modal-dialog-centered" role="dialog" >
      <div class="modal-content">
        <div class="modal-header mdl-hdr text-center">
          <h5 class="modal-title w-100"></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="openModalErrors(false)">
            <span aria-hidden="true" class="mdl-close" >&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <table class="table table-responsive"> 
                <thead>
                    <tr>
                        <th width="10%">Celda</th>
                        <th width="100%">Mensaje Error</th>
                    </tr>
                </thead>
                <tbody *ngIf="tipoCM == 1">
                    <tr *ngFor="let group of previewDataRP.errors;">
                        <td>{{group.Celda}}</td>
                        <td>{{group.Error}}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="tipoCM == 2">
                    <tr *ngFor="let group of previewDataTU.errors;">
                        <td>{{group.Celda}}</td>
                        <td>{{group.Error}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="div-btns row pt-3">   
                <div class="col-12">
                  <button class="btn btn-fluid btn-Eliminar btnTableUp" (click)="openModalErrors(false)" data-dismiss="modal" type="button">CERRAR</button>            
                </div>
            </div>
        </div>       
      </div>
    </div>
</div>
<!---------------------------------------------------------->
<app-loading-efect></app-loading-efect>