import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ItemService } from 'src/app/services/items/item.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';

@Component({
  selector: 'app-practica',
  templateUrl: './practica.component.html',
  styleUrls: ['./practica.component.css']
})
export class PracticaComponent implements OnInit {
  loading = new LoadingEfectComponent();
  searchText;
  public obj:any = {};
  public catalog:any[];
  public mdl : boolean = false;
  identity = JSON.parse(localStorage.getItem('identity_user'));

  constructor(
    private _service:ItemService,
    private router:Router
  ) { }
    
ngOnInit() {
  this.verifyClaims();
  this.getGroup();
  this.loading.onDoneRequest();
}

verifyClaims(){
  var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Configuraciones');
  console.log(claim);
  if(claim == undefined && claim == null){
    this.router.navigate(['/sa-rm/admin']);
  }
  this.identity.permisoLists
}

public getGroup(){    
  this.loading.onLoadingRequest();
  this._service.getGroup()
  .then(response=>{
    this.loading.onDoneRequest();
    if(response.response.status == 200){
      if(response.content != null && response.content.length > 0){
        this.catalog = response.content;
      }
    }else if(response.response.status == 500){
      Swal.fire("Error",response.message,"error");
    }
  })
  .catch(error=>{
    this.loading.onDoneRequest();
    console.log(error.toString());
  });
}

public open(open : boolean, id: number) : void {
  this.obj.Nombre = "";
  this.obj.Color = "";
  this.obj.Icono = "";
  this.obj.id = id;
  if(open){     
    $(".container").css('opacity',0.5);
    $("#sidebar-wrapper").css('opacity',0.5);
    $(".modal-title").html("CREAR GRUPO");
    if(id != 0){
      $(".modal-title").html("EDITAR GRUPO");
      let catAct = this.catalog.find(cat => cat.ID === id);
      console.log(catAct)
      this.obj.Nombre = catAct.Nombre;
      this.obj.Color = catAct.Color;
      this.obj.Icono = catAct.Icono;
    }
  }else{      
    $(".container").css('opacity',1); 
    $("#sidebar-wrapper").css('opacity',1);      
  }
  this.mdl = open;
}

update(){
  this.loading.onLoadingRequest();
  this._service.updateGroup(this.obj)
  .then(response=>{
    this.loading.onDoneRequest();
    if(response.status == 200){
      Swal.fire('Éxito', response.message, 'success');
      this.open(false, 0);
      this.getGroup();
    }else if (response.status == 500){      
      Swal.fire('Error', response.message, 'error');
    }else {
      Swal.fire("Atención",response.message,"info");
    }
  }).catch(error=>{ 
    this.loading.onDoneRequest();     
    Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
  })
}

delete(id: number){
  Swal.fire({
    title: 'Atención',
    text: "¿Estas seguro que deseas eliminar el registro?",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Aceptar',
    cancelButtonText: 'Cancelar'
  }).then((result) => {
    if (result.isConfirmed) {
      this.loading.onLoadingRequest();
      this._service.deleteGroup(id)
      .then(response=>{
        this.loading.onDoneRequest();
        if(response.status == 200){
          Swal.fire('Éxito', response.message, 'success');
          this.open(false, 0);        
        
          this.getGroup();
        }else{
          Swal.fire('Error', response.message, 'error');
        }
      }).catch(error=>{  
        this.loading.onDoneRequest();    
        Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
      })
    }
  })
}

}