<div id="site_wrapper">
    <div class="container">
        <div class="wrapper">
            <h2>Configuraciones</h2>
            <div class="row" style="margin-top: 3%;">
                <div class="col-sm-12 col-md-6 col-lg-4">
                    <div class="chooser-item">
                        <div class="content-special">
                            <h3>ITEMS</h3>
                            <button (click)="goToItems()">LISTA</button>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4">
                    <div class="chooser-item">
                        <div class="content-special">
                            <h3>USUARIOS</h3>
                            <button (click)="goToUsuarios()">LISTA USUARIOS</button>
                            <button (click)="goToRoles()">ROLES Y PERMISOS</button>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                    <div class="chooser-item">
                        <div class="content-special">
                            <h3>CATÁLOGOS</h3>
                            <div class="row">
                                <button (click)="goToGrupos()">GRUPOS</button>
                                <button (click)="goToArea()">ÁREAS</button>
                                <button (click)="goToGeneral()">GENERALES</button>
                                <button (click)="goToTP()">TIPOS DE CONTRATOS</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>