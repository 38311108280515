<div class="back-cuestionario">
    <h3>{{objPreview.Nombre}}</h3>
    <p>{{objPreview.Descripcion}}</p>
</div>
<div id="SeccionPreguntas">
    <div class="back-cuestionario" style="padding-left: 2rem;">
        <div *ngFor="let group of preguntas" class="mt-4 mb-4" style="border-top: 1px solid #495057;">
            <p class="mt-3">{{group.Nombre}} <a *ngIf="group.Mandatorio != 'Deseable';" style="color: red;">* Mandatorio {{group.Mandatorio}}</a></p>
            <label>{{group.Descripcion}}</label>
            <div class="switch-form-base" *ngIf="group.Opcional;">
                 <label class="switch">
                     <input [(ngModel)]="group.NoAplica" type="checkbox" id="swtNA-{{group.ReactivoID}}" name="swtNA-{{group.ReactivoID}}">
                     <span class="slider round"></span>
                 </label>
                 &nbsp;
                 <label class="lblSwitch">No Aplica</label>
            </div>
            <div *ngIf="group.Vencimiento && !group.NoAplica;">
                <label>Fecha de Vencimiento</label>
                <input type="date" class="form-control mb-3" name="pregunta-{{group.ReactivoID}}">
            </div>
            <div *ngIf="group.TipoFK == 1 && !group.NoAplica;">
                <input type="text" class="form-control" name="pregunta-{{group.ReactivoID}}">
            </div>
            <div *ngIf="group.TipoFK == 2 && !group.NoAplica;">
                <input type="date" class="form-control" name="pregunta-{{group.ReactivoID}}">
            </div>
            <div *ngIf="group.TipoFK == 3 && !group.NoAplica;">
                <input type="file" name="pregunta-{{group.ReactivoID}}">
            </div>
            <div *ngIf="group.TipoFK == 4 && !group.NoAplica;">
                <label>Estado</label>
                <input type="text" class="form-control">
                <label>Ciudad/Municipio</label>
                <input type="text" class="form-control">
                <label>Código Postal</label>
                <input type="number" class="form-control">
                <label>Colonia</label>
                <input type="text" class="form-control">
                <label>Calle</label>
                <input type="text" class="form-control">
                <label>Num.</label>
                <input type="text" class="form-control">
            </div>
            <div *ngIf="group.TipoFK == 5 && !group.NoAplica;">
                <input type="radio" name="pregunta-{{group.ReactivoID}}" id="pregunta-si-{{group.ReactivoID}}" value="si"><label for="pregunta-si-{{group.ReactivoID}}">Si &nbsp; &nbsp;</label>
                <input type="radio" name="pregunta-{{group.ReactivoID}}" id="pregunta-no-{{group.ReactivoID}}" value="no"><label for="pregunta-no-{{group.ReactivoID}}">No</label>
            </div>
            <div *ngIf="group.TipoFK == 6 && !group.NoAplica;">
                <input type="email" class="form-control" name="pregunta-{{group.ReactivoID}}">
            </div>
            <div *ngIf="group.TipoFK == 8 && !group.NoAplica;">
                <select name="pregunta-{{group.ReactivoID}}" class="form-control">
                    <option value="0">Seleccionar...</option>
                    <option *ngFor="let item of group.Catalogos" [value]="item.ID">{{item.Nombre}}</option>
                </select>
            </div>
            <div *ngIf="group.Multiple && !group.NoAplica;">
                <button class="btn-Anadir btn-fluid mb-3 mt-3" (click)="anadirReact(group.ReactivoID)">AGREGAR</button>
            </div>
        </div>
    </div>
    <button class="btn-layout btn-med float-right btn-guardar mb-4">GUARDAR</button><br><br>
</div>