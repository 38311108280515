<div class="navbar navbar-expand-lg navbar-light bg-blue header-sitio row">
  <div *ngIf="asistenciasMin > 0" class="nav-item dropleft" style="margin-left: auto;">
    <button class="btn btn-warning dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <fa-icon [icon]="faTicketAlt"></fa-icon>
      <span class="badge badge-pill badge-danger ml-2">{{asistenciasMin}}</span>
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <table class="table table-responsive"> 
          <tbody>
              <tr *ngFor="let group of catalog3" (click)="modalTicket(true, group.AsistenciaID)" style="cursor: pointer;">
                  <td>{{group.AsistenciaID}}</td>
                  <td>{{group.Item}}</td>
                  <td>{{group.Reactivo}}</td>
                  <td>{{group.NumProveedor}}</td>
                  <td>{{group.NombreEmpresa}}</td>
              </tr>
          </tbody>
      </table>
    </div>
  </div>
  <a class="btn-md btnLogOut pl-3 pr-3" [ngStyle]="{'margin-left': asistenciasMin > 0 ? '0' : 'auto'}" (click)="logout()">
    <fa-icon [icon]="fasSignOut"></fa-icon>
    <span>     Cerrar Sesión</span>
  </a>
</div>


<div class="modal fade" id="mdlTicket" tabindex="-1" role="dialog" aria-hidden="true" (click)="minTicketClickOut($event)" [ngStyle]="{'display': mdlTicket ? 'block' : 'none', 'opacity': 1}"> 
  <div class="modal-dialog modal-xl modal-dialog-centered" role="dialog" style="margin-left: 63%; min-height: 103%; max-width: 100%;">
    <div class="modal-content" style="position: fixed; bottom:0;">
      <div class="modal-header mdl-hdr text-center">
        <h5 class="modal-title w-100">TICKET</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Minimizar" (click)="minTicket(true)" style="line-height: .5;">
          <span aria-hidden="true" class="mdl-close" >-</span>
        </button>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalTicket(false,0)" style="line-height: .5;">
          <span aria-hidden="true" class="mdl-close" >&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <div class="row principal">
              <div class="col-12">
                  <label style="font-weight: bold; color: #ffcc01;">ITEM:</label><span>   {{asistencia.Item}}</span>
              </div>
              <div class="col-12">
                  <label style="font-weight: bold; color: #ffcc01;">REACTIVO:</label><span>   {{asistencia.Reactivo}}</span>
              </div>
              <div class="col-12">
                  <label style="font-weight: bold; color: #ffcc01;">Nombre Empresa:</label><span>   {{asistencia.NombreEmpresa}}</span>
              </div>
              <div class="col-12" *ngFor="let group of asistencia.Comentarios" [ngStyle]="{'background-color': group.Color}">
                  <label style="font-weight: bold;">{{group.NombreEmisor}}</label><br>
                  <label>{{group.Comentario}}</label>
              </div>
              <div class="col-12 pt-3">
                  <label>Mensaje</label>
                  <textarea [(ngModel)]="asistenciaREQ.Comentario" class="form-control"></textarea>
              </div>
          </div>
          <div class="div-btns mt-4">
              <button class="btn-layout btn-med btnTableUp mt-3 float-right ml-1" (click)="crearTicket(3)" [hidden]="asistencia.Status == 3 || identity.empresa">CERRAR TICKET</button>  
              <button class="btn-layout btn-med btnTableUp mt-3 float-right ml-1" (click)="crearTicket(2)" [hidden]="asistencia.Status == 3">ENVIAR</button>  
              <button class="btn-layout btn-med btnTableUp mt-3 float-right ml-1" (click)="openPreviewItem(asistencia.ItemEmpresaID)" [hidden]="asistencia.ItemEmpresaID == 0">REVISAR REACTIVO</button>  
          </div>    
      </div>       
    </div>
  </div>
</div>

<router-outlet></router-outlet>

<app-loading-efect></app-loading-efect>