import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-efect',
  templateUrl: './loading-efect.component.html',
  styleUrls: ['./loading-efect.component.css']
})
export class LoadingEfectComponent{

  onLoadingRequest(){
    var loading = document.getElementById("loadingDiv");
    loading.style.display = "block";
  }

  onDoneRequest(){
    var loading = document.getElementById("loadingDiv");
    loading.style.display = "none";
  }  

}
