<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row">
            <div class="col-sm-12 btn-regresar-2">
              <a (click)="regresar()" style="cursor: pointer;" class="float-left link-regresar"><img src="../../../assets/IMG/arrow_back.png" /></a>
            </div>
        </div>
        <div class="row mapa-sitio">
            <div class="col-9">
                <h2>{{item.Nombre}}</h2> 
            </div>
        </div>
        <div class="row tabla-catalogo">  
            <div class="col-12">
                <div class="row pb-4">
                    <div class="col-sm-7 col-md-8 col-lg-9 col-xl-10">
                        <input type="text" [(ngModel)]="searchText" class="form-control buscador" placeholder="Buscador">
                    </div>
                    <div class="col-sm-5 col-md-4 col-lg-3 col-xl-2" [hidden]="!item.Multiple">
                        <button class='btn-layout btn-fluid float-right btnTableUp' type='button' style='margin-left:10px;' (click)="multiple()">
                            AGREGAR
                        </button>
                    </div>
                </div>
                <table class="table table-responsive"> 
                    <thead>
                    <tr>
                        <th  width="100%">Item</th>
                        <th  width="20%">Disponibilidad</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of cuestionario | catalog: searchText;" (click)="verCuestionario(group.ItemEmpresaID)" style="cursor: pointer;">
                            <td>{{group.Nombre}}</td>
                            <td>{{group.Disponibilidad | date:'dd/MM/yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>             
        </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>