import { Component, OnInit } from '@angular/core';
import { NoticiaService } from 'src/app/services/noticia/noticia.service';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { GLOBAL } from './../../../../services/global';

@Component({
  selector: 'app-update-noticias',
  templateUrl: './update-noticias.component.html',
  styleUrls: ['./update-noticias.component.css']
})
export class UpdateNoticiasComponent implements OnInit {
  loading = new LoadingEfectComponent();
  public catalog:any[];
  public texto:string = "";
  public noticia:any = {
    NoticiaID:0,
    AreaFK:0,
    Titulo:"",
    Descripcion:"",
    Cuerpo:"",
    Marcar:false,
    Vencimiento:null,
    SinVencimiento:false,
    Archivos:[]
  };
  //Config Editor HTML
  config: AngularEditorConfig = {
    sanitize: false,
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['insertImage']
      ]
  };
  files: any[] = [];

  identity = JSON.parse(localStorage.getItem('identity_user'));
  public id = +this._route.snapshot.paramMap.get('id');

  constructor(
    private _service:NoticiaService,
    private _serviceUsuarios:UsuariosService,
    public router: Router,
    private _route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getAreas();
    this._route.params.subscribe(
        params => {
            this.id = params['id'];
            if(this.id == 0){
              this.texto = "Nueva Noticia";
            }else{
              this.texto = "Editar Noticia";
              this.getNoticia();
            }
        }
    );
  }

  public getNoticia(){
    this._service.getNoticiasByUser(this.id).then(response=>{
      this.noticia = response.content[0];
      console.log(this.noticia)
    });
  }

  public getAreas(){    
    this.loading.onLoadingRequest();
    this._serviceUsuarios.getAreas()
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.catalog = response.content;
          console.log(this.catalog)
        }
      }else if(response.response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public saveNoticia(){
    this.loading.onLoadingRequest();
    if(this.files.length > 0){
      let formdata = new FormData();
      for (let i = 0; i< this.files.length; i++){
        formdata.append("noticia["+ i +"]", this.files[i], this.files[i].name);
        this.noticia.Archivos.push({
          ContentLenght: this.files[i].size,
          ContentType: this.files[i].type,
          FileName: this.files[i].name,
          FilePath: "Resources/NoticiaFiles/",
          MeasureUnit: "Bytes",
          Server: GLOBAL.urlIMG,
          Url: GLOBAL.urlIMG + "Resources/NoticiaFiles/" + this.files[i].name
        });
      }
  
      this._service.uploadFile(formdata).then((res)=>{
        this.updateNoticia();
      });
    }else{
      this.updateNoticia();
    }
  }

  public updateNoticia(){
      this._service.updateNoticia(this.noticia)
      .then(response=>{
        this.loading.onDoneRequest();
        if(response.status == 200){
          this.router.navigate(['/sa-rm/admin/config-noticias']);
        }else{      
          Swal.fire('Error', response.message, 'error');
        }
      }).catch(error=>{ 
        this.loading.onDoneRequest();     
        Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
      })
  }

  delete(id:number){
    this.noticia.Archivos = this.noticia.Archivos.filter(({ NoticiaArchivoID }) => NoticiaArchivoID !== id); 
  }

  //on file drop handler
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  //handle file from browsing
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  //Delete file from files list
  deleteFile(index: number) {
    this.files.splice(index, 1);
    console.log(this.files);
  }

  //Simulate the upload process
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  //Convert Files list to normal array list
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    console.log(this.files);
    this.uploadFilesSimulator(0);
  }

  //format bytes
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
