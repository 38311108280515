<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row mapa-sitio">
            <div class="col-12">
                <h2>TARIFAS</h2> 
            </div>
        </div>
        <div class="row filtros">
            <div class="col-sm-12 col-md-6 col-lg-3">
                <label>NUM. PROVEEDOR (SPN)</label>
                <select [(ngModel)]="filter.spn" class="form-control" name="spn">
                    <option *ngFor="let numSPN of spn;" value="{{numSPN}}">{{numSPN}}</option>
                </select>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <button class='btn btn-danger btn-fluid' type='button' style='margin-top: 31px;' (click)="getRegistros()">
                    FILTRAR
                </button>
            </div>
        </div>
        <div class="row tabla-catalogo">  
            <div class="col-sm-12 col-md-6 col-lg-3">
                <button class='btn btn-warning btn-fluid' type='button' style='margin-top: 31px;'  (click)="exportexcel()">
                    EXPORTAR
                </button>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <input type="text" [(ngModel)]="searchText" class="form-control buscador" placeholder="Buscador">
                </div>
                <table class="table table-responsive"> 
                    <thead>
                        <tr>
                            <th>SPN</th>
                            <th>RATE_OFFERING_GID</th>
                            <th>RATE_GEO_GID</th>
                            <th>RATE_VERSION_GID</th>
                            <th>SERVPROV_GID</th>
                            <th>IS_ACTIVE</th>
                            <th>EXPIRATION_DATE</th>
                            <th>X_LANE_GID</th>
                            <th>SOURCE_REGION_GID</th>
                            <th>DEST_REGION_GID</th>
                            <th>RATE_GEO_COST_GROUP_GID</th>
                            <th>CHARGE_AMOUNT</th>
                            <th>INSERT_USER (OTM)</th>
                            <th>INSERT_DATE (OTM)</th>
                            <th>UPDATE_USER (OTM)</th>
                            <th>UPDATE_DATE (OTM)</th>
                            <th>INSERT_USER</th>
                            <th>INSERT_DATE</th>
                            <th>UPDATE_USER</th>
                            <th>UPDATE_DATE</th>
                            <th>STATUS</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of datos | tarifas: searchText;">
                            <td>{{group.SPN}}</td>
                            <td>{{group.RATE_OFFERING_GID}}</td>
                            <td>{{group.RATE_GEO_GID}}</td>
                            <td>{{group.RATE_VERSION_GID}}</td>
                            <td>{{group.SERVPROV_GID}}</td>
                            <td>{{group.IS_ACTIVE}}</td>
                            <td>{{group.EXPIRATION_DATE | date:'dd/MM/yyyy'}}</td>
                            <td>{{group.X_LANE_GID}}</td>
                            <td>{{group.SOURCE_REGION_GID}}</td>
                            <td>{{group.DEST_REGION_GID}}</td>
                            <td>{{group.RATE_GEO_COST_GROUP_GID}}</td>
                            <td>{{group.CHARGE_AMOUNT}}</td>
                            <td>{{group.INSERT_USER_OTM}}</td>
                            <td>{{group.INSERT_DATE_OTM | date:'dd/MM/yyyy'}}</td>
                            <td>{{group.UPDATE_USER_OTM}}</td>
                            <td>{{group.UPDATE_DATE_OTM | date:'dd/MM/yyyy'}}</td>
                            <td>{{group.INSERT_USER_NAME}}</td>
                            <td>{{group.INSERT_DATE | date:'dd/MM/yyyy'}}</td>
                            <td>{{group.UPDATE_USER_NAME}}</td>
                            <td>{{group.UPDATE_DATE | date:'dd/MM/yyyy'}}</td>
                            <td *ngIf="group.Status == 1">Pendiente por aprobar</td>
                            <td *ngIf="group.Status == 2" style="color: green;">Aceptada</td>
                            <td *ngIf="group.Status == 3" style="color: red;">Rechazada</td>
                            <td>
                                <button *ngIf="group.Status == 1" class="btn btn-success btn-ch" (click)="cambioStatus(group.EmpresaTarifaID,true)">Aceptar</button>
                            </td>       
                            <td>
                                <button *ngIf="group.Status == 1" class="btn btn-danger btn-ch" (click)="cambioStatus(group.EmpresaTarifaID,false)">Rechazar</button>
                            </td>       
                        </tr>
                    </tbody>
                </table>
                <table [hidden]="true" class="table table-responsive"  id="tableEmpresas"> 
                    <thead>
                        <tr>
                            <th>RATE_OFFERING_GID</th>
                            <th>RATE_GEO_GID</th>
                            <th>RATE_VERSION_GID</th>
                            <th>SERVPROV_GID</th>
                            <th>IS_ACTIVE</th>
                            <th>EXPIRATION_DATE</th>
                            <th>X_LANE_GID</th>
                            <th>SOURCE_REGION_GID</th>
                            <th>DEST_REGION_GID</th>
                            <th>RATE_GEO_COST_GROUP_GID</th>
                            <th>CHARGE_AMOUNT</th>
                            <th>INSERT_USER</th>
                            <th>INSERT_DATE</th>
                            <th>UPDATE_USER</th>
                            <th>UPDATE_DATE</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of datos | tarifas: searchText;">
                            <td>{{group.RATE_OFFERING_GID}}</td>
                            <td>{{group.RATE_GEO_GID}}</td>
                            <td>{{group.RATE_VERSION_GID}}</td>
                            <td>{{group.SERVPROV_GID}}</td>
                            <td>{{group.IS_ACTIVE}}</td>
                            <td>{{group.EXPIRATION_DATE | date:'dd/MM/yyyy'}}</td>
                            <td>{{group.X_LANE_GID}}</td>
                            <td>{{group.SOURCE_REGION_GID}}</td>
                            <td>{{group.DEST_REGION_GID}}</td>
                            <td>{{group.RATE_GEO_COST_GROUP_GID}}</td>
                            <td>{{group.CHARGE_AMOUNT}}</td>
                            <td>{{group.INSERT_USER_OTM}}</td>
                            <td>{{group.INSERT_DATE_OTM | date:'dd/MM/yyyy'}}</td>
                            <td>{{group.UPDATE_USER_OTM}}</td>
                            <td>{{group.UPDATE_DATE_OTM | date:'dd/MM/yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>