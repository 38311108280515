<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row mapa-sitio">
            <div class="col-12">
                <h2>Código de Conducta</h2> 
            </div>
        </div>
        <div class="row filtros">
            <div class="col-sm-12 col-md-6 col-lg-3">
                <label>ACTIVOS/INACTIVOS</label>
                <select [(ngModel)]="filter.eliminado" class="form-control">
                    <option value="2">TODOS</option>
                    <option value="0">ACTIVOS</option>
                    <option value="1">INACTIVOS</option>
                </select>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <label>TIPO DE SERVICIO</label>
                <select [(ngModel)]="filter.extranjera" class="form-control">
                    <option value="-1">TODAS</option>
                    <option value="0">NACIONAL</option>
                    <option value="1">EXTRANJERA</option>
                    <option value="2">AEROLINEA</option>
                </select>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <button class='btn btn-danger btn-fluid' type='button' style='margin-top: 31px;' (click)="getRegistros()">
                    Filtrar
                </button>
            </div>
        </div>
        
        <div class="row tabla-catalogo">  
            <div class="col-12">
                <table class="table table-responsive table-sm table-hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"> 
                    <thead>
                        <tr>
                            <th width="30%">Línea Transportista</th>
                            <th width="20%">SPN</th>
                            <th width="30%">Código de Conducta</th>
                            <th width="30%">Fecha de Celebración</th>
                            <th width="30%">Número Versiones Disponibles</th>
                            <th width="30%">ACTIVOS/INACTIVOS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of datos;">
                            <td>{{group.Nombre}}</td>
                            <td>{{group.SPN}}</td>
                            <td>{{group.CodigoConducta}}</td>
                            <td>{{group.FechaCelebracion}}</td>
                            <td>{{group.Versiones}}</td>
                            <td>{{group.EliminadoValue}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>             
        </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>