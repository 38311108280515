import { Injectable } from '@angular/core';
import { GLOBAL } from './../global';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReclamoService {
  private url:string;
  userToken = localStorage.getItem('token').slice(1).slice(0,-1);

  constructor(private _http:HttpClient) {
    this.url = GLOBAL.url + 'Reclamos';
  }

  getReclamo(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getReclamo?reclamoID=' + obj.reclamoID 
    + '&CV=' + obj.CV
    + '&fechaSiniestro=' + obj.fechaSiniestro
    + '&fechaRegistro=' + obj.fechaRegistro
    + '&statusCarga=' + obj.statusCarga
    + '&statusCliente=' + obj.statusCliente
    + '&statusSeguridad=' + obj.statusSeguridad
    + '&CUENTA=' + obj.CUENTA
    + '&SECTOR=' + obj.SECTOR
    + '&LT=' + obj.LT
    ,{headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getReclamoPendiente(){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getReclamoPendiente', {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getReclamoDoc(ReclamoID:number, RolID:number, Status:number ){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getReclamoDoc?ReclamoID=' + ReclamoID 
    + '&RolID=' + RolID
    + '&Status=' + Status
    , {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getReclamoAlert(flagActivo:boolean){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getReclamoAlert?flagActivo=' + flagActivo, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  createReclamo(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.post(this.url + '/createReclamo', obj, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  updateReclamo(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.post(this.url + '/updateReclamo', obj, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  deleteReclamo(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.delete(this.url + '/deleteReclamo?id=' + id, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }
  
  enviarCorreoLT(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.post(this.url + '/enviarCorreoLT', obj, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  uploadFile(formdata){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.post(GLOBAL.url + 'Values', formdata, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }
}