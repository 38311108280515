import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { CodigoQRService } from 'src/app/services/codigoQR/codigo-qr.service';
import Swal from 'sweetalert2';
import { GLOBAL } from './../../services/global';

@Component({
  selector: 'app-infomultiple-qr',
  templateUrl: './infomultiple-qr.component.html',
  styleUrls: ['./infomultiple-qr.component.css']
})
export class InfomultipleQRComponent implements OnInit {
  public password:string = "";
  public btnContinuar:boolean = false;
  public ItemEmpresaID = +this._route.snapshot.paramMap.get('id').split(",")[0];
  public CodigoQRID = +this._route.snapshot.paramMap.get('id').split(",")[1];
  public catalog:any;
  public nombre:string = "";
  public NombreEmpresa:string = "";
  public info:any[];

  constructor(
    private _service: CodigoQRService,
    public router: Router,
    private _route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getCodes();
  }

  public getCodes(){ 
    this._service.getCodigoqrInfoMultiple(this.ItemEmpresaID, this.CodigoQRID)
    .then(response=>{
      if(response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.catalog = response;
          console.log(this.catalog);
        }
        else{
          Swal.fire("Atención","No hay datos que mostrar.","info");
        }
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      console.log(error.toString());
    });
  }

  continuar(flag:boolean){
    if(this.password == ""){
      Swal.fire("Atención","Debes ingresar tu password de acceso para poder continuar","warning");
      return false;
    }else if(this.password != this.catalog.Password){
      Swal.fire("Atención","El password de acceso que ingresaste es incorrecto","warning");
      return false;
    }
    else{
      this.NombreEmpresa = this.catalog.NombreEmpresa;
      this.nombre = this.catalog.Nombre;
      this.info = this.catalog.content.map((x) =>
      {
        if(x.TipoFK == 4 && x.Respuesta){
          x.Direccion = JSON.parse(x.Respuesta);
        }else{
          let Res = '{"Estado": "", "Municipio": "", "CP": 0, "Colonia": "", "Calle": "", "Num": ""}';
          x.Direccion = JSON.parse(Res);
        }
        return x
      });
    }
    this.btnContinuar = flag;
  }

  descargar(id:number){
    window.open(GLOBAL.urlIMG + '/General/DownloadPdf?documentoId=' + id);
  }

  verDoc(id:string){
    window.open(id);
  }
}
