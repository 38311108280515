import { Component, OnInit} from '@angular/core';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { GLOBAL } from './../../../services/global';

@Component({
  selector: 'app-tipo-unidad',
  templateUrl: './tipo-unidad.component.html',
  styleUrls: ['./tipo-unidad.component.css']
})
export class TipoUnidadComponent implements OnInit {
  identity = JSON.parse(localStorage.getItem('identity_user'));
  loading = new LoadingEfectComponent();

  public filter:any = {
    status: -1,
    extranjera: 2
  };

  constructor() { }

  ngOnInit(): void {
  }

  getRegistros(){
    window.open(GLOBAL.urlIMG + 'Excel/Reporte_TipoUnidad?status=' + this.filter.status
    + '&extranjera=' + this.filter.extranjera);
  }
}
