<div id="site_wrapper">
  <div class="contenedor-principal">
    <div class="row mapa-sitio">
      <div class="col-sm-12 col-md-9">
          <div>
              <a class="lbl-noActive" href="/#/sa-rm/admin/config">Configuraciones > </a><a href="/#/sa-rm/admin/config" class="lbl-noActive">&nbsp;Catálogos > </a><label class="lbl-active">&nbsp;Grupos</label>
          </div>
          <h2>Grupos</h2> 
      </div>
    </div>
    <div class="row principal">
        <div class="col-sm-7 col-md-8 col-lg-9 col-xl-10">
          <a href="/#/sa-rm/admin/config" class="float-left link-regresar" ><img src="../../../assets/IMG/arrow_back.png" /></a>
          <input type="text" [(ngModel)]="searchText" class="buscador form-control" placeholder="Buscador">
        </div>
        <div class="col-sm-5 col-md-4 col-lg-3 col-xl-2">
            <button class='btn-layout btn-fluid float-right btnTableUp' (click)='open(true, 0)' type='button' style='margin-left:10px;'>
                CREAR NUEVO
            </button>
        </div>
    </div>
    <div class="row tabla-catalogo">  
        <div class="col-12">
            <table class="table table-responsive"> 
                <thead>
                <tr>
                    <th width="50%">GRUPO</th>
                    <th width="50%">FECHA DE CREACIÓN</th>
                    <th width="15%" class="no-sort"></th>
                    <th width="15%" class="no-sort"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let group of catalog | catalog: searchText; ">
                    <td width="40%">{{group.Nombre}}</td>
                    <td width="30%">{{group.FechaCreacion | date:'dd/MM/yyyy'}}</td>
                    <td>
                        <button class="btn btn-layout btn-ch btnTableUp" (click)='open(true, group.ID)'>Editar</button>
                    </td>
                    <td>
                        <button class="btn btn-Eliminar btn-ch btnTableUp" (click)="delete(group.ID)">Eliminar</button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>             
    </div>
  </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': mdl ? 'block' : 'none', 'opacity': 1}"> 
  <div class="modal-dialog modal-lg modal-dialog-centered" role="dialog" >
    <div class="modal-content">
      <div class="modal-header mdl-hdr text-center">
        <h5 class="modal-title w-100"></h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="open(false, 0)">
          <span aria-hidden="true" class="mdl-close" >&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <form #createForm="ngForm" (ngSubmit)="update()" autocomplete="off" appIdentityRevealed>
              <div class="form-group">
                  <label for="Nombre">NOMBRE</label>
                  <input type="text" class="form-control" #Nombre="ngModel" name="Nombre" [(ngModel)]="obj.Nombre" placeholder="Ingresa texto"  required />
                  <div *ngIf="Nombre.invalid"
                      class="lbl-error">
                      <span *ngIf="Nombre.errors.required">
                          El nombre es requerido
                      </span>            
                  </div>
              </div>
              <div class="form-group">
                <label for="Color">COLOR</label>
                <input type="color" class="form-control" #Color="ngModel" name="Color" [(ngModel)]="obj.Color" placeholder="Ingresa texto"  required />
                <div *ngIf="Color.invalid"
                    class="lbl-error">
                    <span *ngIf="Color.errors.required">
                        El color es requerido
                    </span>            
                </div>
              </div>
              <div class="div-btns row pt-3">   
                  <div class="col-6">
                    <button class="btn btn-fluid btn-Eliminar btnTableUp" (click)="open(false, 0)" data-dismiss="modal" type="button">CANCELAR</button>            
                  </div>
                  <div class="col-6">     
                    <button class="btn btn-fluid btn-layout btnTableUp" [disabled]="createForm.invalid">GUARDAR</button>    
                  </div>
              </div>
          </form>            
      </div>       
    </div>
  </div>
</div>

<app-loading-efect></app-loading-efect>