import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reclamo-ft',
  templateUrl: './reclamo-ft.component.html',
  styleUrls: ['./reclamo-ft.component.css']
})
export class ReclamoFtComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
