import { Component, OnInit } from '@angular/core';
import { ItemService } from 'src/app/services/items/item.service';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { 
  faCheckCircle  as faCheckCircle 
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css']
})
export class ItemsComponent implements OnInit {
  faCheckCircle = faCheckCircle;
  identity = JSON.parse(localStorage.getItem('identity_user'));
  loading = new LoadingEfectComponent();
  searchText;
  public objPreview:any = {};
  public mdlPreview:boolean = false;
  public catalog:any[];

  constructor(
    private _serviceCliente:ClientesService,
    private _service:ItemService,
    private router:Router
  ) { }

  ngOnInit() {
    this.verifyClaims();
    this.getItem();
    this.loading.onDoneRequest();
  }

  verifyClaims(){
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Configuraciones');
    if(claim == undefined && claim == null){
      this.router.navigate(['/sa-rm/admin']);
    }
    this.identity.permisoLists
  }
  
  public getItem(){    
    this.loading.onLoadingRequest();
    this._service.getItem(0)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.catalog = response.content.map((x) =>
          {
            const factor = 10 ** 2;
            x.Calificacion = Math.round(x.Calificacion * factor)/ factor;
            return x
          });
          
          console.log(this.catalog);
        }
        else{
          Swal.fire("Atención","No hay items que mostrar.","info");
        }
      }else if(response.response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  delete(id: number){
    Swal.fire({
      title: 'Atención',
      text: "¿Estas seguro que deseas eliminar el item?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading.onLoadingRequest();
        this._service.deleteItem(id)
        .then(response=>{
          this.loading.onDoneRequest();
          if(response.status == 200){
            Swal.fire('Éxito', response.message, 'success');
          
            this.getItem();
          }else{
            Swal.fire('Error', response.message, 'error');
          }
        }).catch(error=>{  
          this.loading.onDoneRequest();    
          Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
        })
      }
    })
  }

  public update(id: number){
    this.router.navigate(['/sa-rm/updateitem/' + id.toString()]);
  }

  public preview(id: number){
    if(id === 0){
      this.mdlPreview = false;
    }else{
      let act = this.catalog.find(x => x.ItemID === id);
      this.objPreview = {};
      this.objPreview.ItemID = act.ItemID;
      this.objPreview.Nombre = act.Nombre;
      this.objPreview.Descripcion = act.Descripcion;
      
      this.mdlPreview = true;
    }
  }

  public newVersion(id: number){
    this.loading.onLoadingRequest();
    this._service.newVersion(id)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        this.router.navigate(['/sa-rm/updateitem/' + response.message.toString()]);
      }else if (response.status == 500){      
        Swal.fire('Error', response.message, 'error');
      }else {
        Swal.fire("Atención",response.message,"info");
      }
    }).catch(error=>{ 
      this.loading.onDoneRequest();     
      Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
    })
  }

  mdlMasiveAsign = false;
  itemID = 0;
  public masiveAsign(id: number){
    this.itemID = id;
    if(id === 0){
      this.mdlMasiveAsign = false;
    }else{
      this.mdlMasiveAsign = true;
      if(!this.catalogEmpComplete){
        this.getRegistros();
      }
    }
  }
  
  public flagSelectAll = false;
  public selectAll(){
    this.catalogEmp.map((x)=>{
      x.Checked = this.flagSelectAll;
      return x
    });
  }
  public selectItem(){
    if(this.catalogEmp.filter(x=> x.Checked).length == this.catalogEmp.length){
      this.flagSelectAll = true;
    }else{
      this.flagSelectAll = false;
    }
  }

  public flagExtranjera:boolean = false;
  public flagInactiva:number = -1;
  public catalogEmp:any[];
  public catalogEmpComplete:any[];

  public getRegistros(){
    this.loading.onLoadingRequest();
    this._serviceCliente.getCliente(0)
    .then(response=>{
      if(response.status == 200){
        if(response.content != null && response.content.length > 0){
          let datosComplete = response.content.map((x)=>{
            x.EliminadoValue = x.Eliminado ? 'Inactivo' : 'Activo';
            x.Checked = false;
            return x
          });
          let datos = response.content.map((x)=>{
            x.EliminadoValue = x.Eliminado ? 'Inactivo' : 'Activo';
            x.Checked = false;
            return x
          }).filter(x=> x.LineaExtranjera == this.flagExtranjera);

          this.getRegistrosDeleted(datos, datosComplete);
        }
      }else if(response.status == 500){
        this.loading.onDoneRequest();
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public getRegistrosDeleted(datos:any = [], datosComplete:any = []){
    this._serviceCliente.getClienteEliminado(0)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        if(response.content != null && response.content.length > 0){
          let datosCompleteDeleted = response.content.map((x)=>{
            x.EliminadoValue = x.Eliminado ? 'Inactivo' : 'Activo';
            x.Checked = false;
            return x
          });
          datosComplete = datosComplete.concat(datosCompleteDeleted);

          let datosDeleted = response.content.map((x)=>{
            x.EliminadoValue = x.Eliminado ? 'Inactivo' : 'Activo';
            x.Checked = false;
            return x
          }).filter(x=> x.LineaExtranjera == this.flagExtranjera);
          datos = datos.concat(datosDeleted);

          this.catalogEmpComplete = datosComplete.sort(function (a, b) {
            if (a.Nombre > b.Nombre) {
              return 1;
            }
            if (a.Nombre < b.Nombre) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
          this.catalogEmp = datos.sort(function (a, b) {
            if (a.Nombre > b.Nombre) {
              return 1;
            }
            if (a.Nombre < b.Nombre) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
          console.log(this.catalogEmpComplete);
        }
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public filters(){
    switch(+this.flagInactiva) { 
      case 0: { 
         this.catalogEmp = this.catalogEmpComplete.filter(x=> x.LineaExtranjera == this.flagExtranjera && !x.Eliminado);
         break; 
      } 
      case 1: { 
         this.catalogEmp = this.catalogEmpComplete.filter(x=> x.LineaExtranjera == this.flagExtranjera && x.Eliminado);
         break; 
      } 
      default: { 
         this.catalogEmp = this.catalogEmpComplete.filter(x=> x.LineaExtranjera == this.flagExtranjera);
         break; 
      } 
   } 
   this.flagSelectAll = false;
   this.selectAll();
  }

  public guardarAsignacion(){
    var objItem = {
      checkCuest: [],
      ItemEmpresaID: this.itemID
    }
    objItem.checkCuest = [];
    
    for(var i = 0; i < this.catalogEmp.length; i++){
      if($('#ckb-' + this.catalogEmp[i].EmpresaID).prop('checked')){
        objItem.checkCuest.push(this.catalogEmp[i].EmpresaID);  
      }         
    }
    if(objItem.checkCuest.length <= 0){
      Swal.fire('Atención', "Para asignar el item es necesario elegir al menos una empresa de la tabla.", 'info');
      return false
    }
    this.loading.onLoadingRequest();
    this._serviceCliente.updateItemEmpresaMassiveAsign(objItem)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status != 200){
        Swal.fire('Error', response.message, 'error');
      }else{
        Swal.fire('Éxito', response.message, 'success')
            .then(rs=>{
              this.masiveAsign(0);
            });
      }    
    }).catch(error=>{   
      this.loading.onDoneRequest();   
      Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
    });
  }
}
