import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { Router } from '@angular/router';
import { ProspectoService } from 'src/app/services/prospecto/prospecto.service';
import { 
  faTrash as fasTrash,
  faAddressBook as faAddressBook
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-prospecto',
  templateUrl: './prospecto.component.html',
  styleUrls: ['./prospecto.component.css']
})
export class ProspectoComponent implements OnInit {
  fasTrash = fasTrash;
  faAddressBook = faAddressBook;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions:any = {
    columnDefs:[
      {
          targets: [7],
          orderable: false // set orderable false for selected columns
      },
      {
        targets: 0,
        render: function(data, type) {
          var dt=new Date(data);
          var dia = ("0" + dt.getDate()).slice(-2)
          var mes = ("0" + (dt.getMonth() + 1)).slice(-2)
          return type == 'display' ? dia + "/" + mes + "/" + dt.getFullYear() : data
        }
      }
    ],
    dom: 'Bflrtip',
    buttons: [{
      extend:'excel',
      exportOptions: {
          columns: [0,1,2,3,4,5,6]
      }
    }
    ],
    language: {
        "decimal": "",
        "emptyTable": "No hay información",
        "info": "Mostrando _START_ a _END_ de _TOTAL_ Resultados",
        "infoEmpty": "Mostrando 0 to 0 of 0 Resultados",
        "infoFiltered": "(Filtrado de _MAX_ total resultados)",
        "infoPostFix": "",
        "thousands": ",",
        "lengthMenu": "Mostrar _MENU_ Resultados",
        "loadingRecords": "Cargando...",
        "processing": "Procesando...",
        "search": "Buscador:",
        "zeroRecords": "Sin resultados encontrados",
        "paginate": {
            "first": "Primero",
            "last": "Ultimo",
            "next": "Siguiente",
            "previous": "Anterior"
        }
    }
  };
  dtTrigger = new Subject();

  identity = JSON.parse(localStorage.getItem('identity_user'));
  loading = new LoadingEfectComponent();

  public filter:any = {
    status: -1
  };
  public datos:any[];

  constructor(
    private _service:ProspectoService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this.getRegistros();
    this.verifyClaims();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  verifyClaims(){
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Prospectos');
    if(claim == undefined && claim == null){
      this._router.navigate(['/sa-rm/admin']);
    }
  }

  validateClaim(claim: string) {
    return this.identity.permisoLists.find(x => x.nombrePermiso === claim);
  }

  getRegistros(){
    this.dtOptions.destroy;
    this.loading.onLoadingRequest();
    this._service.getProspecto(0, +this.filter.status)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          this.datos = response.content;
          // Destroy the table first
          dtInstance.destroy();
          this.dtTrigger.next();
          this.loading.onDoneRequest();
        });
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  habilitarProspectoRechazado(id: number){
    Swal.fire({
      title: 'Atención',
      text: "¿Estas seguro que deseas habilitar el prospecto rechazado?",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading.onLoadingRequest();
        this._service.habilitarProspectoRechazado(id)
        .then(response=>{
          this.loading.onDoneRequest();
          if(response.status == 200){
            Swal.fire('Éxito', response.message, 'success');
            this.getRegistros();
          }else{
            Swal.fire('Error', response.message, 'error');
          }
        }).catch(error=>{  
          this.loading.onDoneRequest();    
          Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
        })
      }
    })
  }

  deleteProspecto(id: number){
    Swal.fire({
      title: 'Atención',
      text: "¿Estas seguro que deseas eliminar el registro?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading.onLoadingRequest();
        this._service.deleteProspecto(id)
        .then(response=>{
          this.loading.onDoneRequest();
          if(response.status == 200){
            Swal.fire('Éxito', response.message, 'success');
            this.getRegistros();
          }else{
            Swal.fire('Error', response.message, 'error');
          }
        }).catch(error=>{  
          this.loading.onDoneRequest();    
          Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
        })
      }
    })
  }

  deleteProspectoDef(id: number){
    Swal.fire({
      title: 'Atención',
      text: "¿Estas seguro que deseas eliminar el registro de forma definitiva? Esta acción no podrá deshacerse.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading.onLoadingRequest();
        this._service.deleteProspectoDef(id)
        .then(response=>{
          this.loading.onDoneRequest();
          if(response.status == 200){
            Swal.fire('Éxito', response.message, 'success');
            this.getRegistros();
          }else{
            Swal.fire('Error', response.message, 'error');
          }
        }).catch(error=>{  
          this.loading.onDoneRequest();    
          Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
        })
      }
    })
  }

  public ExtModal = false;
  dispDate;
  public dispID = 0;
  updateDisponibilidadModal(id: number){
    this.ExtModal = !this.ExtModal;
    if(this.ExtModal){
      this.dispDate = "";
      this.dispID = id
    }
  }

  updateProspectoDisponibilidad(){
    if(this.dispDate == "" ){
      Swal.fire("Atención","La fecha de disponibilidad no puede estar vacia.","warning")
    }else{
      this.loading.onLoadingRequest();
      this._service.updateProspectoDisponibilidad(this.dispID, this.dispDate)
      .then(response=>{
        this.loading.onDoneRequest();
        if(response.status == 200){
          Swal.fire('Éxito', response.message, 'success');
          this.updateDisponibilidadModal(0);
          this.getRegistros();
        }else{
          Swal.fire('Error', response.message, 'error');
        }
      }).catch(error=>{  
        this.loading.onDoneRequest();    
        Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
      })
    }
  }
}
