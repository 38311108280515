import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'role'
})
export class RolePipe implements PipeTransform {

  transform(items: any[], searchRole: string): unknown {
    if (!items) return [];
    if (!searchRole) return items;

    searchRole = searchRole.toLowerCase();

    return items.filter( it => {
      return it.Nombre.toLowerCase().includes(searchRole)
      || it.Descripcion.toLowerCase().includes(searchRole)
      || it.FechaCreacion.toLowerCase().includes(searchRole);
    });
  }

}
