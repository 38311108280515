import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'asistencia'
})
export class AsistenciaPipe implements PipeTransform {
  transform(items: any[], searchAsistencia: string): any[] {
    if (!items) return [];
    if (!searchAsistencia) return items;

    searchAsistencia = searchAsistencia.toLowerCase();

    return items.filter( it => {
      return it.NumProveedor.toLowerCase().includes(searchAsistencia) 
      || it.NombreEmpresa.toLowerCase().includes(searchAsistencia)
      || it.NombreTipoPersona.toLowerCase().includes(searchAsistencia)
      || it.NombreStatus.toLowerCase().includes(searchAsistencia)
      || it.FechaCreacion.toLowerCase().includes(searchAsistencia);
    });
  }

}
