import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';

@Component({
  selector: 'app-contrato',
  templateUrl: './contrato.component.html',
  styleUrls: ['./contrato.component.css']
})
export class ContratoComponent implements OnInit {
  loading = new LoadingEfectComponent();
  public catalog:any[];
  public nombre:string = "";

  public id = +this._route.snapshot.paramMap.get('id')
  identity = JSON.parse(localStorage.getItem('identity_user'));

  constructor(
    private _service:ClientesService,
    private _serviceUsuario:UsuariosService,
    private _route: ActivatedRoute,
    public router: Router,
  ) { }

  ngOnInit() {
    this.getRegistros();
  }
  
  validateClaim(claim: string) {
    return this.identity.permisoLists.find(x => x.nombrePermiso === claim);
  }

  public registrarLog(){
    let clase = 'Status Contrato ' + this.nombre;
    let obj = {
      //Controller: obj.Controller,
      Class: clase,
      //Table: 'ItemEmpresa',
      //ID: this.id,
      Message: 'ACCESO'
    }
    this._serviceUsuario.registrarLog(obj);
  }

  public getRegistros(){
    this.loading.onLoadingRequest();
    this._service.getContratoProceso(this.id)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        this.catalog = response.content;
        this.nombre = response.content[0].Nombre;
        this.registrarLog();
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  regresar(){
    this.router.navigate(["/sa-rm/admin-client/" + this.id + "/ft/" + this.id]);
  }

  public newContract(){
    Swal.fire({
      title: 'Atención',
      text: "¿Estas seguro que deseas iniciar un nuevo proceso de contrato?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading.onLoadingRequest();
        this._service.getNewContrato(this.id)
        .then(response=>{
          this.loading.onDoneRequest();
          if(response.status == 200){
            Swal.fire('Éxito', response.message, 'success');
          
            this.getRegistros();
          }else{
            Swal.fire('Error', response.message, 'error');
          }
        }).catch(error=>{  
          this.loading.onDoneRequest();    
          Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
        })
      }
    })
  }

  public goRoute(id){
    this.router.navigate(['/sa-rm/admin-client/' + id + '/contrato-sublist/' + id]);
  }
}
