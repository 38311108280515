<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row mapa-sitio">
            <div class="col-12">
                <h2>{{titleAlerta}} Empresas</h2> 
            </div>
        </div>
        <div class="row principal">
            <div class="col-sm-7 col-md-8 col-lg-9 col-xl-10">
                <input type="text" [(ngModel)]="searchClient" class="buscador form-control"  placeholder="Buscador">
            </div>
        </div>
        <div class="row tabla-catalogo">  
            <div class="col-12">
                <table class="table table-responsive"> 
                    <thead>
                        <tr>
                            <th width="10%">NUM PROVEEDOR</th>
                            <th width="50%">NOMBRE EMPRESA</th>
                            <th width="50%">NOMBRE COMERCIAL</th>
                            <th width="15%">TIPO PERSONA</th>
                            <th width="15%">PROVEEDOR</th>
                            <th width="10%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of cuestionario | filterclientlist: searchClient; ">
                            <td>{{group.NumProveedor}}</td>
                            <td>{{group.Nombre}}</td>
                            <td>{{group.NombreComercial}}</td>
                            <td>{{group.NombreTipoPersona}}</td>
                            <td>{{group.ProveedorVal}}</td>
                            <td>
                                <button class="btn btn-layout btn-ch btnTableUp" routerLink="/sa-rm/admin/alertaitem/{{group.EmpresaID}},{{tipoAlerta}}">VER</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>             
        </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>