<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row mapa-sitio">
            <div class="col-12">
                <h1 style="font-weight: bold;">Dashboard {{flagEXT}}</h1> 
            </div>
        </div>
        <div class="row">
            <div class="col-3" style="height: fit-content;">
                <label class="dash-title">{{obj.rolname}}</label><br>
                <label class="lbl-filtros dash-number">{{obj.DG.PERTOTAL}}%</label><br>
                <label style="font-size: 18px;">Número obtenido del porcentaje de validación de items mandatorios a partir de un total de {{obj.NumEmpresas}} empresas.</label><br>
                <label class="lbl-filtros dash-number" style="font-size: 20px;">{{obj.NumEmpresasFisicas}} Empresas Físicas</label><br>
                <label class="lbl-filtros dash-number" style="font-size: 20px;">{{obj.NumEmpresasMorales}} Empresas Morales</label><br><br>
                <label class="lbl-filtros">VER DETALLE</label><br>
                <!--<label (click)="getRegistrosMandatorios()" class="btn btn-dark btn-fluid">{{tipoDetalle}}</label><br>-->
                <label *ngIf="obj.rolcheck == 0" (click)="gotoDashboard(1)" class="btn btn-danger btn-fluid">POR ÁREA</label><br>
                <label (click)="gotoDashboard(2)" class="btn btn-warning btn-fluid" style="color: white;">POR EMPRESA</label>
            </div>
            <div class="col-9">
                <div id="graphCo" class="" style="height: 400px;"></div>
            </div>
        </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>