<div id="site_wrapper">
    <div class="contenedor-principal" style="height: 599px;">
        <div class="row mapa-sitio">
            <div class="col-12">
                <h2>Estatus de Carga</h2> 
            </div>
        </div>
        <div class="row filtros">
            <div class="col-sm-12 col-md-6 col-lg-3">
                <label>TIPO DE MANDATORIO</label>
                <ng-select multiple="true" [clearable]="false" [(ngModel)]="filter.status" placeholder="Seleccionar...">
                    <ng-option value="Para Firma de Contrato">Para Firma de Contrato</ng-option>
                    <ng-option value="Para Visita de Certificación">Para Visita de Certificación</ng-option>
                    <ng-option value="Para Iniciar Operaciones">Para Iniciar Operaciones</ng-option>
                    <ng-option value="Deseable">No Mandatorio</ng-option>
                </ng-select>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <label>Item</label>
                <ng-select multiple="true" [clearable]="false" [searchable]="true" [(ngModel)]="filter.itemID" placeholder="Seleccionar...">
                    <ng-option [value]="group.ItemID" *ngFor="let group of catalog;">
                        {{group.Nombre}}
                    </ng-option>
                </ng-select>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <label>ACTIVOS/INACTIVOS</label>
                <select [(ngModel)]="filter.eliminado" class="form-control">
                    <option value="2">TODOS</option>
                    <option value="0">ACTIVOS</option>
                    <option value="1">INACTIVOS</option>
                </select>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <label>TIPO DE SERVICIO</label>
                <select [(ngModel)]="filter.extranjera" class="form-control">
                    <option value="-1">TODAS</option>
                    <option value="0">NACIONAL</option>
                    <option value="1">EXTRANJERA</option>
                    <option value="2">AEROLINEA</option>
                </select>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <button class='btn btn-danger btn-fluid' type='button' style='margin-top: 31px;' (click)="getRegistros()">
                    DESCARGAR
                </button>
            </div>
        </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>