<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row mapa-sitio">
            <div class="col-12">
                <h2>Prospectos</h2> 
            </div>
        </div>
        <div class="row filtros">
            <div class="col-sm-12 col-md-6 col-lg-3">
                <label style="color: white;">STATUS</label>
                <select [(ngModel)]="filter.status" class="form-control">
                    <option value="-1">TODOS</option>
                    <option value="0">Amarillo</option>
                    <option value="1">Rojo</option>
                    <option value="2">Verde</option>
                    <!--<option value="210">ACTIVOS</option>-->
                    <option value="4">RECHAZADOS</option>
                    <option value="5">EXPIRADOS</option>
                    <option value="6">ELIMINADOS</option>
                </select>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <button class='btn btn-danger btn-fluid' type='button' style='margin-top: 31px;' (click)="getRegistros()">
                    Filtrar
                </button>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <button *ngIf="validateClaim('Alta Prospectos')" class='btn-layout btn-fluid float-right btnTableUp' type='button' style='margin-top: 35px;' routerLink="/sa-rm/nuevoprospect">
                    CREAR NUEVO
                </button>
            </div>
        </div>
        
        <div class="row tabla-catalogo">  
            <div class="col-12">
                <table class="table table-responsive table-sm table-hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"> 
                    <thead>
                        <tr>
                            <th width="10%">FECHA DE CREACIÓN</th>
                            <th width="30%">NOMBRE ENTREVISTADOR</th>
                            <th width="30%">RAZÓN SOCIAL</th>
                            <th width="30%">NOMBRE COMERCIAL</th>
                            <th width="30%">TIPO DE SERVICIO</th>
                            <th width="30%">TIPO PERSONA</th>
                            <th width="30%">STATUS</th>
                            <th width="10%">FECHA DE EXPIRACIÓN</th>
                            <th width="10%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of datos;">
                            <td>{{group.INSERT_DATE | date:'yyyy/MM/dd'}}</td>
                            <td>{{group.Create_User_Name}}</td>
                            <td>{{group.RazonSocial}}</td>
                            <td>{{group.NombreComercial}}</td>
                            <td>{{group.NombreTipoServicio}}</td>
                            <td>{{group.NombreTipoPersona}}</td>
                            <td style="text-wrap: nowrap;">{{group.NombreStatus}}
                                <img *ngIf="group.Status == 0" style="width:40%" src="./../../../../../assets/IMG/resultado_intermedio.png">
                                <img *ngIf="group.Status == 1" style="width:40%" src="./../../../../../assets/IMG/resultado_fundamental.png">
                                <img *ngIf="group.Status == 2" style="width:40%" src="./../../../../../assets/IMG/resultado_avanzado.png">
                            </td>
                            <td>{{group.EXPIRE_DATE | date:'dd/MM/yyyy'}}</td>
                            <td style="text-wrap: nowrap;">
                                <button class="btn btn-layout btn-ch btnTableUp ml-3 p-0" routerLink="/sa-rm/admin/prospect-ft/{{group.ProspectoID}}">VER</button>
                                <ng-template [ngIf]="validateClaim('Alta Prospectos') && (group.Status < 2 || group.Status == 5) && identity.Rol == 2">
                                    &nbsp;
                                    <button (click)="deleteProspecto(group.ProspectoID)" style="background-color: brown !important;" class="btn btn-eliminar" title="Eliminar">
                                        <fa-icon [icon]="fasTrash"></fa-icon>
                                    </button>
                                </ng-template>
                                <ng-template [ngIf]="validateClaim('Activacion Prospectos') && group.Status == 6">
                                    &nbsp;
                                    <button (click)="updateDisponibilidadModal(group.ProspectoID)" class="btn btn-eliminar" title="Re-Activar Prospecto">
                                        <fa-icon [icon]="faAddressBook"></fa-icon>
                                    </button>
                                </ng-template>
                                <ng-template [ngIf]="validateClaim('Candidato Prospectos') && group.Status == 4">
                                    &nbsp;
                                    <button (click)="habilitarProspectoRechazado(group.ProspectoID)" class="btn btn-eliminar" title="Habilitar Prospecto">
                                        <fa-icon [icon]="faAddressBook"></fa-icon>
                                    </button>
                                </ng-template>                                
                                <ng-template [ngIf]="validateClaim('Eliminar Prospectos')">
                                    &nbsp;
                                    <button (click)="deleteProspectoDef(group.ProspectoID)" class="btn btn-eliminar" title="Eliminar (Definitivo)">
                                        <fa-icon [icon]="fasTrash"></fa-icon>
                                    </button>
                                </ng-template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>             
        </div>
    </div>
</div>

<div class="modal fade" id="ExtModal" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': ExtModal ? 'block' : 'none', 'opacity': 1}"> 
    <div class="modal-dialog modal-md modal-dialog-centered" role="dialog" >
      <div class="modal-content">
        <div class="modal-header mdl-hdr text-center">
          <h5 class="modal-title w-100">Re-Activar Prospecto</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="updateDisponibilidadModal(0)">
            <span aria-hidden="true" class="mdl-close" >&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form #FormDisponibilidad="ngForm" (ngSubmit)="updateProspectoDisponibilidad()" autocomplete="off" appIdentityRevealed>
                <div class="form-group">
                    <label for="dispDate">Para reactivar un prospecto eliminado debes actualizar su fecha de disponibilidad para que puedas editar sus reactivos sin problema.</label>
                    <input type="date" class="form-control" name="dispDate" [(ngModel)]="dispDate">
                </div>
                <div class="div-btns row pt-3">   
                    <div class="col-6">
                      <button class="btn btn-fluid btn-Eliminar btnTableUp" (click)="updateDisponibilidadModal(0)" data-dismiss="modal" type="button">CANCELAR</button>            
                    </div>
                    <div class="col-6">     
                      <button class="btn btn-fluid btn-layout btnTableUp">GUARDAR</button>    
                    </div>
                </div>
            </form>            
        </div>       
      </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>